<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="pad30">
        <div class="mb30">
          <div class="mb30">
            <b-button pill :variant="customPrimaryVariant" class="fw500" :to="{ name: 'posDashboard' }">
              <i class="fas fa-arrow-left mr5"></i>
              {{ $t("Return") }}
            </b-button>

            <h5 class="text-left titleDetails">
              <span style="font-size: 70%">{{ $t("Abondement") }}</span>
              <i class="fas fa-angle-double-right ml5 mr5"></i>
              {{ marketing }}
            </h5>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="cardInfo">
                <div class="text-left">
                  <p>
                    <i class="fas fa-map-marker-alt mr5"></i>
                    {{ $t("CodedPOS") }}
                  </p>
                  <p class="m0">
                    <b>{{ pointCode }}</b>
                  </p>
                </div>
              </div>

              <div class="cardInfo">
                <div class="text-left">
                  <p>
                    <i class="fas fa-map-marker-alt mr5"></i>
                    {{ $t("NamePOS") }}
                  </p>
                  <p class="m0">
                    <b>{{ pointText }}</b>
                  </p>
                </div>
              </div>

              <div class="cardInfo">
                <div class="text-left">
                  <p>
                    <i class="far fa-calendar-alt mr5"></i>
                    {{ $t("LimitDate") }}
                  </p>
                  <p class="m0 warningColor">
                    <b>{{ deadline | dateGenericFormat }}</b>
                  </p>
                </div>
              </div>

              <div class="cardInfo" v-if="allowRate === true">
                <div class="text-left">
                  <p>
                    <i class="fas fa-tag mr5"></i>
                    {{ $t("Threshold") }}
                  </p>
                  <p class="m0">
                    <b>{{ threshold | convertInEuro }}</b>
                  </p>
                </div>
              </div>

              <div class="cardInfo" v-if="allowRate === true">
                <div class="text-left">
                  <p>
                    <i class="fas fa-info-circle mr5"></i>
                    {{ $t("amountAbundance") }}
                  </p>
                  <p class="m0">
                    <a href="#" v-b-modal.rateAmountModal><b>{{ $t("viewRateAmount") }}</b></a>
                  </p>
                </div>
              </div>

              <div class="actionDiv float-right">
                <b-button pill :variant="customPrimaryVariant" class="fw500 float-right" @click="downloadExcel">
                  <i class="far fa-file-excel mr5"></i>
                  {{ $t("DownloadFile") }}
                </b-button>
                <b-button pill :variant="customPrimaryVariant" class="fw500 ml15 float-right"
                  @click="validateOperationFunc()" v-show="status === 0 || status === 3">
                  <i class="fas fa-check mr5"></i>
                  {{ validateToDr }}
                </b-button>
              </div>
            </div>
          </div>

          <b-modal no-close-on-backdrop no-close-on-esc hide-footer size="xl" id="rateAmountModal" centered
            :title="$t('amountAbundance')" ref="rateAmountModal">
            <template>
              <div v-for="item in myTradesList" :key="item.trade_code">
                <h5 class="mt30 mb15 text-left">
                  <i class="fas fa-list mr5"></i>
                  {{ item.label }}
                </h5>

                <OperationAbondance v-if="myTrades.length > 0" :scope="
                    myTrades.find((el) => el.trade_code === item.trade_code)
                      .data
                  " />
              </div>
            </template>
          </b-modal>

          <b-modal no-close-on-backdrop no-close-on-esc id="validateOperation" centered :title="addressToRd"
            ref="validateOperationModal">
            <p>{{ addressToRdText }}</p>

            <template v-slot:modal-footer>
              <div class="w-100">
                <b-button class="float-right" :variant="customPrimaryVariant" pill @click="validate()">
                  {{ addressToRdButton }}</b-button>
                <b-button class="float-right mr5" variant="secondary" pill @click="hideModal('validateOperationModal')">
                  {{ $t("Cancel") }}</b-button>
              </div>
            </template>
          </b-modal>

          <!-- Comment -->
          <b-modal no-close-on-backdrop no-close-on-esc centered hide-footer :title="$t('refusedComment')"
            ref="commentModal">
            <p>{{ commentText }}</p>
          </b-modal>

          <!-- MODIFY OPERATION MODAL -->
          <b-modal no-close-on-backdrop no-close-on-esc id="modifyOperationModal" centered
            :title="modifyActionModalTitle" ref="modifyOperationModal">
            <div role="group">
              <label class="inlineCenter"
                style="margin-bottom: 15px; padding-top: 10px">{{ $t("OperationName") }}</label>
              <b-button class="float-right ml5" pill @click="switchOperationMode()"
                :variant="customPrimaryOutlineVariant">
                <i :class="switchIcon"></i>
                {{ operationModeText }}
              </b-button>
              <template v-if="switchState === true">
                <v-select :placeholder="$t('selectExistingOperation')" v-model="operationInput" :options="operation"
                  :class="{
                    pleaseFill:
                      (operationInput === null || operationInput.length <= 0) &&
                      isValidation == false,
                  }" :selectable="(option) => !option.hasOwnProperty('group')">
                  <span slot="no-options">
                    {{ $t("operationDoesNotExist") }}
                  </span>
                  <template #option="{ group, label }">
                    <div v-if="group" class="group">{{ group }}</div>
                    {{ label }}
                  </template>
                </v-select>
              </template>
              <template v-else>
                <b-form-input :placeholder="$t('setOperationName')" v-model="operationInputAdd" type="text" :class="{
                    pleaseFill:
                      (operationInputAdd === null ||
                        operationInputAdd === '') &&
                      isValidation == false,
                  }"></b-form-input>
              </template>

              <template v-if="comment != ''">
                <label class="mt5">{{ $t("comment") }}</label>
                <p class="commentSection">{{ comment }}</p>
              </template>

              <template v-if="source === 'user'">
                <label class="mt10" for="supportUsedInput">
                  {{ $t("SupportUsed") }}
                </label>
                <v-select id="supportUsedInput" v-model="supportUsedSelected" :options="supports" :class="{
                    pleaseFill:
                      (supportUsedSelected === null ||
                        supportUsedSelected.length <= 0) &&
                      isValidation == false,
                  }"></v-select>
              </template>

              <template v-if="source === 'user'">
                <label class="mt10">{{ $t("TitleSupport") }}</label>
                <b-form-input v-model="supportInput" type="text"></b-form-input>
              </template>

              <template>
                <label for="modelInput" style="margin-bottom: 15px; padding-top: 15px">{{ $t("Model") }}</label>
                <a class="float-right ml5 selectPadding" pill @click="unSelectAllModel()"
                  :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
                  <i class="fas fa-times-circle"></i>
                  Tout désélectionner
                </a>
                <a class="float-right ml5 selectPadding" pill @click="selectAllModel()"
                  :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
                  <i class="fas fa-check-circle"></i>
                  Tout sélectionner
                </a>

                <v-select id="modelInput" multiple v-model="modelSelected" :options="models"></v-select>
              </template>

              <template v-if="source === 'user'">
                <label class="mt10">{{ $t("StartDate") }}</label>
                <Datepicker v-model="startDate" name="uniquename" :language="fr" />
              </template>

              <template v-if="source === 'user'">
                <label class="mt10">{{ $t("EndDate") }}</label>
                <Datepicker v-model="endDate" name="uniquename" :language="fr" />
              </template>

              <template v-if="source === 'user'">
                <label class="mt10">{{ $t("Amount") }}</label>
                <b-form-input v-model="amount" v-mask="'##########'" :class="{
                    pleaseFill: amount === '' && isValidation == false,
                  }"></b-form-input>
              </template>
            </div>

            <template v-slot:modal-footer>
              <div class="w-100">
                <b-button class="float-right" :variant="customPrimaryVariant" pill @click="modifyRow">{{ $t("Edit") }}
                </b-button>
                <b-button class="float-right mr5" variant="secondary" pill @click="hideModal('modifyOperationModal')">
                  {{ $t("Cancel") }}</b-button>
              </div>
            </template>
          </b-modal>

          <!-- DELETE OPERATION MODAL -->
          <b-modal no-close-on-backdrop no-close-on-esc id="deleteOperationModal" centered
            :title="deleteActionModalTitle" ref="deleteOperationModal">
            <p>{{ $t("askDeleteOperation") }}</p>

            <b-form-input v-model="deleteOperation" type="text" hidden></b-form-input>

            <template v-slot:modal-footer>
              <div class="w-100">
                <b-button class="float-right" :variant="customPrimaryVariant" pill @click="deleteRow">{{ $t("delete") }}
                </b-button>
                <b-button class="float-right mr5" variant="secondary" pill @click="hideModal('deleteOperationModal')">
                  {{ $t("Cancel") }}</b-button>
              </div>
            </template>
          </b-modal>

          <!-- ADD OPERATION MODAL -->
          <b-modal no-close-on-backdrop no-close-on-esc id="addOperationModal" centered :title="addActionModalTitle"
            ref="addOperationModal">
            <div role="group">
              <label class="inlineCenter"
                style="margin-bottom: 15px; padding-top: 10px">{{ $t("OperationName") }}</label>
              <b-button class="float-right ml5" pill @click="switchOperationMode()"
                :variant="customPrimaryOutlineVariant">
                <i class="fas fa-plus mr5"></i>
                {{ operationModeText }}
              </b-button>
              <template v-if="switchState === true">
                <v-select :placeholder="$t('selectExistingOperation')" v-model="operationInput" :options="operation"
                  :class="{
                    pleaseFill:
                      (operationInput === null || operationInput.length <= 0) &&
                      isValidation == false,
                  }" :selectable="(option) => !option.hasOwnProperty('group')">
                  <span slot="no-options">
                    {{ $t("operationDoesNotExist") }}
                  </span>
                  <template #option="{ group, label }">
                    <div v-if="group" class="group">{{ group }}</div>
                    {{ label }}
                  </template>
                </v-select>
              </template>
              <template v-else>
                <b-form-input :placeholder="$t('setOperationName')" v-model="operationInputAdd" type="text" :class="{
                    pleaseFill:
                      (operationInputAdd === null ||
                        operationInputAdd === '') &&
                      isValidation == false,
                  }"></b-form-input>
              </template>

              <template v-if="commentAdd != ''">
                <label class="mt5">{{ $t("comment") }}</label>
                <p class="commentSection">{{ commentAdd }}</p>
              </template>

              <label for="supportUsedInput" class="mt10">
                {{ $t("SupportUsed") }}
              </label>
              <v-select id="supportUsedInput" v-model="supportUsedSelected" :options="supports" :class="{
                  pleaseFill:
                    (supportUsedSelected === null ||
                      supportUsedSelected.length <= 0) &&
                    isValidation == false,
                }"></v-select>

              <label class="mt10">{{ $t("TitleSupport") }}</label>
              <b-form-input v-model="supportInput" type="text"></b-form-input>

              <label for="modelInput" style="margin-bottom: 15px; padding-top: 15px">{{ $t("Model") }}</label>
              <a class="float-right ml5 selectPadding" pill @click="unSelectAllModel()"
                :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
                <i class="fas fa-times-circle"></i>
                Tout désélectionner
              </a>
              <a class="float-right ml5 selectPadding" pill @click="selectAllModel()"
                :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
                <i class="fas fa-check-circle"></i>
                Tout sélectionner
              </a>

              <v-select id="modelInput" multiple v-model="modelSelected" :options="models"></v-select>

              <label class="mt10">{{ $t("StartDate") }}</label>
              <Datepicker v-model="startDate" name="uniquename" :language="fr" />

              <label class="mt10">{{ $t("EndDate") }}</label>
              <Datepicker v-model="endDate" name="uniquename" :language="fr" />

              <label class="mt10">{{ $t("Amount") }}</label>
              <b-form-input v-model="amount" v-mask="'##########'"
                :class="{ pleaseFill: amount === '' && isValidation == false }"></b-form-input>
            </div>

            <template v-slot:modal-footer>
              <div class="w-100">
                <b-button class="float-right" :variant="customPrimaryVariant" pill @click="addOperation">{{ $t("Add") }}
                </b-button>
                <b-button class="float-right mr5" variant="secondary" pill @click="hideModal('addOperationModal')">
                  {{ $t("Cancel") }}</b-button>
              </div>
            </template>
          </b-modal>

          <!-- INVOICE MODAL -->
          <b-modal no-close-on-backdrop no-close-on-esc id="addInvoiceModal" centered :title="$t('AddInvoice')"
            ref="addInvoiceModal">
            <b-form-group>
              <b-form-file accept=".pdf" v-model="invoiceFile" id="invoiceFile" ref="invoiceFile"
                :browse-text="$t('loadFile')" :placeholder="$t('selectFromComputer')"></b-form-file>
            </b-form-group>

            <b-button v-if="isUser === 'lmp'" class="mt15" :variant="customPrimaryVariant" pill @click="loadLmpInvoice">
              <i class="fas fa-file-download mr5"></i>
              {{ $t("InvoiceFileLAMP") }}
            </b-button>

            <template v-slot:modal-footer>
              <div class="w-100">
                <b-button class="float-right" :variant="customPrimaryVariant" pill
                  @click="submitFile('invoiceFile', 'addInvoiceModal')">{{ $t("Validate") }}</b-button>
                <b-button class="float-right mr5" variant="secondary" pill @click="hideModal('addInvoiceModal')">
                  {{ $t("Cancel") }}</b-button>
              </div>
            </template>
          </b-modal>

          <!-- PROOF MODAL -->
          <b-modal no-close-on-backdrop no-close-on-esc id="addProofModal" centered :title="$t('AddProof')"
            ref="addProofModal">
            <b-form-group>
              <b-form-file v-model="proofFile" id="proofFile" ref="proofFile" :browse-text="$t('loadFile')"
                :placeholder="$t('selectFromComputer')"></b-form-file>
            </b-form-group>

            <template v-slot:modal-footer>
              <div class="w-100">
                <b-button class="float-right" :variant="customPrimaryVariant" pill
                  @click="submitFile('proofFile', 'addProofModal')">{{ $t("Add") }}</b-button>
                <b-button class="float-right mr5" variant="secondary" pill @click="hideModal('addProofModal')">
                  {{ $t("Cancel") }}</b-button>
              </div>
            </template>
          </b-modal>
          <BR />
          <!-- <div>
            <b-tabs content-class="mt-3">
              <b-tab title="Opération" active @click="op = true"></b-tab>
              <b-tab title="Abondement" @click="op = false"></b-tab>
            </b-tabs>
          </div> -->

          <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

          <template>
            <div v-for="item in groupTrades" :key="item.trade_code">
              <h5 class="mt30 mb15 text-left">
                <i class="fas fa-list mr5"></i>
                {{ item.title }}
              </h5>
              <b-table responsive bordered striped class="m0" :fields="fields" :items="item.array" id="posDetailsTable"
                :tbody-tr-class="rowClass">

                <template v-slot:cell(operation_name)="row">
                  {{ row.item.operation_name }}
                  <i v-if="(row.item.customCeil) || (row.item.turnoverContribution && row.item.customCeil)" class="fas fa-tag ml5 primaryColor cursor-pointer"
                    v-b-popover.hover.right="row.item.contribLabel" :title="$t('customCeil')"></i>
                </template>

                <template v-slot:cell(start_date)="row">{{
                  row.item.start_date | dateGenericFormat
                }}</template>

                <template v-slot:cell(end_date)="row">{{
                  row.item.end_date | dateGenericFormat
                }}</template>

                <template v-slot:cell(actions)="row">
                  <b-dropdown dropleft text="Drop-Left" variant="link" toggle-class="text-decoration-none" no-caret
                    v-if="
                      row.item.status !== 1 &&
                      status !== 4 &&
                      status !== 7 &&
                      status !== 1 &&
                      row.item.status !== 7
                    ">
                    <template v-slot:button-content>
                      <i class="fas fa-ellipsis-v primaryColor fs18"></i>
                    </template>
                    <b-dropdown-item @click="
                        modifyOperationModal(
                          row.item.operation_code,
                          row.item.plan_code,
                          row.item.operation_done_code,
                          row.item.operation_name,
                          row.item.media_support_code,
                          row.item.support,
                          row.item.model_code,
                          row.item.model,
                          row.item.threshold,
                          row.item.rate,
                          row.item.source,
                          row.item.amount,
                          row.item.title,
                          row.item.start_date,
                          row.item.end_date,
                          row.item.comment,
                          item.trade_code,
                          row
                        )
                      " v-show="
                        status < 1 ||
                        (status === 3 &&
                          (row.item.status === 3 ||
                            row.item.status === 2 ||
                            row.item.status < 1))
                      ">
                      <i class="fas fa-edit mr5"></i>
                      {{ $t("update") }}
                    </b-dropdown-item>

                    <b-dropdown-item @click="
                        deleteOperationModal(
                          row.item.operation_done_code,
                          item.trade_code
                        )
                      " v-show="
                        status < 1 ||
                        (status === 3 &&
                          (row.item.status === 3 ||
                            row.item.status === 2 ||
                            row.item.status < 1))
                      ">
                      <i class="fas fa-trash mr5"></i>
                      {{ $t("delete") }}
                    </b-dropdown-item>

                    <b-dropdown-item @click="showComment(row.item.details.refuse_comment)"
                      v-show="status === 3 && row.item.status === 3">
                      <i class="fas fa-comment-dots mr5"></i>
                      {{ $t("viewComment") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <template v-slot:cell(invoice)="row">
                  <div style="margin-bottom: 10px" v-for="(item, index) in row.item.invoice" v-bind:key="index">
                    <button class="pdfButton" v-on:click="downloadInvoice(item.document_done_code)">
                      <i class="fas fa-file-pdf mr5 dInline" />
                      {{ item.namefile | truncate(10, "...") }}
                    </button>
                    <button class="deleteButton" v-if="
                        row.item.status !== 1 &&
                        status !== 4 &&
                        status !== 7 &&
                        status !== 1 &&
                        row.item.status !== 7
                      " v-on:click="deleteDocument(item.document_done_code)">
                      <i class="fas fa-times-circle dInline" />
                    </button>
                  </div>

                  <b-button v-if="row.item.invoice.length == 0" pill :variant="customPrimaryVariant" @click="
                      addInvoice(
                        row.item.operation_done_code,
                        row.item.media_support_code,
                        row.item.source
                      )
                    " v-show="status == 0 || status == 3">
                    <i class="fas fa-plus-circle"></i>
                  </b-button>
                </template>

                <template v-slot:cell(proof)="row">
                  <div style="margin-bottom: 10px" v-for="(item, index) in row.item.proof" v-bind:key="index">
                    <button class="pdfButton" v-on:click="downloadProof(item.document_done_code)">
                      <i class="fas fa-file-pdf mr5 dInline" />
                      {{ item.namefile | truncate(10, "...") }}
                    </button>
                    <button class="deleteButton" v-if="
                        row.item.status !== 1 &&
                        status !== 4 &&
                        status !== 7 &&
                        status !== 1 &&
                        row.item.status !== 7
                      " v-on:click="deleteDocument(item.document_done_code)">
                      <i class="fas fa-times-circle dInline" />
                    </button>
                  </div>
                  <b-button pill :variant="customPrimaryVariant" @click="
                      addProof(
                        row.item.operation_done_code,
                        row.item.media_support_code
                      )
                    " v-show="status == 0 || status == 3">
                    <i class="fas fa-plus-circle"></i>
                  </b-button>
                </template>

                <template v-slot:cell(drAmount)="row">
                  <span v-if="row.item.customCeil">
                    {{ row.item.drCustomCeil | convertInEuro }}
                  </span>
                  <span v-else>{{ row.item.drAmount | convertInEuro }}</span>
                </template>

              </b-table>

              <b-btn v-if="op === true" pill :variant="customPrimaryVariant" @click="addOperationModal(item.trade_code)"
                class="mt15" v-show="status < 1">
                <i class="fas fa-edit mr5" />
                {{ $t("AddAction") }}
              </b-btn>
              <div class="mb75"></div>
            </div>
          </template>

          <div>
            <hr class="mt30" />
            <b-table responsive bordered class="mt30" :fields="summaryFieldsToDisplay" :items="summaryItems" id="summaryTable">
              <template v-slot:cell(totalExpenses)="data">
                <span>{{ data.item.totalExpenses | convertInEuro }}</span>
              </template>

              <template v-slot:head(totalForecast)="data">
                <span v-html="data.field.label"></span>
                <b-button class="ml5 info-btn" v-b-popover.hover.right="$t('forecastQuestion')" title="">
                  <i style="font-size: 18px;" class="far fa-question-circle"></i>
                </b-button>
              </template>
            </b-table>
            <!--
            <b-table responsive bordered class="mt30" :fields="summaryNoneFields" :items="summaryNoneItems"
              id="summaryNoneTable" style="width: 260px">
              <template v-slot:cell(totalNoContributionExpenses)="data">
                <span>{{
                  data.item.totalNoContributionExpenses | convertInEuro
                }}</span>
              </template>
            </b-table>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import http from "../helpers/http";
  import common from "../helpers/common";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import FileSaver from "file-saver";
  import Datepicker from "vuejs-datepicker";
  import mixins from "../helpers/mixins.js";
  import {
    en,
    fr
  } from "vuejs-datepicker/dist/locale";

  import OperationAbondance from "@/components/Table/OperationAbondance.vue";

  export default {
    mixins: [mixins],
    beforeMount() {
      this.isLoading = true;
      common.isAuth(this, http);
    },
    data() {
      const allowRate = this.$cookie.get("allowRate") == "true";
      const brand = this.$cookie.get("brand");
      const headerRate = []; // add header if you need

      if (allowRate) {
        headerRate.push({
          key: "drAmount",
          label: this.$i18n.t("tableAbundance"),
          formatter: this.$store.state.setCurrency,
          tdClass: "text-right",
          thStyle: {
            width: "140px",
          },
        });
      }

      return {
        generalOperation: [],
        generalTrade: [],
        validateToDr: this.$i18n.t("validateToDr"),
        addressToRd: this.$i18n.t("addressToRd"),
        addressToRdText: this.$i18n.t("addressToRd2"),
        addressToRdButton: this.$i18n.t("addressToRd3"),
        myTrades: [],
        op: true,
        allowRate,
        commentText: "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor indicidiunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maece as accumsan lacus vel facilisis.",
        /*summaryFields: [{
            key: "totalExpenses",
            label: this.$i18n.t("totalExpenses"),
            formatter: this.$store.state.setCurrency,
            tdClass: "text-center",
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "rate",
            label: this.$i18n.t("RateInput"),
            formatter: this.$store.state.setPercentage,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalRate",
            label: this.$i18n.t("TotalRate"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "customCeil",
            label: this.$i18n.t("specificWithoutCeil"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "threshold",
            label: this.$i18n.t("ThresholdConcessionsPlate"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalAllExpenses",
            label: this.$i18n.t("totalAllExpenses"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
        ],
        */
        summaryFields: [{
            key: "totalExpenses",
            label: this.$i18n.t("totalExpenses"),
            formatter: this.$store.state.setCurrency,
            tdClass: "text-center",
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalForecast",
            label: this.$i18n.t("totalForecast"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalContribution",
            label: this.$i18n.t("totalContribution"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalCustomCeil",
            label: this.$i18n.t("totalCustomCeil"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalToPay",
            label: this.$i18n.t("totalToPay"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
        ],
        /*
        summaryNoneFields: [{
          key: "totalNoContributionExpenses",
          label: this.$i18n.t("totalNoneExpenses_" + brand),
          formatter: this.$store.state.setCurrency,
          tdClass: "text-center",
          thStyle: {
            width: "1px",
          },
        }, ],
        */
        summaryItems: [],
        summaryNoneItems: [],
        isUser: "none",
        limit: 0,
        periodDtartDate: null,
        periodEndDate: null,
        commentAdd: "",
        comment: "",
        isValidation: true,
        switchIcon: "fas fa-plus mr5",
        deleteOperation: "",
        switchState: false,
        operationModeText: this.$t("addOperation"),
        brand: window.location.pathname.split("/")[1],
        customPrimaryOutlineVariant: "outline-" + window.location.pathname.split("/")[1] + "-customPrimary",
        customPrimaryVariant: window.location.pathname.split("/")[1] + "-customPrimary",
        marketing: "",
        deadline: "",
        source: "",
        operationInputAdd: "",
        supportInput: "",
        amount: 0,
        en: en,
        fr: fr,
        startDate: new Date(),
        endDate: new Date(),
        pointCode: "",
        pointText: "",
        status: 2,
        isLoading: false,
        fullPage: true,
        invoiceFile: null,
        proofFile: null,
        operationDoneCode: "",
        operationCode: "",
        operationInput: [],
        rateInput: "",
        thresholdInput: "",
        threshold: 0,
        supportUsedSelected: null,
        modelSelected: null,
        planDoneCode: this.$route.params.id,
        models: [],
        supports: [],
        operation: [],
        planCode: "",
        fields: [{
            key: "operation_name",
            label: this.$i18n.t("tableOperation"),
            formatter: "joinArray",
          },
          {
            key: "support",
            label: this.$i18n.t("tableSupport"),
            formatter: "joinArray",
          },
          {
            key: "title",
            label: this.$i18n.t("tableTitle"),
          },
          {
            key: "model",
            label: this.$i18n.t("tableModel"),
            formatter: "joinArray",
            thStyle: {
              width: "260px",
            },
          },
          {
            key: "start_date",
            label: this.$i18n.t("tableDateBegin"),
            thStyle: {
              width: "120px",
            },
          },
          {
            key: "end_date",
            label: this.$i18n.t("tableDateEnd"),
            thStyle: {
              width: "100px",
            },
          },
          {
            key: "amount",
            label: this.$i18n.t("tableAmount"),
            formatter: this.$store.state.setCurrency,
            tdClass: "text-right",
            thStyle: {
              width: "120px",
            },
          },
          ...headerRate,
          {
            key: "invoice",
            label: this.$i18n.t("tableInvoice"),
          },
          {
            key: "proof",
            label: this.$i18n.t("tableProof"),
          },
          {
            key: "actions",
            label: this.$i18n.t("tableActions"),
            tdClass: "text-center",
            thStyle: {
              width: "70px",
            },
          },
        ],
        currentTradeCode: "",
        customPrimaryVariantText: "text-" + window.location.pathname.split("/")[1] + "-customPrimary",
        addActionModalTitle: this.$i18n.t("AddAction"),
        modifyActionModalTitle: this.$i18n.t("modifyAction2"),
        deleteActionModalTitle: this.$i18n.t("deleteAction"),
      };
    },
    watch: {
      switchState() {
        switch (this.switchState) {
          case false:
            this.operationModeText = this.$t("chooseOperationList");
            this.switchIcon = "fas fa-mouse-pointer mr5";
            break;
          case true:
            this.operationModeText = this.$t("addOperation");
            this.switchIcon = "fas fa-plus mr5";
            break;
        }
      },

      currentTradeCode(val) {
        const tradeSelected = this.myTradesList.find(
          (el) => el.trade_code === val
        );
        const TradeName = tradeSelected.label; // must be changed

        this.addActionModalTitle = TradeName.concat(
          " - ",
          this.$i18n.t("AddAction")
        );
        this.modifyActionModalTitle = TradeName.concat(
          " - ",
          this.$i18n.t("modifyAction2")
        );
        this.deleteActionModalTitle = TradeName.concat(
          " - ",
          this.$i18n.t("deleteAction")
        );
      },
      operationInput: function (val) {
        // this.modelSelected = [];

        if (val) {
          if (val.creaToModel) {
            for (let i = 0; i < this.models.length; i++) {
              for (let j = 0; j < val.creaToModel.length; j++) {
                if (this.models[i].code === val.creaToModel[j]) {
                  if (
                    this.modelSelected.some(
                      (e) => e.code === val.creaToModel[j]
                    ) === false
                  ) {
                    this.modelSelected.push({
                      code: this.models[i].code,
                      label: this.models[i].label,
                    });
                  }
                }
              }
            }
          }
          if (val.comment) {
            this.commentAdd = val.comment;
            this.comment = val.comment;
          } else if (val[0]) {
            if (val[0].comment) {
              this.comment = val[0].comment;
            } else {
              this.comment = "";
            }
          } else {
            this.commentAdd = "";
            this.comment = "";
          }
        } else {
          this.commentAdd = "";
          this.comment = "";
        }
      },
    },
    components: {
      Loading,
      Datepicker,
      OperationAbondance,
    },
    computed: {
      groupTrades() {
        const sectionTrade = this.myTradesList.map((el) => ({
          trade_code: el.trade_code,
          title: el.label,
          array: [],
        }));
        this.generalTrade.forEach((element) => {
          const temp = sectionTrade.find(
            (el) => el.trade_code === element.trade_code
          );

          temp.array.push(element);
        });
        return sectionTrade;
      },

      myTradesList() {
        return this.$store.getters.getTradesFull;
      },

      summaryFieldsToDisplay () {
        return this.allowRate ? this.summaryFields : this.summaryFields.slice(0,1);
      }
    },
    mounted() {
      let self = this;
      this.isLoading = true;
      this.switchState = true;
      this.$store.dispatch("getTrade", this.$cookie);
      self.initTable(self);

      // Get models

      http
        .get(
          this.$store.state.apiUrl + "/global/model/",
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        )
        .then((res) => {
          let response = res[0];
          let array = [];

          if (res[1]) {
            common.refreshToken(this, res[1].data.token, res[1].data.validity);
          }

          for (let i = 0; i < response.data.length; i++) {
            array.push({
              label: response.data[i].name,
              code: response.data[i].model_code,
            });
          }
          this.models = array;
        });

      // Get supports
      http
        .get(
          this.$store.state.apiUrl + "/global/media_support/",
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        )
        .then((res) => {
          let response = res[0];
          let array = [];

          if (res[1]) {
            common.refreshToken(this, res[1].data.token, res[1].data.validity);
          }

          for (let i = 0; i < response.data.length; i++) {
            array.push({
              label: response.data[i].name,
              code: response.data[i].media_support_code,
            });
          }
          this.supports = array;
        });
    },
    methods: {
      validateOperationFunc() {
        let isInvoicesOk = true;

        for (let i = 0; i < this.generalTrade.length; i++) {
          if (this.generalTrade[i].invoice.length === 0) {
            isInvoicesOk = false;
          }
        }

        if (isInvoicesOk == true) {
          this.$refs["validateOperationModal"].show();
        } else {
          common.makeToast(
            this,
            this.$i18n.t("warning"),
            this.$i18n.t("pleaseInvoices"),
            this.brand + "-customWarning",
            "b-toaster-bottom-center",
            true
          );
        }
      },
      unSelectAllModel() {
        this.modelSelected = [];
      },
      selectAllModel() {
        this.modelSelected = [...this.models];
      },
      switchOperationMode() {
        switch (this.switchState) {
          case true:
            this.switchState = false;

            break;
          case false:
            this.switchState = true;

            break;
        }
      },
      setOptGroups(arrayOperation) {
        let self = this;
        let array = []; // kind of operation

        for (let i = 0; i < arrayOperation.length; i++) {
          if (arrayOperation[i].isCrea === false) {
            if (!array.filter((e) => e.code === "OP").length > 0) {
              array.push({
                group: self.$i18n.t("Operations"),
                label: null,
                code: "OP",
              });
            }
            array.push(arrayOperation[i]);
          } else {
            if (!array.filter((e) => e.code === "TH").length > 0) {
              array.push({
                group: self.$i18n.t("themes"),
                label: null,
                code: "TH",
                creaToModel: arrayOperation[i].creaToModel,
              });
            }
            array.push(arrayOperation[i]);
          }
        }

        return array;
      },
      setOperation(tradeCode) {
        let self = this;

        const scope = this.groupTrades.find((el) => el.trade_code === tradeCode);
        // Get allow
        // this.generalOperationObject
        self.operation = self.setOptGroups(
          this.generalOperation.filter((el) => el.trade_code === tradeCode)
        );

        self.operation = self._.uniqBy(self.operation, "code");
      },
      initTable(self) {
        // Get dashboard rows
        self.isLoading = true;
        http
          .get(
            self.$store.state.apiUrl + "/global/details/" + this.planDoneCode,
            self.$cookie.get("auth"),
            self.$cookie.get("authExpires")
          )
          .then((res) => {
            let response = res[0];
            this.generalTrade = [];

            if (res[1]) {
              common.refreshToken(self, res[1].data.token, res[1].data.validity);
            }

            self.threshold = response.data.infos.limit;
            self.marketing = response.data.infos.plan.label;
            self.deadline = response.data.infos.plan.deadline;
            self.status = response.data.infos.status;
            self.pointCode = response.data.infos.point_code;
            self.pointText = response.data.infos.point_name;
            self.periodStartDate = response.data.infos.plan.start_date;
            self.periodEndDate = response.data.infos.plan.end_date;

            if (self.status === 3) {
              self.validateToDr = self.$i18n.t("returnToDr");
              self.addressToRd = self.$i18n.t("TitleRefuseOperation");
              self.addressToRdText = self.$i18n.t("reSendToDr");
              self.addressToRdButton = self.$i18n.t("confirm");
            } else {
              self.validateToDr = self.$i18n.t("validateToDr");
              self.addressToRd = self.$i18n.t("addressToRd");
              self.addressToRdText = self.$i18n.t("addressToRd2");
              self.addressToRdButton = self.$i18n.t("addressToRd3");
            }
            /*
                        self.summaryItems = [{
                          type: response.data.summary.type,
                          totalExpenses: response.data.summary.totalExpenses,
                          totalAllExpenses: response.data.summary.totalAllExpenses,
                          customCeil: response.data.summary.customCeil,
                          rate: response.data.summary.rate,
                          totalRate: response.data.summary.totalRate,
                          threshold: self.threshold,
                        }, ];
            */
            self.summaryItems = [{
              type: response.data.summary.type,
              totalExpenses: response.data.summary.totalExpenses,
              totalForecast: response.data.summary.totalForecast,
              totalContribution: response.data.summary.totalContribution,
              totalCustomCeil: response.data.summary.totalCustomCeil,
              totalToPay: response.data.summary.totalToPay,
            }, ];
            /*
            self.summaryNoneItems = [{
              type: response.data.summary.type,
              totalNoContributionExpenses: response.data.summary.totalNoContributionExpenses,
            }, ];
            */

            for (let i = 0; i < response.data.tableData.trades.length; i++) {
              let resTrades = response.data.tableData.trades[i];

              for (let l = 0; l < resTrades.data.length; l++) {
                let models = [];
                let modelsCodes = [];

                for (let j = 0; j < resTrades.data[l].model.length; j++) {
                  models.push(resTrades.data[l].model[j].model);
                  modelsCodes.push(resTrades.data[l].model[j].model_code);
                }

                let comment = "";

                if (resTrades.data[l].comment) {
                  comment = resTrades.data[l].comment;
                }

                const temp = {
                  customCeil: resTrades.data[l].customCeil,
                  drCustomCeil: resTrades.data[l].drCustomCeil,
                  trade_code: resTrades.data[l].trade_code,
                  operation_code: resTrades.data[l].operation_code,
                  operation_done_code: resTrades.data[l].operation_done_code,
                  operation_name: resTrades.data[l].operation_name,
                  media_support_code: resTrades.data[l].media_support_code,
                  support: resTrades.data[l].support,
                  title: resTrades.data[l].title,
                  model: models,
                  model_code: modelsCodes,
                  start_date: resTrades.data[l].start_date,
                  end_date: resTrades.data[l].end_date,
                  amount: resTrades.data[l].amount,
                  rate: resTrades.data[l].rate,
                  drAmount: resTrades.data[l].drAmount,
                  invoice: resTrades.data[l].invoice,
                  proof: resTrades.data[l].proof,
                  threshold: resTrades.data[l].threshold,
                  status: resTrades.data[l].status,
                  actions: "",
                  crea_code: resTrades.data[l].crea_code,
                  // comment: comment,
                  plan_code: response.data.infos.plan_code,
                  source: resTrades.data[l].source,
                  contribLabel: resTrades.data[l].contribLabel,
                  details: resTrades.data[l].details
                };
                this.generalTrade.push(temp);
              }
            }
            self.planCode = response.data.infos.plan_code;

            // Get operation
            // natega

            http
              .get(
                this.$store.state.apiUrl + "/pos/operations/" + self.planCode,
                this.$cookie.get("auth"),
                this.$cookie.get("authExpires")
              )
              .then((res) => {
                this.myTrades = res[0].data.trades;
              });

            http
              .get(
                self.$store.state.apiUrl +
                "/global/operation/" +
                self.planCode +
                "?start_date=" +
                self.periodStartDate +
                "&end_date=" +
                self.periodEndDate,
                self.$cookie.get("auth"),
                self.$cookie.get("authExpires")
              )
              .then((res) => {
                let response = res[0].data[0].trades;

                if (res[1]) {
                  common.refreshToken(
                    self,
                    res[1].data.token,
                    res[1].data.validity
                  );
                }

                for (let i = 0; i < response.length; i++) {
                  let tradeCode = response[i].trade_code;

                  for (let j = 0; j < response[i].data.length; j++) {
                    let comment = "";

                    if (response[i].data[j].comment) {
                      comment = response[i].data[j].comment;
                    }

                    let temp = {
                      trade_code: response[i].data[j].trade_code,
                      code: response[i].data[j].operation_code,
                      plan_code: response[i].data[j].plan_code,
                      label: response[i].data[j].name,
                      status: response[i].data[j].status,
                      isCrea: response[i].data[j].isCrea,
                      creaToModel: response[i].data[j].creaToModel,
                      comment: comment,
                    };
                    this.generalOperation.push(temp);
                  }
                }
                self.isLoading = false;
              });
          });
      },
      loadLmpInvoice() {
        let proxy = null;
        let self = this;

        if (process.env.VUE_APP_NODE_ENV == "localhost") {
          proxy = true;
        }

        self.isLoading = true;
        http
          .get(
            this.$store.state.apiUrl +
            "/pos/quotation?operationdonecode=" +
            self.operationDoneCode +
            "&support=" +
            self.supportUsedSelected,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            self.initTable(self);
            self.$refs["addInvoiceModal"].hide();
            common.makeToast(
              self,
              self.$i18n.t("successLoadLmpTitle"),
              self.$i18n.t("successLoadLmpBody"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
            self.isLoading = false;
          });
      },
      downloadExcel() {
        let proxy = null;

        if (process.env.VUE_APP_NODE_ENV == "localhost") {
          proxy = true;
        }

        this.isLoading = true;
        const allowRate = this.$cookie.get("allowRate") || true;
        http
          .getExcel(
            this.$store.state.apiUrl +
            "/global/document_generation/" +
            this.planDoneCode +
            "?plan_code=" +
            this.planCode +
            "&" +
            "abundance=" +
            allowRate,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            const blob = new Blob([res[0].data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, Math.random() + ".xlsx");
            this.isLoading = false;
          });
      },
      joinArray(array) {
        if (Array.isArray(array)) {
          return array.join(", ");
        } else {
          return array;
        }
      },
      showComment(comment) {
        this.commentText = comment;
        this.$refs["commentModal"].show();
      },
      submitFile(file, ref) {
        let formData = new FormData();
        let self = this;

        if (file == "invoiceFile") {
          formData.append("file", this.invoiceFile);
          formData.append("operationDoneCode", self.operationDoneCode);
          formData.append("support", self.supportUsedSelected);
          formData.append("type", "1");
        } else if (file == "proofFile") {
          formData.append("file", this.proofFile);
          formData.append("operationDoneCode", self.operationDoneCode);
          formData.append("support", self.supportUsedSelected);
          formData.append("type", "2");
        }
        this.isLoading = true;
        http
          .postDocument(
            this.$store.state.apiUrl +
            "/pos/details/" +
            this.planDoneCode +
            "/upload/",
            formData,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }
            common.makeToast(
              self,
              self.$i18n.t("SuccessfulConnexion"),
              self.$i18n.t("SuccessfulSending"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
            self.initTable(self);
            self.$refs[ref].hide();
            this.isLoading = false;
          })
          .catch((error) => {
            common.makeToast(
              self,
              self.$i18n.t("Error"),
              self.$i18n.t("FailedSending"),
              self.brand + "-customDanger",
              "b-toaster-bottom-center",
              true
            );
            this.isLoading = false;
          });
      },
      isNumber(event) {
        this.$store.state.isNumber(event);
      },
      hideModal(ref) {
        this.$refs[ref].hide();
      },
      showModal(ref) {
        this.isValidation = true;
        this.$refs[ref].show();
      },
      addOperationModal(tradeCode) {
        let self = this;

        if (tradeCode != null) {
          this.currentTradeCode = tradeCode;
        }
        this.setOperation(tradeCode);

        // Reset form
        this.operationInput = "";
        this.operationInputAdd = "";
        this.amount = 0;
        this.supportInput = "";
        this.supportUsedSelected = [];
        this.modelSelected = [];

        self.$refs["addOperationModal"].show();
      },
      rowClass(item) {
        if (!item) {
          return;
        }
        if (item.status === 0) {
          return;
        } else if (item.status === 1) {
          return "table-customSuccess";
        } else if (item.status === 2 || item.status === 3) {
          return "table-customDanger";
        }
      },
      addOperation() {
        let self = this;
        let contribution = "";
        let type;
        let models = self.modelSelected.map(function (obj) {
          return obj.code;
        });

        if (self.switchState == true) {
          contribution = self.operationInput.code;
          type = "code";
        } else {
          contribution = self.operationInputAdd;
          type = "other";
        }

        let data = {
          planDoneCode: self.planDoneCode,
          contribution: contribution,
          supportTitle: self.supportInput,
          support: self.supportUsedSelected.code,
          models: models,
          startDate: self.startDate,
          endDate: self.endDate,
          amount: self.amount,
          contributionType: type,
          trade_code: self.currentTradeCode,
        };

        if (self.switchState == true) {
          data.operation_code = self.operationInput.code;
          if (self.operationInput.isCrea == true) {
            data.crea_code = self.operationInput.code;
          }
        }

        if (contribution == undefined) {
          contribution = "";
        }

        if (data.support == undefined || contribution == "" || self.amount == "" || self.supportUsedSelected == [] || self.modelSelected == []) {
          self.isValidation = false;
          common.makeToast(
            self,
            "Attention",
            self.$i18n.t("missingForAction"),
            self.brand + "-customWarning",
            "b-toaster-bottom-center",
            true
          );
        } else {
          self.isLoading = true;
          http
            .post(
              this.$store.state.apiUrl + "/pos/addOperation",
              data,
              this.$cookie.get("auth"),
              this.$cookie.get("authExpires")
            )
            .then((res) => {
              if (res[1]) {
                common.refreshToken(
                  this,
                  res[1].data.token,
                  res[1].data.validity
                );
              }

              //self.isLoading = false;
              common.makeToast(
                self,
                self.$i18n.t("Success"),
                self.$i18n.t("AddedOperation"),
                self.brand + "-customSuccess",
                "b-toaster-bottom-center",
                true
              );
              self.initTable(self);
            })
            .catch((error) => {
              // self.isLoading = false;
              common.makeToast(
                self,
                self.$i18n.t("Error"),
                self.$i18n.t("AddedOperation"),
                self.brand + "-customDanger",
                "b-toaster-bottom-center",
                true
              );
            });
          this.$refs["addOperationModal"].hide();
          self.isValidation = true;
        }
      },
      modifyOperationModal(
        operation_code,
        plan_code,
        operation_done_code,
        operationInput,
        media_support_code,
        support,
        model_code,
        model,
        threshold,
        rate,
        source,
        amount,
        title,
        startDate,
        endDate,
        comment,
        tradeCode,
        row
      ) {
        let self = this;
        const crea_code = row.item.crea_code;

        if (operation_code || crea_code) {
          // toggle inteface
          this.switchState = true;
        } else {
          this.switchState = false;
        }
        self.currentTradeCode = tradeCode; // save trade
        self.source = source; // orign

        self.supportUsedSelected = {
          label: support,
          code: media_support_code,
        }; // get support

        self.modelSelected = [];

        for (let i = 0; i < model.length; i++) {
          self.modelSelected.push({
            label: model[i],
            code: model_code[i],
          });
        } // get model

        self.modelSelected = self._.uniqBy(self.modelSelected, "code");

        self.setOperation(self.currentTradeCode);

        self.$route.params.operationDoneCode = operation_done_code;
        self.operationDoneCode = operation_done_code;
        self.operationCode = operation_code;

        self.operationInput = null;

        if (operation_code || crea_code) {
          self.operationInput = {
            label: operationInput,
            comment: comment,
          };
        } else {
          self.operationInputAdd = operationInput; // input text
        }

        self.thresholdInput = threshold;
        self.rateInput = rate;
        self.planCode = plan_code;
        self.amount = amount;
        self.supportInput = title;
        self.startDate = startDate;
        self.endDate = endDate;

        this.isLoading = true;
        http
          .get(
            this.$store.state.apiUrl + "/pos/planName/" + self.planCode,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            let response = res[0];

            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }
            this.$refs["modifyOperationModal"].show();
            this.isLoading = false;
          });
      },
      deleteOperationModal(operation_done_code, tradeCode) {
        let self = this;

        self.currentTradeCode = tradeCode;
        self.deleteOperation = operation_done_code;
        this.$refs["deleteOperationModal"].show();
      },
      addInvoice(operation_done_code, support, source) {
        this.isUser = source;
        this.operationDoneCode = operation_done_code;
        this.supportUsedSelected = support;
        this.$refs["addInvoiceModal"].show();
      },
      addProof(operation_done_code, support) {
        this.operationDoneCode = operation_done_code;
        this.supportUsedSelected = support;
        this.$refs["addProofModal"].show();
      },
      modifyRow() {
        let self = this;
        let index;
        let items = [];

        // self.currentTradeCode
        items = this.generalOperation;

        if (
          (self.switchState === true && self.operationInput !== null) ||
          self.switchState === false
        ) {
          for (let i = 0; i < items.length; i++) {
            if (self.operationDoneCode == items[i].operation_done_code) {
              index = i;
              if (self.operationInput && self.operationInput.label) {
                items[i].operation_name = self.operationInput.label;
              } else {
                items[i].operation_name = self.operationInputAdd;
              }

              items[i].support = self.supportUsedSelected.label;

              let models = [];

              for (let k = 0; k < self.modelSelected.length; k++) {
                models.push(self.modelSelected[k].label);
              }

              items[i].model = models;
              items[i].rate = self.rateInput;
              items[i].threshold = self.thresholdInput;
              var state = i;
            }
          }

          let supportUsedSelectedVar = self.supportUsedSelected.code;
          let modelSelected = [];
          let rateInput = self.rateInput;
          let thresholdInput = self.thresholdInput;
          let operationDoneCode = self.operationDoneCode;

          for (let j = 0; j < self.modelSelected.length; j++) {
            modelSelected.push(self.modelSelected[j].code);
          }

          let data;
          let operation = "";
          let operationCode = "";

          if (self.switchState == true) {
            operation = self.operationInput.label;
            operationCode = self.operationInput.code;
          } else {
            operation = self.operationInputAdd;
          }

          if (self.source == "lmp") {
            data = {
              source: self.source,
              state: state,
              operation_name: operation,
              operation_code: operationCode,
              operation_done_code: operationDoneCode,
              model: modelSelected,
              trade_code: self.currentTradeCode,
            };
          } else {
            data = {
              source: self.source,
              state: state,
              operation_name: operation,
              operation_code: operationCode,
              operation_done_code: operationDoneCode,
              media_support_code: supportUsedSelectedVar,
              model: modelSelected,
              title: self.supportInput,
              start_date: self.startDate,
              end_date: self.endDate,
              amount: self.amount,
              trade_code: self.currentTradeCode,
            };
          }

          if (self.operationInput && self.operationInput.isCrea == true) {
            data.crea_code = operationCode;
          }

          self.isLoading = true;
          http
            .post(
              this.$store.state.apiUrl + "/pos/modif",
              data,
              this.$cookie.get("auth"),
              this.$cookie.get("authExpires")
            )
            .then((res) => {
              if (res[1]) {
                common.refreshToken(
                  this,
                  res[1].data.token,
                  res[1].data.validity
                );
              }
              if (res[0].status == 200) {
                common.makeToast(
                  self,
                  self.$i18n.t("Success"),
                  self.$i18n.t("ModificationDone"),
                  self.brand + "-customSuccess",
                  "b-toaster-bottom-center",
                  true
                );
              } else {
                common.makeToast(
                  self,
                  "Erreur",
                  "No contrib found for this model and suppport",
                  self.brand + "-customDanger",
                  "b-toaster-bottom-center",
                  true
                );
              }
              self.isLoading = false;
              self.initTable(self);
            })
            .catch((error) => {
              self.isLoading = false;
              common.makeToast(
                self,
                self.$i18n.t("Error"),
                self.$i18n.t("ModificationFailed"),
                self.brand + "-customDanger",
                "b-toaster-bottom-center",
                true
              );
            });
          this.$refs["modifyOperationModal"].hide();
          self.isValidation = true;
        } else {
          self.isValidation = false;
          common.makeToast(
            self,
            self.$i18n.t("warning"),
            self.$i18n.t("pleaseFill"),
            self.brand + "-customWarning",
            "b-toaster-bottom-center",
            true
          );
        }
      },
      deleteRow() {
        let self = this;

        self.isLoading = true;
        http
          .delete(
            this.$store.state.apiUrl +
            "/pos/operationDone/" +
            self.deleteOperation,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            self.isLoading = false;
            common.makeToast(
              self,
              self.$i18n.t("Success"),
              self.$i18n.t("DeleteDone"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
          })
          .catch((error) => {
            self.isLoading = false;
            common.makeToast(
              self,
              self.$i18n.t("Error"),
              self.$i18n.t("DeleteFailed"),
              self.brand + "-customDanger",
              "b-toaster-bottom-center",
              true
            );
          });
        this.$refs["deleteOperationModal"].hide();
        self.initTable(self);
      },
      validate() {
        let self = this;

        self.isLoading = true;
        // Send all
        http
          .post(
            self.$store.state.apiUrl +
            "/global/validatePlanDone/" +
            self.planDoneCode,
            null,
            self.$cookie.get("auth"),
            self.$cookie.get("authExpires")
          )
          .then(function (res) {
            if (res[1]) {
              common.refreshToken(self, res[1].data.token, res[1].data.validity);
            }
            self.isLoading = false;
            common.makeToast(
              self,
              self.$i18n.t("Success"),
              self.$i18n.t("ValidationDone"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
            self.$refs["validateOperationModal"].hide();
            setTimeout(
              function () {
                self.$router.push({
                  name: "posDashboard",
                });
              }.bind(this),
              1500
            );
          })
          .catch(function () {
            self.isLoading = false;
            common.makeToast(
              self,
              self.$i18n.t("Error"),
              self.$i18n.t("ValidationFailed"),
              self.brand + "-customDanger",
              "b-toaster-bottom-center",
              true
            );
          });
      },
      downloadInvoice(document_done_code) {
        let proxy = null;

        if (process.env.VUE_APP_NODE_ENV == "localhost") {
          proxy = true;
        }

        this.isLoading = true;
        http
          .getPDF(
            this.$store.state.apiUrl + "/pos/document_done/" + document_done_code,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            const blob = new Blob([res[0].data], {
              type: res[0].headers["content-type"].split("[")[0],
            });

            FileSaver.saveAs(blob, res[0].headers["content-type"].split("[")[1]);
            this.isLoading = false;
          });
      },
      downloadProof(document_done_code) {
        let proxy = null;

        if (process.env.VUE_APP_NODE_ENV == "localhost") {
          proxy = true;
        }

        this.isLoading = true;
        http
          .getDocument(
            this.$store.state.apiUrl + "/pos/document_done/" + document_done_code,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            const blob = new Blob([res[0].data], {
              type: res[0].headers["content-type"].split("[")[0],
            });

            FileSaver.saveAs(blob, res[0].headers["content-type"].split("[")[1]);
            this.isLoading = false;
          }).catch((e) => {
            console.log(e);
            this.isLoading = false;
          });
      },
      deleteDocument(document_done_code) {
        let proxy = null;
        let self = this;

        if (process.env.VUE_APP_NODE_ENV == "localhost") {
          proxy = true;
        }

        this.isLoading = true;
        http
          .delete(
            this.$store.state.apiUrl + "/pos/document_done/" + document_done_code,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            self.initTable(self);
            common.makeToast(
              self,
              self.$i18n.t("successDeleteDocumentTitle"),
              self.$i18n.t("successDeleteDocumentBody"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
          });
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // Import custom SASS variable overrides
  @import "../assets/custom-vars.scss";

  .customTable th {
    padding-right: 15px;
    font-weight: normal;
  }

  .decorationNone {
    text-decoration: none;
  }

  .alignSwitch {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
  }

  .ml5 {
    margin-left: 5px !important;
  }

  .mr5 {
    margin-right: 5px !important;
  }

  .inlineCenter {
    display: inline-flex;
    align-items: center;
  }

  .cardInfo {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 15px;
    width: auto;
    height: 100px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: $minBorder;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .titleDetails {
    display: inline-flex;
    align-items: center;
    margin: 0;
    margin-left: 15px;
  }

  .actionDiv {
    height: 100px;
    align-items: flex-end;
    display: inline-flex;
  }

  .mt30 {
    margin-top: 30px;
  }

  .commentSection {
    border: 1px solid #ced4da;
    padding: 10px;
    margin: 0px;
    border-radius: 3px;
  }

  .mt5 {
    margin-top: 5px;
  }

  .mt10 {
    margin-top: 10px;
  }

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .info-btn {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    color: #333;
    margin-top: -5px;
  }
</style>