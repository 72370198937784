<template>
  <div id="rd-reports-menu">
    <b-button
      class="mt-3 ml-3"
      :to="`/${brand}/${$route.params.type}/dashboard/marketingplan`"
      pill
      :variant="customPrimaryVariant"
    >
      <i class="mr-2 fas fa-chevron-left"></i>
      {{ $t("GoBack") }}
    </b-button>
    <h1 class="my-4 text-center" v-html="$t('Report')"></h1>
    <p class="text-center" v-html="$t('SelectReportText')"></p>
    <ul class="report-choices">
      <li>
        <ReportChoice
          :isLoading="loadings.pos"
          @btnClicked="generatePremadeReport(filtersPos, 'pos', $event)"
          :text="$t('ReportByPOS')"
          icon="fas fa-map-marker-alt"
        />
      </li>
      <li>
        <ReportChoice
          :isLoading="loadings.week"
          @btnClicked="generatePremadeReport(filtersWeek, 'week', $event)"
          :text="$t('ReportByMonth')"
          icon="far fa-calendar-alt"
        />
      </li>
      <li>
        <ReportChoice
          :isLoading="loadings.medias"
          @btnClicked="generatePremadeReport(filtersEvent, 'medias', $event)"
          :text="$t('ReportBySection')"
          icon="fas fas fa-handshake"
        />
      </li>
      <li>
        <ReportChoice
          :isLoading="loadings.trade"
          @btnClicked="generatePremadeReport(filtersTrade, 'trade', $event)"
          :text="$t('ReportByTrade')"
          icon="fas fa-grip-horizontal"
        />
      </li>
    </ul>

    <ul class="report-choices">
      <li></li>
      <li>
        <ReportChoice
          :multi="false"
          @btnClicked="
            $router.push(
              `/${brand}/${$route.params.type}/reports/custom/forplan`
            )
          "
          :text="$t('CustomReport')"
          icon="far fa-file-alt"
        />
      </li>
      <li></li>
    </ul>
  </div>
</template>

<script>
import ReportChoice from "@/components/Report/ReportChoice";
import http from "@/helpers/http";
import moment from "moment";
import common from "@/helpers/common";

export default {
  name: "rdReportsMenu",
  components: { ReportChoice },
  beforeMount() {
    common.isAuth(this, http);
  },
  computed: {
    brand() {
      return this.$route.params.brand;
    },
    filtersPos() {
      return {
        sorting_choice: "point",
        allMedias: true,
        pos: [],
        start_date: this.firstDayInMonth(Date.now()).toString(),
        end_date: this.lastDayInMonth(Date.now()).toString(),
        start_week: moment().week(),
        range: 5,
        year: moment().format("YYYY"),
        title: "Rapport d'activité",
      };
    },
    filtersTrade() {
      return {
        sorting_choice: "trade",
        allMedias: true,
        pos: [],
        start_date: this.firstDayInMonth(Date.now()).toString(),
        end_date: this.lastDayInMonth(Date.now()).toString(),
        start_week: moment().week(),
        year: moment().format("YYYY"),
        range: 5,
        title: "Rapport d'activité",
      };
    },

    filtersEvent() {
      return {
        sorting_choice: "pre_event",
        allMedias: true,
        pos: [],
        start_date: this.firstDayInMonth(Date.now()).toString(),
        end_date: this.lastDayInMonth(Date.now()).toString(),
        start_week: moment().week(),
        year: moment().format("YYYY"),
        range: 5,
        title: "Rapport d'activité",
      };
    },
    filtersMedias() {
      return {
        sorting_choice: "media_category",
        allMedias: true,
        pos: [],
        start_date: this.firstDayInMonth(Date.now()).toString(),
        end_date: this.lastDayInMonth(Date.now()).toString(),
        start_week: moment().week(),
        year: moment().format("YYYY"),
        range: 5,
        title: "Rapport d'activité",
      };
    },

    filtersWeek() {
      return {
        sorting_choice: "point",
        allMedias: true,
        pos: [],
        start_week: this.getLastWeekPreviousMonth(),
        year: moment().format("YYYY"),
        range: 4,
        start_date: this.firstDayInMonth(Date.now()).toString(),
        end_date: this.lastDayInMonth(Date.now()).toString(),
        title: "Rapport d'activité",
      };
    },
  },
  data() {
    return {
      error: false,
      type_file: "pdf",
      customPrimaryVariant:
        window.location.pathname.split("/")[1] + "-customPrimary",
      loadings: {
        pos: false,
        week: false,
        medias: false,
        trade: false,
      },
    };
  },
  watch: {
    error() {
      if (this.error === true) {
        common.makeToast(
          this,
          this.$i18n.t("Error"),
          this.$i18n.t("noDataReport"),
          this.$route.params.brand + "-customDanger",
          "b-toaster-bottom-center",
          true
        );
        this.error = false;
      }
    },
  },
  methods: {
    getLastWeekPreviousMonth() {
      let lastMonth = moment().month();
      if (lastMonth !== 0) {
        lastMonth--;
      }
      const lastWeekOfLastMonth = moment().date(0).month(lastMonth).week();
      return lastWeekOfLastMonth;
    },
    firstDayInMonth(date) {
      let now = new Date();
      let lastday = new Date(now.getFullYear(), now.getMonth(), 0);
      let firstday = new Date(lastday.getFullYear(), lastday.getMonth(), 1);

      return firstday;
    },
    lastDayInMonth(date) {
      let now = new Date();
      let lastday = new Date(now.getFullYear(), now.getMonth(), 0);

      return lastday;
    },
    firstDayInPreviousMonth(date) {
      let d = new Date();

      return new Date(d.setDate(d.getDate() - 30));
    },
    lastDayInPreviousMonth(date) {
      let d = new Date();

      return new Date(d.setDate(d.getDate()));
    },
    async generatePremadeReport(filters, loadingToFire, format) {
      const myFormat = format;

      this.loadings[loadingToFire] = true;
      let res;
      try {
        if (myFormat === "pdf") {
          filters.type_file = "pdf";
          res = await http.postPDF(
            this.$store.state.apiUrl + "/global/generateCustomReportPreplan",
            filters,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          );
        } else {
          filters.type_file = "xlsx";
          res = await http.postExcel(
            this.$store.state.apiUrl + "/global/generateCustomReportPreplan",
            filters,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          );
        }

        if (res[0]) {
          this.forceFileDownload(res[0].data, myFormat);
        } else {
          this.error = true;
        }
        this.loadings[loadingToFire] = false;
      } catch (err) {
        this.error = true;
        this.loadings[loadingToFire] = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/custom-vars.scss";

#rd-reports-menu {
  .report-choices {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    padding: 0;
    width: 1000px;
    max-width: 95%;
    margin: 90px auto;

    > li {
      flex: 1;
      margin: 0 20px;
    }
  }
}
</style>
