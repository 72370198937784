<template>
  <div class="pad30">
    <div class="mb30">
      <div class="mb30">
        <b-button pill :variant="customPrimaryVariant" class="fw500" :to="{ name: 'rdDashboard' }">
          <i class="fas fa-arrow-left mr5"></i>
          {{ $t("Return") }}
        </b-button>

        <h5 class="text-left titleDetails">
          <span style="font-size: 70%">{{ $t("Abondement") }}</span>
          <i class="fas fa-angle-double-right ml5 mr5"></i>
          {{ $t("TitleRdCreate") }}
        </h5>
      </div>

      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
      <div role="group" class="period">
        <label class="fw500">{{ $t("selectBimestre") }}</label>
        <v-select id="periodSelected" v-model="periodSelected" :placeholder="$t('SelectPeriod')" :options="periods">
        </v-select>
      </div>

      <b-btn pill :variant="customPrimaryVariant" class="float-right mb15" v-b-modal.validateOperation
        v-bind:disabled="periodSelected === null">
        <i class="fas fa-check mr5" />
        <template v-if="isCreation === 0">{{ $t("Validate") }}</template>
        <template v-else>{{ $t("update") }}</template>
      </b-btn>
      <b-btn pill :variant="customPrimaryVariant" class="float-right mr5" v-bind:disabled="periodSelected === null"
        @click="downloadExcel">
        <i class="fas fa-file-download mr5" />
        {{ $t("DownloadModel") }}
      </b-btn>

      <b-btn @click="openQuotaVNModal()" pill :variant="customPrimaryVariant" class="float-right mr5"
        v-bind:disabled="periodSelected === null">
        <input type="file" id="fileQuotaVN" ref="fileQuotaVN" v-on:change="onFilePicked()" style="display:none;" />
        <template v-if="QuotaVNexist === 0">
          <i class="fas fa-file-upload mr5" />
          {{ $t("importQuotaVN") }}
        </template>
        <template v-else>
          <i class="fas fa-sync-alt mr5" />
          {{ $t("updateQuotaVN") }}
        </template>
      </b-btn>

      <b-btn v-if="allowRate" @click="openLimitModal()" pill :variant="customPrimaryVariant" class="float-right mr5"
        v-bind:disabled="periodSelected === null">
        <input type="file" id="fileLimit" ref="fileLimit" v-on:change="handleFileUpload()" style="display:none;" />
        <template v-if="limitStatus === 0">
          <i class="fas fa-file-upload mr5" />
          {{ $t("downloadLimitFile") }}
        </template>
        <template v-else>
          <i class="fas fa-sync-alt mr5" />
          {{ $t("updateLimitFile") }}
        </template>
      </b-btn>
      <CreateAbondanceTable :customPrimaryVariant="customPrimaryVariant" :scope="trade"
        :items="generalOperationObject[trade.trade_code]" :fields="fields" v-for="(trade, i) in trades"
        :showModalOperation="showModal" :passDeleteRow="passDeleteRow" :passModifyRow="passModifyRow" :key="i"
        :disabled="periodSelected === null" />

      <!-- ADD OPERATION TO LIST MODAL -->
      <b-modal no-close-on-backdrop no-close-on-esc id="addOperationToListModal" centered
        :title="$t('addOperationToList')" ref="addOperationToListModal">
        <div role="group">
          <label>{{ $t("addOperationToList") }}</label>
          <b-form-input v-model="addOperationToListInput" type="text" :placeholder="$t('setOperationName')">
          </b-form-input>
        </div>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="addOperationToList">
              {{ $t("Add") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill
              @click="hideSpecificModal('addOperationToListModal')">{{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <!-- Delete custom ceil -->
      <b-modal no-close-on-backdrop no-close-on-esc id="deleteCustomCeilModal" centered
        :title="$t('deleteCustomCeilTitle')" ref="deleteCustomCeilModal">

        <p>{{ $t("deleteCustomCeilBody") }}</p>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill
              @click="hideSpecificModal('deleteCustomCeilModal'); applyCustomCeil(false);">
              {{ $t("Delete") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill
              @click="hideSpecificModal('deleteCustomCeilModal')">{{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <b-modal no-close-on-backdrop no-close-on-esc hide-footer id="importQuotaVNModal" centered
        :title="$t('uploadQuotaVNTitle')" ref="importQuotaVNModal">
        <div id="uploadDiv">
          <p>{{ $t("uploadQuotaVNText") }}</p>
          <b-btn pill :variant="customPrimaryVariant" @click="onPickFile()">
            <i class="fas fa-file-upload mr5" />
            {{ $t("importFile") }}
          </b-btn>
          <div v-if="QuotaSize > 0" class="limitDiv">
            <p style="margin: 0px" :class="customPrimaryVariantText">
              <i class="fas fa-file mr5" />
              {{ QuotaSize }} / {{ posLength }} {{ $t("imported") }}
            </p>
          </div>

          <hr />
          <p>{{ $t("downloadQuotaVNTemplateText") }}</p>
          <b-btn pill :variant="customPrimaryOutlineVariant" href="/template_quotaVN.csv" download>
            <i class="fas fa-file-download mr5" />
            {{ $t("downloadTemplate") }}
          </b-btn>
        </div>
        <div id="successUploadDiv" style="display:none;">
          <p>{{$t("uploadQuotaVNSuccessText")}}</p>
          <!--<a pill @click="hideQuotaVNModal()" :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
            {{$t("getQuotaVNList")}}
          </a>-->
          <div style="margin-top:30px;">
            <b-btn @click="hideQuotaVNModal()" :variant="customPrimaryVariant" class="btn fw500 ml15 rounded-pill"
              style="float: right"> OK </b-btn>
          </div>
        </div>
      </b-modal>

      <b-modal no-close-on-backdrop no-close-on-esc hide-footer id="importLimitModal" centered
        :title="$t('importCeiling')" ref="importLimitModal" v-if="allowRate">
        <p>{{ $t("downloadLimitText") }}</p>
        <b-btn pill :variant="customPrimaryVariant" @click="handleFileClick()">
          <i class="fas fa-file-upload mr5" />
          {{ $t("downloadLimitFile3") }}
        </b-btn>
        <div v-if="limitSize > 0" class="limitDiv">
          <p style="margin: 0px" :class="customPrimaryVariantText">
            <i class="fas fa-file mr5" />
            {{ limitSize }} / {{ posLength }} {{ $t("imported") }}
          </p>
        </div>

        <hr />
        <p>{{ $t("downloadLimitTemplateText") }}</p>
        <b-btn pill :variant="customPrimaryOutlineVariant" href="/template.csv" download>
          <i class="fas fa-file-download mr5" />
          {{ $t("downloadTemplate") }}
        </b-btn>
      </b-modal>

      <b-modal no-close-on-backdrop no-close-on-esc id="validateOperation" centered :title="validateModifTitle"
        ref="validateOperationModal">
        <p>{{ validateModifText }}</p>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill v-bind:disabled="periodSelected === null"
              @click="addAll()">{{ $t("Validate") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill @click="hideModal"
              v-bind:disabled="periodSelected === null">{{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <!-- Add operation -->
      <b-modal no-close-on-backdrop no-close-on-esc id="addOperation" centered :title="addOpModalTitle" size="lg"
        ref="addOperationModal">
        <div role="group">
          <label class="inlineCenter">{{ $t("OperationName") }}</label>
          <b-button style="margin-bottom: 15px;" class="float-right ml5" :variant="customPrimaryOutlineVariant" pill
            @click="showModal('addOperationToListModal', null)">
            <i class="fas fa-plus mr5"></i>
            {{ $t("addOperationToList") }}
          </b-button>

          <div>
            <v-select :placeholder="$t('selectExistingOperation')" v-model="addOperationSelect" :options="operation"
              :class="{
              pleaseFill:
                (addOperationSelect === null ||
                  addOperationSelect.length <= 0) &&
                isValidation == false,
            }" style="display: inline-block; width: 661px" :selectable="(option) => !option.hasOwnProperty('group')">
              <span slot="no-options">{{ $t("operationDoesNotExist") }}</span>
              <template #option="{ group, label }">
                <div v-if="group" class="group">{{ group }}</div>
                {{ label }}
              </template>
            </v-select>
            <b-button class="i-block ml5" @click="passModifyOperationName" :variant="customPrimaryVariant" pill>
              <i class="fas fa-plus mr5"></i>
              {{ $t("Edit") }}
            </b-button>
          </div>


          <label style="margin-bottom: 15px; padding-top: 15px">
            {{ $t("SupportUsed") }}
          </label>
          <a class="float-right ml5 selectPadding" pill @click="unSelectAllSupport()"
            :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
            <i class="fas fa-times-circle"></i>
            Tout désélectionner
          </a>
          <a class="float-right ml5 selectPadding" pill @click="selectAllSupport()" :variant="customPrimaryVariantText"
            :class="customPrimaryVariantText">
            <i class="fas fa-check-circle"></i>
            Tout sélectionner
          </a>
          <v-select multiple v-model="supportUsedSelected" value="model_support_code" label="name"
            :placeholder="$t('SupportUsed')" :options="supports" :class="{
              pleaseFill:
                (supportUsedSelected === null ||
                  supportUsedSelected.length <= 0) &&
                isValidation == false,
            }"></v-select>

          <label for="modelInput" style="margin-bottom: 15px; padding-top: 15px">{{ $t("Model") }}</label>
          <a class="float-right ml5 selectPadding" pill @click="unSelectAllModel()" :variant="customPrimaryVariantText"
            :class="customPrimaryVariantText">
            <i class="fas fa-times-circle"></i>
            Tout désélectionner
          </a>
          <a class="float-right ml5 selectPadding" pill @click="selectAllModel()" :variant="customPrimaryVariantText"
            :class="customPrimaryVariantText">
            <i class="fas fa-check-circle"></i>
            Tout sélectionner
          </a>

          <v-select multiple v-model="modelSelected" value="model_code" label="name" :options="models"
            :placeholder="$t('Model')"></v-select>

          <b-row>

            <b-col cols="6">
              <b-form-group :label="$t('RateInput')" v-if="allowRate">
                <b-form-radio v-model="selectedRate" name="amountRadioAdd" value="percentage"
                  @click="applyCustomCeil(false, 'add')" class="inlineRadio">
                  pourcentage (%)</b-form-radio>
                <b-form-radio @click="applyCustomCeil(false, 'add')" v-model="selectedRate" name="amountRadioAdd"
                  value="amount" class="inlineRadio">budget (€)
                </b-form-radio>
                <b-form-radio @click="applyCustomCeil(false, 'add')" v-model="selectedRate" name="amountRadioAdd"
                  value="turnoverIndicator" class="iBlock">Quotas VN
                </b-form-radio>

                <div v-if="selectedRate === 'percentage' || selectedRate === 'amount'">
                  <b-form-input ref="rateInputAdd" v-model="rateInputAdd" v-mask="inputMask"
                    :placeholder="$t('RateInput')" class="mt10"></b-form-input>
                </div>
                <div v-else style="position: relative;">
                  <b-form-input type="number" id="rateInputAddPercentage" ref="rateInputAddPercentage"
                    v-model="rateInputAddPercentage" class="mt10"
                    style="width:45%; display:inline!important;"></b-form-input><div class="placeholder_percent">%</div>
                  <b-form-input type="number" id="rateInputAddAmount" ref="rateInputAddAmount" v-model="rateInputAddAmount"
                    class="mt10" style="margin-left: 20px; width:45%; display:inline!important;">
                    </b-form-input>
                  <div class="placeholder_amount">€</div>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="6" v-if="isCustomCeil === false && allowRate">
              <b-button class="float-right" pill :variant="customPrimaryOutlineVariant" size="md"
                style="width: 100%; margin-top: 60px;" @click="applyCustomCeil(true, 'add')">
                <i class="fas fa-tag mr5"></i>
                <template v-if="selectedRate === 'percentage'">
                  {{ $t("addCustomCeil") }}
                </template>
                <template v-else>
                  {{ $t("applyCustomCeil") }}
                </template>
              </b-button>
            </b-col>

            <b-col cols="6" v-if="isCustomCeil === true && selectedRate === 'percentage'">
              <label style="margin-top: 30px;">{{ $t("specificCeil") }}</label>
              <b-input-group>
                <b-form-input v-model="customCeil" ref="customCeil" :placeholder="$t('setSpecificCeil')"></b-form-input>
                <b-input-group-append>
                  <b-button :variant="customPrimaryOutlineVariant" @click="$bvModal.show('deleteCustomCeilModal')"><i
                      class="fas fa-trash-alt"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <b-col cols="6"
              v-if="isCustomCeil === true && (selectedRate === 'amount' || selectedRate === 'turnoverIndicator')">
              <b-button class="float-right" pill variant="outline-secondary" size="md"
                style="width: 100%; margin-top: 60px;" @click="applyCustomCeil(false, 'add')">
                <i class="fas fa-tag mr5"></i>
                {{ $t("cancelCustomCeil") }}
              </b-button>
            </b-col>
          </b-row>

        </div>

        <label for="commentInputAdd">{{ $t("commentInput") }}</label>
        <b-form-textarea rows="3" v-model="commentInputAdd" :placeholder="$t('writeCommentInput')"></b-form-textarea>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="addRow"
              v-bind:disabled="periodSelected === null">{{ $t("Add") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill @click="hideModal"
              v-bind:disabled="periodSelected === null">{{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <!-- Modify operation -->
      <b-modal no-close-on-backdrop no-close-on-esc id="modifyOperation" centered size="lg" :title="modifOpModalTitle"
        ref="modifyOperationModal">
        <b-container>
          <b-row>
            <b-col cols="6">
              <label class="inlineCenter"
                style="margin-bottom: 15px; padding-top: 10px">{{ $t("OperationName") }}</label>
            </b-col>
            <b-col cols="6">
              <b-button class="float-right" pill @click="showModal('addOperationToListModal', null)"
                :variant="customPrimaryOutlineVariant" size="md">
                <i class="fas fa-plus mr5"></i>
                {{ $t("addOperationToList") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12">
              <v-select v-model="addOperationSelect" :options="operation" :placeholder="$t('selectExistingOperation')"
                style="display: inline-block; width: 361px" :selectable="(option) => !option.hasOwnProperty('group')">
                <span slot="no-options">{{ $t("operationDoesNotExist") }}</span>
                <template #option="{ group, label }">
                  <div v-if="group" class="group">{{ group }}</div>
                  {{ label }}
                </template>
              </v-select>
              <b-button class="ml5 mt5 float-right" @click="passModifyOperationName" :variant="customPrimaryVariant"
                pill>
                <i class="fas fa-edit mr5"></i>
                {{ $t("Edit") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <label style="margin-bottom: 15px; padding-top: 15px">
                {{ $t("SupportUsed") }}
              </label>
            </b-col>
            <b-col cols="6">
              <a class="float-right ml5 selectPadding" pill @click="unSelectAllSupport()"
                :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
                <i class="fas fa-times-circle"></i>
                Tout désélectionner
              </a>
              <a class="float-right ml5 selectPadding" pill @click="selectAllSupport()"
                :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
                <i class="fas fa-check-circle"></i>
                Tout sélectionner
              </a>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <v-select multiple v-model="supportUsedSelected" value="model_support_code" label="name"
                :placeholder="$t('SupportUsed')" :options="supports"></v-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <label for="modelInput" style="margin-bottom: 15px; padding-top: 15px">{{ $t("Model") }}</label>
            </b-col>
            <b-col cols="6">
              <a class="float-right ml5 selectPadding" pill @click="unSelectAllModel()"
                :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
                <i class="fas fa-times-circle"></i>
                Tout désélectionner
              </a>
              <a class="float-right ml5 selectPadding" pill @click="selectAllModel()"
                :variant="customPrimaryVariantText" :class="customPrimaryVariantText">
                <i class="fas fa-check-circle"></i>
                Tout sélectionner
              </a>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <v-select multiple v-model="modelSelected" value="model_code" label="name" :options="models"
                :placeholder="$t('Model')"></v-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group :label="$t('RateInput')" v-if="allowRate">
                <b-form-radio v-model="selectedRateModify" name="amountRadioModify" value="percentage"
                  class="inlineRadio" @click="applyCustomCeil(false, 'modify')">pourcentage (%)</b-form-radio>
                <b-form-radio @click="applyCustomCeil(false, 'modify')" v-model="selectedRateModify" name="amountRadioModify"
                  value="amount" class="inlineRadio">budget(€)
                  </b-form-radio>
                <b-form-radio @click="applyCustomCeil(false, 'modify')" v-model="selectedRateModify" name="amountRadioModify"
                  value="turnoverIndicator" class="iBlock" >Quotas VN
                </b-form-radio>

                <div v-if="selectedRateModify === 'percentage' || selectedRateModify === 'amount'">
                  <b-form-input v-model="rateInput" ref="rateInput" v-mask="inputMask" :placeholder="$t('RateInput')"
                    class="mt10"></b-form-input>
                </div>
                <div v-else style="position: relative;">
                  <b-form-input type="number" id="rateInputPercentage" ref="rateInputPercentage" v-model="rateInputPercentage"
                    class="mt10" style="width:45%; display:inline!important;"></b-form-input>
                    <div class="placeholder_percent">%</div>
                  <b-form-input type="number" id="rateInputAmount" ref="rateInputAmount" v-model="rateInputAmount"
                    class="mt10" style="margin-left: 20px; width:45%; display:inline!important;"></b-form-input>
                    <div class="placeholder_amount">€</div>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="6" v-if="isCustomCeil === false && allowRate">
              <b-button class="float-right" pill :variant="customPrimaryOutlineVariant" size="md"
                style="width: 100%; margin-top: 60px;" @click="applyCustomCeil(true, 'modify')">
                <i class="fas fa-tag mr5"></i>
                <template v-if="selectedRateModify === 'percentage'">
                  {{ $t("addCustomCeil") }}
                </template>
                <template v-else>
                  {{ $t("applyCustomCeil") }}
                </template>
              </b-button>
            </b-col>

            <b-col cols="6" v-if="isCustomCeil === true && selectedRateModify === 'percentage'">
              <label style="margin-top: 30px;">{{ $t("specificCeil") }}</label>
              <b-input-group>
                <b-form-input v-model="customCeil" ref="customCeil" v-mask="inputMask"
                  :placeholder="$t('setSpecificCeil')"></b-form-input>
                <b-input-group-append>
                  <b-button :variant="customPrimaryOutlineVariant" @click="$bvModal.show('deleteCustomCeilModal')"><i
                      class="fas fa-trash-alt"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <b-col cols="6" v-if="isCustomCeil === true && (selectedRateModify === 'amount' || selectedRateModify === 'turnoverIndicator')">
              <b-button class="float-right" pill variant="outline-secondary" size="md"
                style="width: 100%; margin-top: 60px;" @click="applyCustomCeil(false, 'modify')">
                <i class="fas fa-tag mr5"></i>
                {{ $t("cancelCustomCeil") }}
              </b-button>
            </b-col>

          </b-row>
          <b-row>
            <b-col>
              <label for="commentInputModify">{{ $t("commentInput") }}</label>
              <b-form-textarea rows="3" v-model="commentInputModify" :placeholder="$t('writeCommentInput')">
              </b-form-textarea>
            </b-col>
          </b-row>
        </b-container>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="modifyRow"
              v-bind:disabled="periodSelected === null">{{ $t("Edit") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill @click="hideModal"
              v-bind:disabled="periodSelected === null">{{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <!-- Delete operation -->
      <b-modal no-close-on-backdrop no-close-on-esc id="deleteOperation" centered :title="deleteOpModalTitle"
        ref="deleteOperationModal">
        <p>{{ $t("DeleteOperation") }}</p>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="deleteRow"
              v-bind:disabled="periodSelected === null">{{ $t("Delete") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill @click="hideModal"
              v-bind:disabled="periodSelected === null">{{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <!-- Modify operation name -->
      <b-modal no-close-on-backdrop no-close-on-esc id="modifyOperationName" centered
        title="Modifier le nom de l'opération" ref="modifyOperationNameModal">
        <div role="group">
          <label for="modifyOperationNameInput">
            {{ $t("operationName") }}
          </label>
          <b-form-input v-model="modifyOperationNameInput" placeholder="Nom de l'opération"></b-form-input>
        </div>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="modifyOperationName"
              v-bind:disabled="periodSelected === null">{{ $t("Edit") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill
              @click="hideSpecificModal('modifyOperationNameModal')" v-bind:disabled="periodSelected === null">
              {{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
  import http from "../helpers/http";
  import common from "../helpers/common";
  import Loading from "vue-loading-overlay";
  import CreateAbondanceTable from "@/components/Table/CreateAbondance.vue";
  import "vue-loading-overlay/dist/vue-loading.css";
  import FileSaver from "file-saver";

  export default {
    beforeMount() {
      common.isAuth(this, http);
    },
    data() {
      const allowRate = this.$cookie.get("allowRate") == "true";
      const headerRate = []; // add header if you need

      if (allowRate) {
        headerRate.push({
          key: "contribLabel",
          label: this.$i18n.t("tableAbundance"),
          tdClass: "text-right",
        });
      }

      return {
        isCustomCeil: false,
        customCeil: null,
        addOpModalTitle: this.$i18n.t("AddOperation"),
        modifOpModalTitle: this.$i18n.t("modifOp"),
        deleteOpModalTitle: this.$i18n.t("OperationDelete"),
        generalOperation: [],
        generalOperationTheme: [],
        posLength: 0,
        limitStatus: 0,
        limitSize: 0,
        threshold: 0,
        QuotaVNexist: 0,
        QuotaSize: 0,
        startDate: null,
        endDate: null,
        modifyOperationNameCode: "",
        modifyOperationNameInput: "",
        allowRate,
        currentTradeCode: "",
        isCreation: 0,
        selectedRateModify: "percentage",
        selectedRate: "percentage",
        inputMask: "###",
        commentInputModify: "",
        commentInputAdd: "",
        isValidation: true,
        addOperationToListInput: "",
        brand: window.location.pathname.split("/")[1],
        customPrimaryOutlineVariant: "outline-" + window.location.pathname.split("/")[1] + "-customPrimary",
        customPrimaryVariant: window.location.pathname.split("/")[1] + "-customPrimary",
        customPrimaryVariantText: "text-" + window.location.pathname.split("/")[1] + "-customPrimary",
        oldRate: "",
        isLoading: true,
        fullPage: true,
        dataSendAll: [],
        models: [],
        supports: [],
        periods: [],
        planCode: "",
        addOperationInput: "",
        addOperationSelect: [],
        operation: [],
        rateInput: 0,
        rateInputPercentage: null,
        rateInputAmount: 0,
        rateInputAdd: 0,
        rateInputAddPercentage: null,
        rateInputAddAmount: 0,
        supportUsedSelected: [],
        modelSelected: [],
        periodSelected: null,
        tableIndex: null,
        tableItem: null,
        operationCode: "",
        checkMediaModel: "",
        file: "",
        fields: [{
            key: "operation_name",
            label: this.$i18n.t("tableOperation"),
          },
          {
            key: "support",
            label: this.$i18n.t("tableSupport"),
            formatter: this.joinArray,
          },
          {
            key: "model",
            label: this.$i18n.t("tableModel"),
            formatter: this.joinArray,
          },
          ...headerRate,
          {
            key: "comment",
            label: this.$i18n.t("tableComment"),
          },
          {
            key: "actions",
            label: this.$i18n.t("tableActions"),
            tdClass: "text-center w-actions",
          },
        ],
        items: [],
        contribKey: "",
        validateModifText: this.$i18n.t("validateOpDr"),
        validateModifTitle: this.$i18n.t("TitleValidateOperation"),
      };
    },
    components: {
      Loading,
      CreateAbondanceTable,
    },
    computed: {
      generalOperationObject() {
        const myOperationObjet = {
          themes: []
        };

        this.generalOperation.forEach((element) => {
          if (!element.trade_code) {
            myOperationObjet["themes"].push(element);
          } else {
            if (myOperationObjet[element.trade_code]) {
              myOperationObjet[element.trade_code].push(element);
            } else {
              myOperationObjet[element.trade_code] = [element];
            }
          }
        });

        return myOperationObjet;
      },

      trades() {
        return this.$store.getters.getTradesFull || [];
      },
    },
    mounted() {
      this.$store.dispatch("getTrade", this.$cookie);

      this.isLoading = true;

      // Get period
      http
        .get(
          this.$store.state.apiUrl + "/global/period/",
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        )
        .then((res) => {
          let response = res[0];
          let array = [];

          if (res[1]) {
            common.refreshToken(this, res[1].data.token, res[1].data.validity);
          }

          for (let i = 0; i < response.data.length; i++) {
            array.push({
              start_date: response.data[i].start_date,
              end_date: response.data[i].end_date,
              label: response.data[i].label,
              code: response.data[i].plan_code,
              status: response.data[i].status,
            });
          }
          this.periods = array;
          this.isLoading = false;
        });

      // Get models
      http
        .get(
          this.$store.state.apiUrl + "/global/model/",
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        )
        .then((res) => {
          let response = res[0];
          let array = [];

          if (res[1]) {
            common.refreshToken(this, res[1].data.token, res[1].data.validity);
          }

          for (let i = 0; i < response.data.length; i++) {
            array.push({
              name: response.data[i].name,
              model_code: response.data[i].model_code,
            });
          }
          this.models = array;
        });

      // Get supports
      http
        .get(
          this.$store.state.apiUrl + "/global/media_support/",
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        )
        .then((res) => {
          let response = res[0];
          let array = [];

          if (res[1]) {
            common.refreshToken(this, res[1].data.token, res[1].data.validity);
          }

          for (let i = 0; i < response.data.length; i++) {
            array.push({
              name: response.data[i].name,
              media_support_code: response.data[i].media_support_code,
            });
          }
          this.supports = array;
        });
    },
    watch: {
      currentTradeCode(val) {
        const tradeSelected = this.trades.find((el) => el.trade_code === val);
        const TradeName = tradeSelected.label; // must be changed
        this.addOpModalTitle = TradeName.concat(
          " - ",
          this.$i18n.t("AddOperation")
        );
        this.modifOpModalTitle = TradeName.concat(" - ", this.$i18n.t("modifOp"));
        this.deleteOpModalTitle = TradeName.concat(
          " - ",
          this.$i18n.t("OperationDelete")
        );
      },
      addOperationSelect(val) {
        if (val) {
          if (val.creaToModel) {
            for (let j = 0; j < val.creaToModel.length; j++) {
              for (let i = 0; i < this.models.length; i++) {
                if (this.models[i].model_code === val.creaToModel[j]) {
                  if (
                    this.modelSelected.some(
                      (e) => e.code === val.creaToModel[j]
                    ) === false
                  ) {
                    this.modelSelected.push({
                      code: this.models[i].model_code,
                      name: this.models[i].name,
                    });
                  }
                }
              }
            }
          }
          if (val.comment) {
            this.commentInputAdd = val.comment;
            this.commentInputModify = val.comment;
          } else if (val[0]) {
            if (val[0].comment) {
              this.commentInputModify = val[0].comment;
            } else {
              this.commentInputModify = "";
            }
          } else {
            this.commentInputAdd = "";
            this.commentInputModify = "";
          }
        } else {
          this.commentInputAdd = "";
          this.commentInputModify = "";
        }
      },
      periodSelected() {
        let self = this;

        self.periodSelectedFunction(self);
      },
      // Add rate input
      rateInputAdd: function (val) {
        this.changeInputValues(this.selectedRate, val, "add");
      },
      // Add rate input Percentage
      rateInputAddPercentage: function (val) {
        this.changeInputValues(this.selectedRate, val, "add");
      },
      // Add rate input 
      rateInputAddAmount: function (val) {
        this.changeInputValues(this.selectedRate, val, "add");
      },
      // Modify rate input
      rateInput: function (val) {
        this.changeInputValues(this.selectedRateModify, val, "modify");
      },
      // Modify rate input
      rateInputPercentage: function (val) {
        this.changeInputValues(this.selectedRateModify, val, "modify");
      },
      // Modify rate input
      rateInputAmount: function (val) {
        this.changeInputValues(this.selectedRateModify, val, "modify");
      },
      selectedRate: function (val) {
        this.changeInputValues(this.selectedRate, this.rateInputAdd, "add");
      },
      selectedRateModify: function (val) {
        this.changeInputValues(this.selectedRateModify, this.rateInput, "modify");
      },
    },
    created() {
      let self = this;

      http
        .get(
          self.$store.state.apiUrl + "/global/posList/",
          self.$cookie.get("auth"),
          self.$cookie.get("authExpires")
        )
        .then((res) => {
          if(res[0] && res[0].data){
          let response = res[0].data;

          self.posLength = response.split(",").length;
          }
        });
    },
    methods: {
      applyCustomCeil(param, mode = null) {
        this.isCustomCeil = param;

        if (param == false) {
          this.customCeil = null;
        }
      },
      changeInputValues(selectedRate, val, mode = "add") {
        if (selectedRate == "percentage") {
          val = Number(val);
          this.inputMask = "###";

          if (this.onlyNumber(val) > 100) {
            if (mode === "modify") {
              this.rateInput = 100;
            } else {
              this.rateInputAdd = 100;
            }
          } else {
            if (mode === "modify") {
              this.rateInput = this.onlyNumber(val);
            } else {
              this.rateInputAdd = this.onlyNumber(val);
            }
          }
        } else if (selectedRate == "amount") {
          this.inputMask = "##########";
          if (mode === "modify") {
            this.rateInput = Number(this.rateInput) || 0;
          } else {
            this.rateInputAdd = Number(this.rateInputAdd) || 0;
          }
        } else {
          this.inputMask = "##########";
          if (mode === "modify") {
            this.rateInputAmount = Number(this.rateInputAmount) || 0;
            if (this.rateInputPercentage && this.onlyNumber(this.rateInputPercentage) > 100) {
              this.rateInputPercentage = 100;
            } else {
              this.rateInputPercentage = Number(this.rateInputPercentage) || null;
            }
          } else {
            this.rateInputAddAmount = Number(this.rateInputAddAmount) || 0;
            if (this.rateInputAddPercentage && this.onlyNumber(this.rateInputAddPercentage) > 100) {
              this.rateInputAddPercentage = 100;
            } else {
              this.rateInputAddPercentage = Number(this.rateInputAddPercentage) || null;
            }
          }
        }
      },
      openQuotaVNModal() {
        this.$refs["importQuotaVNModal"].show();
      },
      hideQuotaVNModal() {
        this.$root.$emit('bv::hide::modal', 'importQuotaVNModal');
      },
      onPickFile() {
        document.getElementById("fileQuotaVN").click();
      },
      onFilePicked() {
        let self = this;
        let formData = new FormData();

        self.file = self.$refs.fileQuotaVN.files[0];
        formData.append("file", self.file);

        self.isLoading = true;

        http
          .postFile(
            self.$store.state.apiUrl +
            "/global/uploadTurnover/?plan_code=" +
            self.planCode,
            formData,
            self.$cookie.get("auth"),
            self.$cookie.get("authExpires")
          )
          .then(function (res) {
            if (res[1]) {
              common.refreshToken(self, res[1].data.token, res[1].data.validity);
            }

            common.makeToast(
              self,
              "Fichier importé",
              "Les données ont bien été importées",
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );

            document.getElementById("uploadDiv").style.display = "none";
            document.getElementById("successUploadDiv").style.display = "block";

            self.isLoading = false;
            self.file = "";
            self.$refs.fileQuotaVN.value = null;
            self.periodSelectedFunction(self);
          })
          .catch(function (error) {
            self.isLoading = false;
            self.file = "";
            self.$refs.fileQuotaVN.value = null;
          });
      },
      openLimitModal() {
        this.$refs["importLimitModal"].show();
      },
      handleFileClick() {
        document.getElementById("fileLimit").click();
      },
      handleFileUpload() {
        let self = this;
        let formData = new FormData();

        self.file = self.$refs.fileLimit.files[0];

        formData.append("file", self.file);

        self.isLoading = true;
        http
          .postFile(
            self.$store.state.apiUrl +
            "/global/uploadLimit/?plan_code=" +
            self.planCode,
            formData,
            self.$cookie.get("auth"),
            self.$cookie.get("authExpires")
          )
          .then(function (res) {
            if (res[1]) {
              common.refreshToken(self, res[1].data.token, res[1].data.validity);
            }
            let response = res[0];

            common.makeToast(
              self,
              "Fichier importé",
              "Les données ont bien été importées",
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
            self.$refs["importLimitModal"].hide();
            self.isLoading = false;
            self.file = "";
            self.$refs.fileLimit.value = null;
            self.periodSelectedFunction(self);
          })
          .catch(function (error) {
            self.isLoading = false;
            self.file = "";
            self.$refs.fileLimit.value = null;
          });
      },
      passModifyOperationName() {
        let self = this;

        if (self.addOperationSelect) {
          if (self.addOperationSelect.code) {
            if (self.checkMediaModel == 'false') {
              let regex = /-[0-9]+$/gm;
              let nameWithoutTag = self.addOperationSelect.label.replace(regex, '');
              self.modifyOperationNameInput = nameWithoutTag;
              self.modifyOperationNameCode = self.addOperationSelect.code;
            } else {
              self.modifyOperationNameInput = self.addOperationSelect.label;
              self.modifyOperationNameCode = self.addOperationSelect.code;
            }
            /*
                        self.modifyOperationNameInput = self.addOperationSelect.label;
                        self.modifyOperationNameCode = self.addOperationSelect.code;
                        */
          } else {
            if (self.addOperationSelect[0]) {
              if (self.checkMediaModel == 'false') {
                let regex = /-[0-9]+$/gm;
                let nameWithoutTag = self.addOperationSelect[0].label.replace(regex, '');
                self.modifyOperationNameInput = nameWithoutTag;
                self.modifyOperationNameCode = self.addOperationSelect[0].code;
              } else {
                self.modifyOperationNameInput = self.addOperationSelect[0].label;
                self.modifyOperationNameCode = self.addOperationSelect[0].code;
              }
              /*
                            self.modifyOperationNameInput = self.addOperationSelect[0].label;
                            self.modifyOperationNameCode = self.addOperationSelect[0].code;
                            */
            }
          }
        }
        self.$refs["modifyOperationNameModal"].show();
      },
      modifyOperationName() {
        let self = this;

        if (self.modifyOperationNameInput != "") {
          let object = {
            label: self.modifyOperationNameInput,
          };
          this.isLoading = true;
          http
            .patch(
              self.$store.state.apiUrl +
              "/rd/operationName/" +
              self.modifyOperationNameCode,
              object,
              self.$cookie.get("auth"),
              self.$cookie.get("authExpires")
            )
            .then(function (res) {
              if (res[1]) {
                common.refreshToken(
                  self,
                  res[1].data.token,
                  res[1].data.validity
                );
              }

              self.$refs["modifyOperationNameModal"].hide();
              self.$refs["modifyOperationModal"].hide();
              self.$refs["addOperationModal"].hide();
              common.makeToast(
                self,
                "Opération renomée",
                "L'opération a été renommée avec succès",
                self.brand + "-customSuccess",
                "b-toaster-bottom-center",
                true
              );
              self.isLoading = false;
              self.periodSelectedFunction(self);
            })
            .catch(function (error) {
              self.isLoading = false;
            });
        } else {
          common.makeToast(
            self,
            "Attention",
            self.$i18n.t("missingModifyOperation"),
            self.brand + "-customWarning",
            "b-toaster-bottom-center",
            true
          );
        }
      },
      unSelectAllModel() {
        this.modelSelected = [];
      },
      selectAllModel() {
        this.modelSelected = [...this.models];
      },
      unSelectAllSupport() {
        this.supportUsedSelected = [];
      },
      selectAllSupport() {
        this.supportUsedSelected = [...this.supports];
      },
      addOperationToList() {
        let self = this;
        let object = {
          name: self.addOperationToListInput,
          plan_code: self.planCode,
          trade_code: self.currentTradeCode,
        };

        http
          .post(
            self.$store.state.apiUrl + "/rd/operationName",
            object,
            self.$cookie.get("auth"),
            self.$cookie.get("authExpires")
          )
          .then(function (res) {
            if (res[1]) {
              common.refreshToken(self, res[1].data.token, res[1].data.validity);
            }
            let response = res[0];

            self.operation.push({
              code: response.data.operation_code,
              plan_code: response.data.plan_code,
              label: response.data.name,
              status: response.data.status,
            });

            self.addOperationSelect = {
              code: response.data.operation_code,
              plan_code: response.data.plan_code,
              label: response.data.name,
              status: response.data.status,
            };
            self.$refs["addOperationToListModal"].hide();
            common.makeToast(
              self,
              self.$i18n.t("successAddOperationToListTitle"),
              self.$i18n.t("successAddOperationToListBody"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
            self.isLoading = false;
          })
          .catch(function (error) {
            self.isLoading = false;
          });
      },
      numberThousand(x) {
        if (x > 0) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        } else {
          return 0;
        }
      },
      onlyNumber(val) {
        if (val === "") {
          val = 0;
        }

        if (val != 0) {
          val = val.toString().replace(/^0+/, "");
          return parseInt(val.toString().replace(/[^0-9]/g, ""));
        } else {
          return parseInt(val);
        }
      },
      periodSelectedFunction(self) {
        if (self.periodSelected) {
          self.planCode = self.periodSelected.code;
          self.isCreation = self.periodSelected.status;
          self.startDate = self.periodSelected.start_date;
          self.endDate = self.periodSelected.end_date;
          self.isLoading = true;

          if (self.isCreation === 0) {
            self.validateModifText = self.$i18n.t("validateOpDr");
            self.validateModifTitle = self.$i18n.t("TitleValidateOperation");
          } else {
            self.validateModifText = self.$i18n.t("modifOpDr");
            self.validateModifTitle = self.$i18n.t("TitleModifOperation");
          }

          http
            .get(
              self.$store.state.apiUrl +
              "/rd/contributionslimit/" +
              self.periodSelected.code,
              self.$cookie.get("auth"),
              self.$cookie.get("authExpires")
            )
            .then((res) => {
              if(res[0] && res[0].data){
                let response = res[0].data;

                self.limitStatus = response.status;
                self.limitSize = response.size;
              }
            });

          // http
          //   .get(
          //     self.$store.state.apiUrl +
          //     "/rd/contributionsturnoverindicator/" +
          //     self.periodSelected.code,
          //     self.$cookie.get("auth"),
          //     self.$cookie.get("authExpires")
          //   )
          //   .then((res) => {
          //     if(res[0] && res[0].data){
          //       let response = res[0].data;

          //       self.QuotaVNexist = response.status;
          //       self.QuotaSize = response.size;
          //     }
          //   });

          http
            .get(
              self.$store.state.apiUrl +
              "/rd/operations/" +
              self.periodSelected.code,
              self.$cookie.get("auth"),
              self.$cookie.get("authExpires")
            )
            .then((res) => {
              let response = res[0].data.trades;

              this.checkMediaModel = res[0].data.checkMediaModel;

              this.generalOperation = [];

              if (res[1]) {
                common.refreshToken(
                  self,
                  res[1].data.token,
                  res[1].data.validity
                );
              }

              for (let i = 0; i < response.length; i++) {
                for (let y = 0; y < response[i].data.length; y++) {
                  let supports = [];
                  let models = [];

                  for (let j = 0; j < response[i].data[y].medias.length; j++) {
                    supports.push({
                      name: response[i].data[y].medias[j].name,
                      media_support_code: response[i].data[y].medias[j].media_code,
                    });
                  }

                  for (let k = 0; k < response[i].data[y].models.length; k++) {
                    models.push({
                      name: response[i].data[y].models[k].name,
                      model_code: response[i].data[y].models[k].model_code,
                    });
                  }

                  let supportName = [];
                  let modelName = [];
                  let supportCode = [];
                  let modelCode = [];

                  for (let l = 0; l < supports.length; l++) {
                    supportName.push(supports[l].name);
                    supportCode.push(supports[l].media_support_code);
                  }

                  for (let m = 0; m < models.length; m++) {
                    modelName.push(models[m].name);
                    modelCode.push(models[m].model_code);
                  }

                  let comment = "";

                  if (response[i].data[y].comment) {
                    comment = response[i].data[y].comment;
                  }

                  let contrib = "";

                  if (response[i].data[y].contribLabel) {
                    contrib = response[i].data[y].contribLabel;
                    if(response[i].data[y].turnoverContribution){
                      if(response[i].data[y].turnoverContribution.rateLimit){
                        contrib = (response[i].data[y].turnoverContribution.amount/100) + ' € ' + this.$t("QuotaVNLimite") + response[i].data[y].turnoverContribution.rateLimit + ' % ' + this.$t("QuotaVNLabel");  
                      } else {
                        contrib = (response[i].data[y].turnoverContribution.amount/100) + ' € ' + this.$t("QuotaVNLabel"); 
                      }
                    }
                  } else {
                    if (response[i].data[y].customCeil) {
                      if(response[i].data[y].customCeil.amount){
                        if (response[i].data[y].customCeil.rateLimit){
                          contrib = (response[i].data[y].customCeil.amount/100) + ' € ' + this.$t("QuotaVNLimite") + response[i].data[y].customCeil.rateLimit + ' % ' +  this.$t("QuotaVNLabel");
                        } else {
                          contrib = (response[i].data[y].customCeil.amount/100) + ' € ' + this.$t("QuotaVNLabel");
                        }
                        response[i].data[y].customCeil = contrib
                      }
                      else{
                        contrib = response[i].data[y].customCeil + " €";
                        response[i].data[y].customCeil = contrib;
                      }
                    }
                  }

                  let temp = {
                    customCeil: response[i].data[y].customCeil,
                    operation_name: response[i].data[y].name,
                    operation_code: response[i].data[y].operation_code,
                    supports: supports,
                    support: supportName,
                    media_support_code: supportCode,
                    models: models,
                    model: modelName,
                    model_code: modelCode,
                    rate: response[i].data[y].rate,
                    budgetAmount: response[i].data[y].budgetAmount,
                    turnoverContribution: response[i].data[y].turnoverContribution,
                    status: response[i].data[y].status,
                    comment: comment,
                    contribKey: response[i].data[y].contribKey,
                    contribLabel: contrib,
                    trade_code: response[i].trade_code,
                  };

                  /* Must be a computed */
                  //console.log(temp);
                  this.generalOperation.push(temp);
                }
              }

              self.isLoading = false;
            });

          // Get operation
          http
            .get(
              self.$store.state.apiUrl +
              "/global/operation/" +
              self.planCode +
              "?start_date=" +
              self.startDate +
              "&end_date=" +
              self.endDate,
              self.$cookie.get("auth"),
              self.$cookie.get("authExpires")
            )
            .then((res) => {
              let response = res[0];
              let array = [];

              if (res[1]) {
                common.refreshToken(
                  self,
                  res[1].data.token,
                  res[1].data.validity
                );
              }
              this.generalOperationTheme = [];
              for (let i = 0; i < response.data[0].trades.length; i++) {
                for (let j = 0; j < response.data[0].trades[i].data.length; j++) {
                  //if statement to add only theme and operation without details 
                  //if(!(response.data[0].trades[i].data[j].details) || response.data[0].trades[i].data[j].details.length <= 2){
                  let temp = {
                    code: response.data[0].trades[i].data[j].operation_code,
                    plan_code: response.data[0].trades[i].data[j].plan_code,
                    label: response.data[0].trades[i].data[j].name,
                    status: response.data[0].trades[i].data[j].status,
                    isCrea: response.data[0].trades[i].data[j].isCrea,
                    creaToModel: response.data[0].trades[i].data[j].creaToModel,
                    trade_code: response.data[0].trades[i].data[j].trade_code,
                  };
                  this.generalOperationTheme.push(temp);
                  //}
                }
              }
            });
        } else {
          this.generalOperation = [];
        }
      },
      downloadExcel() {
        let proxy = null;

        if (process.env.VUE_APP_NODE_ENV == "localhost") {
          proxy = true;
        }

        this.isLoading = true;
        http
          .getExcel(
            this.$store.state.apiUrl +
            "/rd/document_template_generation/" +
            this.periodSelected.code,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            const blob = new Blob([res[0].data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, Math.random() + ".xlsx");
            this.isLoading = false;
          });
      },
      isNumber(event) {
        this.$store.state.isNumber(event);
      },
      joinArray(array) {
        if (Array.isArray(array)) {
          return array.join(", ");
        } else {
          return array;
        }
      },
      hideModal() {
        this.$refs["addOperationModal"].hide();
        this.$refs["modifyOperationModal"].hide();
        this.$refs["deleteOperationModal"].hide();
        this.$refs["validateOperationModal"].hide();
      },
      hideSpecificModal(ref) {
        this.$refs[ref].hide();
      },
      setOptGroups(arrayOperation) {
        // see with alex
        let self = this;
        let array = [];

        for (let i = 0; i < arrayOperation.length; i++) {
          if (arrayOperation[i].isCrea == false) {
            if (!array.filter((e) => e.code === "OP").length > 0) {
              array.push({
                group: self.$i18n.t("Operations"),
                label: null,
                code: "OP",
              });
            }
            array.push(arrayOperation[i]);
          } else {
            if (!array.filter((e) => e.code === "TH").length > 0) {
              array.push({
                group: self.$i18n.t("themes"),
                label: null,
                code: "TH",
                creaToModel: arrayOperation[i].creaToModel,
              });
            }
            array.push(arrayOperation[i]);
          }
        }

        return array;
      },
      setOperation(tradeCode) {
        let self = this;

        const scope = this.trades.find((el) => el.trade_code === tradeCode);

        // Get allow
        // this.generalOperationObject
        self.operation = self.setOptGroups(
          this.generalOperationTheme.filter((el) => el.trade_code === tradeCode)
        );

        self.operation = self._.uniqBy(self.operation, "code");
      },
      showModal(ref, tradeCode) {
        if (tradeCode != null) {
          this.currentTradeCode = tradeCode;
        }
        this.setOperation(tradeCode);
        this.isValidation = true;
        if (ref == "addOperationModal") {
          this.addOperationSelect = null;
          this.addOperationInput = "";
          this.modelSelected = [];
          this.supportUsedSelected = [];
          this.rateInputAdd = 0;
        }
        this.$refs[ref].show();
      },
      addRow() {
        let self = this;

        add(self);

        function add(self) {
          let supportName = [];
          let modelName = [];
          let supportCode = [];
          let modelCode = [];
          let operationName = "";
          let operationCode;
          let isCrea = false;

          for (let l = 0; l < self.supportUsedSelected.length; l++) {
            supportName.push(self.supportUsedSelected[l].name);
            supportCode.push(self.supportUsedSelected[l].media_support_code);
          }

          for (let m = 0; m < self.modelSelected.length; m++) {
            modelName.push(self.modelSelected[m].name);
            modelCode.push(
              self.modelSelected[m].model_code || self.modelSelected[m].code
            );
          }

          if (self.addOperationSelect) {
            operationName = self.addOperationSelect.label;
            operationCode = self.addOperationSelect.code;
            isCrea = self.addOperationSelect.isCrea;
          }

          let object = {
            operation_name: operationName,
            plan_code: self.planCode,
            supports: supportCode,
            models: modelCode,
            status: 0,
            isCrea: isCrea,
            isCreationMode: true,
            trade_code: self.currentTradeCode,
            turnoverIndicator: false
          };
          if (self.selectedRate == "percentage") {
            object["rate"] = parseInt(self.rateInputAdd, 10);
            object["customCeil"] = self.customCeil;
          } else if (self.selectedRate == "amount") {
            if (self.isCustomCeil === true) {
              object["customCeil"] = parseInt(self.rateInputAdd, 10);
            } else {
              object["budgetAmount"] = parseInt(self.rateInputAdd, 10);
            }
          } else { //turnoverIndicator
            object['turnoverIndicator'] = true;
            if (self.isCustomCeil === true) {
              object["customCeil"] = {
                "amount": parseInt(self.rateInputAddAmount*100, 10) || 0,
                "rateLimit": parseInt(self.rateInputAddPercentage, 10) || null
              };
            } else {
              object['turnoverContribution'] = {
                "amount": parseInt(self.rateInputAddAmount*100, 10) || 0,
                "rateLimit": parseInt(self.rateInputAddPercentage, 10) || null
              };
            }
          }

          // Add comment if not empty
          if (self.commentInputAdd != "") {
            object["comment"] = self.commentInputAdd;
          }

          object["operation_code"] = operationCode;

          if (supportCode.length == 0 || operationName == "") {
            self.isValidation = false;
            common.makeToast(
              self,
              "Attention",
              self.$i18n.t("missingAddOperation"),
              self.brand + "-customWarning",
              "b-toaster-bottom-center",
              true
            );
          } else {
            self.isLoading = true;
            http
              .post(
                self.$store.state.apiUrl + "/rd/operation",
                object,
                self.$cookie.get("auth"),
                self.$cookie.get("authExpires")
              )
              .then(function (res) {
                if (res[1]) {
                  common.refreshToken(
                    self,
                    res[1].data.token,
                    res[1].data.validity
                  );
                }
                self.$refs["addOperationModal"].hide();
                self.isLoading = false;

                self.periodSelectedFunction(self);

                common.makeToast(
                  self,
                  self.$i18n.t("Success"),
                  self.$i18n.t("SuccessAdding"),
                  self.brand + "-customSuccess",
                  "b-toaster-bottom-center",
                  true
                );

                self.isCustomCeil = false;
                self.customCeil = null;
                self.isValidation = true;
              })
              .catch(function (error) {
                self.isLoading = false;
                common.makeToast(
                  self,
                  self.$i18n.t("Error"),
                  self.$i18n.t("FailedAdding"),
                  self.brand + "-customSuccess",
                  "b-toaster-bottom-center",
                  true
                );
              });
          }
        }
      },
      modifyRow() {
        let self = this;

        modif(self);

        function modif(self) {
          let supportCodeArray = [];
          let modelCodeArray = [];
          let model = [];
          let support = [];
          let operationName;
          let operationCode;
          let isCrea = false;

          for (let i = 0; i < self.supportUsedSelected.length; i++) {
            supportCodeArray.push(self.supportUsedSelected[i].media_support_code);
            support.push(self.supportUsedSelected[i].name);
          }

          for (let j = 0; j < self.modelSelected.length; j++) {
            modelCodeArray.push(self.modelSelected[j].model_code);
            model.push(self.modelSelected[j].name);
          }

          operationName = self.addOperationSelect.label;
          operationCode = self.addOperationSelect.code;
          isCrea = self.addOperationSelect.isCrea;

          let object = {
            plan_code: self.planCode,
            operation_name: operationName,
            support: supportCodeArray,
            model: modelCodeArray,
            old_rate: parseInt(self.oldRate, 10),
            isCrea: isCrea,
            isCreationMode: false,
            status: 1,
            trade_code: self.currentTradeCode,
            contribKey: self.contribKey,
            turnoverIndicator: false,
            method: 'update',
          };

          // Modify comment if not empty
          if (self.commentInputModify != "") {
            object["comment"] = self.commentInputModify;
          }

          if (self.selectedRateModify == "percentage") {
            object["rate"] = parseInt(self.rateInput, 10);
            object["customCeil"] = self.customCeil;
          } else if (self.selectedRateModify == "amount") {
            if (self.isCustomCeil === true) {
              object["customCeil"] = parseInt(self.rateInput, 10);
            } else {
              object["budgetAmount"] = parseInt(self.rateInput, 10);
            }
          } else { //turnoverIndicator
            object['turnoverIndicator'] = true;
            if (self.isCustomCeil === true) {
              object["customCeil"] = {
                "amount": parseInt(self.rateInputAmount*100, 10) || 0,
                "rateLimit": parseInt(self.rateInputPercentage, 10) || null
              };
            } else {
              object['turnoverContribution'] = {
                "amount": parseInt(self.rateInputAmount*100, 10) || 0,
                "rateLimit": parseInt(self.rateInputPercentage, 10) || null
              };
            }
          }

          object["operation_code"] = self.operationCode;
          object["crea_code"] = operationCode;

          if (
            object["rate"] < parseInt(self.oldRate, 10) &&
            self.isCreation == 1
          ) {
            common.makeToast(
              self,
              self.$i18n.t("warning"),
              self.$i18n.t("percentageWarning"),
              self.brand + "-customWarning",
              "b-toaster-bottom-center",
              true
            );
          } else {
            self.isLoading = true;
            http
              .post(
                self.$store.state.apiUrl + "/rd/operation",
                object,
                self.$cookie.get("auth"),
                self.$cookie.get("authExpires")
              )
              .then(function (res) {
                if (res[1]) {
                  common.refreshToken(
                    self,
                    res[1].data.token,
                    res[1].data.validity
                  );
                }
                self.$refs["modifyOperationModal"].hide();
                self.isLoading = false;

                self.periodSelectedFunction(self);

                common.makeToast(
                  self,
                  self.$i18n.t("Success"),
                  self.$i18n.t("ModificationSuccess"),
                  self.brand + "-customSuccess",
                  "b-toaster-bottom-center",
                  true
                );
                self.isCustomCeil = false;
                self.customCeil = null;
              })
              .catch(function () {
                self.isLoading = false;
                common.makeToast(
                  self,
                  self.$i18n.t("Error"),
                  self.$i18n.t("ModificationFailed"),
                  self.brand + "-customSuccess",
                  "b-toaster-bottom-center",
                  true
                );
              });
          }
        }
      },
      passDeleteRow(item, index) {
        this.tableItem = item;
        this.tableIndex = index;
        this.oldRate = item.rate;
        this.contribKey = item.contribKey;
      },
      passModifyRow(item, index, tradeCode) {
        this.supportUsedSelected = item.supports;
        this.modelSelected = item.models;

        this.currentTradeCode = tradeCode;
        this.setOperation(tradeCode);
        this.tableItem = item;
        this.tableIndex = index;
        this.addOperationSelect = [{
          label: item.operation_name,
          code: item.operation_code,
          comment: item.comment,
        }, ];
        if (item.comment) {
          this.commentInputModify = item.comment;
        }

        if (item.customCeil) {
          this.isCustomCeil = true;
        } else {
          this.isCustomCeil = false;
        }

        if (item.rate) {
          this.rateInput = item.rate;
          this.selectedRateModify = "percentage";
          if (item.customCeil) {
            this.customCeil = item.customCeil;
          }
        } else if(item.budgetAmount) {
          this.rateInput = item.budgetAmount;
          this.selectedRateModify = "amount";
          if (item.customCeil) {
            this.rateInput = item.customCeil;
          }
        } else if(item.turnoverContribution){
          this.rateInputAmount = (item.turnoverContribution.amount)/100;
          this.rateInputPercentage = item.turnoverContribution.rateLimit;
          this.selectedRateModify = "turnoverIndicator";
          if (item.customCeil) {
            if(item.customCeil.amount){
              this.rateInputAmount = (item.customCeil.amount)/100;
              this.rateInputPercentage = item.customCeil.rateLimit;
              this.selectedRateModify = "turnoverIndicator";
            }
            else{
              this.rateInput = item.customCeil;
            }
          }
        }
        this.oldRate = item.rate;
        this.contribKey = item.contribKey;
        this.operationCode = item.operation_code;
        this.$refs["modifyOperationModal"].show();
      },
      deleteRow() {
        let self = this;

        self.isLoading = true;
        http
          .delete(
            this.$store.state.apiUrl +
            "/rd/operation/" +
            self.tableItem.operation_code +
            "?contribKey=" +
            self.contribKey,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then(function (res) {
            if (res[1]) {
              common.refreshToken(self, res[1].data.token, res[1].data.validity);
            }

            self.isLoading = false;
            self.$refs["deleteOperationModal"].hide();
            self.periodSelectedFunction(self);
            common.makeToast(
              self,
              self.$i18n.t("Success"),
              self.$i18n.t("DeleteDone"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
          })
          .catch(function () {
            self.isLoading = false;
            common.makeToast(
              self,
              self.$i18n.t("Error"),
              self.$i18n.t("DeleteFailed"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
          });
      },
      addAll() {
        let self = this;

        self.isLoading = true;
        // Send all
        http
          .post(
            this.$store.state.apiUrl + "/rd/activatePlan/" + self.planCode,
            null,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then(function (res) {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }
            self.isLoading = false;
            common.makeToast(
              self,
              self.$i18n.t("Success"),
              self.$i18n.t("SuccessfulSending"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
            self.$refs["validateOperationModal"].hide();
            setTimeout(
              function () {
                self.$router.push({
                  name: "rdDashboard",
                });
              }.bind(this),
              1500
            );
          })
          .catch(function () {
            self.isLoading = false;
            common.makeToast(
              self,
              self.$i18n.t("Error"),
              self.$i18n.t("FailedSending"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
          });
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // Import custom SASS variable overrides
  @import "../assets/custom-vars.scss";

    /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  .placeholder_percent {
    position: absolute;
    left: 40%;
    top: 16px;
    pointer-events: none;
    opacity: .5;
  }

  .placeholder_amount {
    position: absolute;
    right: 7%;
    top: 16px;
    pointer-events: none;
    opacity: .5;
  }

  .input:placeholder-shown .placeholder_percent {
    /* if real placeholder is shown - hide fake placeholder */
    opacity: 0;
  }

  .period {
    max-width: 504px;
    margin-bottom: 30px;
  }

  .alignSwitch {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
  }

  .ml5 {
    margin-left: 5px !important;
  }

  .mr5 {
    margin-right: 5px !important;
  }

  .inlineCenter {
    display: inline-flex;
    align-items: center;
  }

  .titleDetails {
    display: inline-flex;
    align-items: center;
    margin: 0;
    margin-left: 15px;
  }

  .inlineRadio {
    margin-right: 15px;
    display: inline-block;
  }

  .iBlock {
    display: inline-block;
  }

  .mt10 {
    margin-top: 10px;
  }

  #file {
    display: none;
  }

  .limitDiv {
    background: rgb(242, 242, 244);
    width: fit-content;
    padding: 5px;
    border-radius: 6px;
    margin-top: 15px;
  }
</style>