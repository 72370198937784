<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    v-model="isDisplayed"
    :title="title"
  >
    <b>{{ $t("TradeToSelect") }}</b>
    <v-select
      label="text"
      :reduce="(trade) => trade.value"
      v-model="trade"
      :options="trades"
      placeholder="Choisir un métier"
      :class="{ pleaseFill: this.trade === null && isValidation == false }"
      :disabled="trades.length === 0"
    ></v-select>
    <template v-slot:modal-footer>
      <div class="w-100 float-right">
        <b-button
          pill
          :variant="'customPrimary' | variantBrand(self)"
          class="float-right ml-1"
          @click="clickAdd()"
        >
          {{ $t("Add") }}</b-button
        >
        <b-button
          pill
          variant="secondary"
          class="float-right ml-1"
          @click="isDisplayed = false"
          >{{ $t("Cancel") }}
        </b-button>
      </div>
    </template>
    <hr />
    <b v-if="isOperation === false">{{ $t("SupportToSelect") }}</b>
    <v-select
      v-if="isOperation === false"
      label="text"
      :reduce="(media) => media.value"
      v-model="media"
      :options="limitedMedias"
      placeholder="Choisir un support"
      :disabled="medias.length === 0"
      :class="{
        pleaseFill:
          (media === null || media.length <= 0) && isValidation == false,
      }"
    >
    </v-select>
    <b>{{ $t("OperationToSelect") }}</b>

    <b-form-input
      :placeholder="$t('OperationName')"
      v-model="operationName"
      :class="{ pleaseFill: operationName === '' && isValidation == false }"
    />
  </b-modal>
</template>
<script>
import common from "../../helpers/common";

export default {
  props: {
    display: {
      type: Boolean,
      default: true,
    },
    supportList: {
      type: Array,
      default: () => [],
    },
    medias: {
      type: Array,
      default: () => ["x", "y"],
    },
    supportOrOperation: {
      type: String,
      default: null,
    },
    trades: {
      type: Array,
      default: () => ["j", "j2"],
    },

    defaultMediaCode: {
      type: String,
      default: null,
    },
    defaultTradeCode: {
      type: String,
      default: null,
    },
    defaultOperation: {
      type: String,
      default: "",
    },

    handleValidate: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    defaultTradeCode() {
      this.trade = this.defaultTradeCode;
    },
    defaultOperation() {
      this.operationName = this.defaultOperation;
    },
    defaultMediaCode() {
      this.media = this.defaultMediaCode;
    },
  },

  methods: {
    clickAdd() {
      const data = {};
      this.errors = [];

      if (this.isOperation === true) {
        if (!this.operationName || this.operationName.trim() === "") {
          this.errors.push(this.$t("ErrorHaveToPickOperation"));
          this.isValidation = false;
          common.makeToast(
            this,
            "Attention",
            this.$t("ChooseSupportorOperation"),
            this.brand + "-customWarning",
            "b-toaster-bottom-center",
            true
          );
          return false;
        }

        this.isValidation = true;
        data.operation = this.operationName;
      } else {
        if (
          (!this.operationName || this.operationName.trim() === "") &&
          !this.media
        ) {
          this.errors.push(this.$t("ErrorHaveToPickOperation"));
          this.errors.push(this.$t("ErrorHaveToPickSupport"));
          this.isValidation = false;
          common.makeToast(
            this,
            "Attention",
            this.$t("ChooseSupportorOperation"),
            this.brand + "-customWarning",
            "b-toaster-bottom-center",
            true
          );
          return false;
        }
        this.isValidation = true;
        if (this.media && this.media !== null) {
          data.media_support_code = this.media;
        }
        if (this.operationName && this.operationName !== null) {
          data.operation = this.operationName;
        }
        data.operation = this.operationName;
      }

      if (this.trade === null) {
        this.errors.push(this.$t("ErrorHaveToPickTrade"));
      } else {
        data.trade_code = this.trade;
      }
      if (this.errors.length !== 0) {
        return false;
      }
      this.isDisplayed = false;
      this.handleValidate(data);
      return true;
    },
  },
  data() {
    return {
      brand: window.location.pathname.split("/")[1],
      isValidation: true,
      title: this.$t("AddOperation"),
      trade: this.defaultTradeCode,
      media: this.defaultMediaCode,
      errors: [],
      operationName: this.defaultOperation,
    };
  },
  computed: {
    limitedMedias: {
      get() {
        if (this.supportList.length === 0) {
          return this.medias;
        }

        return this.supportList.map((supportMediaId) => {
          return this.medias.find((el) => el.value === supportMediaId);
        });
      },
    },
    isOperation: {
      get() {
        return this.supportOrOperation === "operation";
      },
    },

    self: {
      get: function () {
        return this;
      },
    },
    isDisplayed: {
      get: function () {
        return this.display;
      },
      set: function (display) {
        this.$emit("update:display", display); // for sync
      },
    },
  },
};
</script>

<style>
</style>