<template>
  <div id="app-root" :class="theme">
    <div id="app">
      <div>
        <div
          class="text-center d-flex h-100 justify-content-center align-items-center spinnerBackground"
          v-bind:class="{ inactiveSpinner: $store.state.isSpinnerInactive }"
        >
          <b-spinner
            :variant="customPrimaryVariant"
            key="customPrimary"
          ></b-spinner>
        </div>
        <b-navbar
          v-if="['login', 'logout', '404'].indexOf($route.name) === -1"
          id="mainNav"
          toggleable="lg"
          type="dark"
          :variant="customPrimaryVariant"
        >
          <img :src="logo" style="max-width: 150px" />
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="w-100 flex i-center">
              <b-button
                v-if="isDR == 'true'"
                pill
                class="custom-pos-activity fw500 fs18"
                @click="drInterface()"
              >
                {{ $t("rrmpAccess") }}
                <i class="fas fa-chevron-right ml5"></i>
              </b-button>
              <div v-if="isDR == 'true'" class="separator"></div>
              <b-button
                pill
                class="customLogout fw500 fs18"
                :to="{ name: 'logout' }"
              >
                <i class="fas fa-sign-out-alt mr5"></i>
              </b-button>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
require("./assets/fontawesome/css/all.min.css");

export default {
  data() {
    return {
      logo: "/img/" + window.location.pathname.split("/")[1] + "/logo.png",
      isDR: false,
      theme: "theme-" + window.location.pathname.split("/")[1],
      customPrimaryVariant:
        window.location.pathname.split("/")[1] + "-customPrimary",
    };
  },
  updated() {
    this.isDR = this.$cookie.get("isDR");
  },
  methods: {
    drInterface() {
      let self = this;

      window.open(
        process.env.VUE_APP_RD_INTERFACE.concat(
          "/",
          window.location.pathname.split("/")[1],
          "?auth=",
          self.$cookie.get("auth"),
          "&authExpires=",
          self.$cookie.get("authExpires"),
          "&authExpiresDate=",
          self.$cookie.get("authExpiresDate")
        )
      );
    },
  },
};
</script>

<style lang="scss">
// Import custom SASS variable overrides
@import "assets/custom-vars.scss";
// Import Bootstrap and BootstrapVue source SCSS files
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
// Import SCSS select2
@import "vue-select/src/scss/vue-select.scss";

html,
body {
  font-family: "Avenir", sans-serif !important;
  background: #fcfcfc;
}

.pleaseFill {
  border: 1px solid red !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #80808012 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff !important;
}

.red {
  color: red !important;
}

.green {
  color: #84c294 !important;
}

.vs__dropdown-option--disabled {
  background: #eeeeee !important;
  color: #333 !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.flex {
  display: flex;
}

.i-center {
  align-items: center;
}

.separator {
  border-left: 1px solid white;
  height: 28px;
  position: absolute;
  right: 76px;
}

.custom-file-label {
  overflow-x: hidden;
}

.mr5 {
  margin-right: 5px;
}

.roundDiv {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #df3636;
  border-radius: 500px;
}

.examinateColor {
  color: #df3636;
}

.mr15 {
  margin-right: 15px;
}

.bold {
  font-weight: bold;
}

.i-block {
  display: inline-block;
}

.fw-500 {
  font-weight: 500 !important;
}

.mb75 {
  margin-bottom: 100px !important;
}
</style>