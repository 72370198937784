/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

function filterByMedia(data, filter) {
  return data.filter((el) => {
    return el.type === filter;
  });
}

export default {
  getMedias(state) {
    const medias = state.data.map((el) => ({
      value: el.media_support_code,
      text: el.name,
    }));

    return medias || [];
  },
  getMediaById: (state) => (code) => {
    return state.data.find((media) => media.media_support_code === code);
  },

  getAllMedia(state) {
    return state.data || [];
  },
  getOnlineMedia(state) {
    return filterByMedia(state.data, "online");
  },
  getOfflineMedia(state) {
    return filterByMedia(state.data, "offline");
  },
  getMarketinglineMedia(state) {
    return filterByMedia(state.data, "maketing");
  },
};
