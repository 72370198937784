import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import BootstrapVue from "bootstrap-vue";
import vSelect from "vue-select";
import routes from "./routes";
import VueBootstrap4Table from "vue-bootstrap4-table";
import PortalVue from "portal-vue";
import Vuex from "vuex";
import i18n from "./i18n";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
const moment = require("moment");
import VueLodash from "vue-lodash";
import lodash from "lodash";
import "./filters/global";
import "@/assets/customfont/style.css";
import store from "./store";

import utilsMixin from "@/mixins/utils";
import {
  convertInEuro
} from "@/filters/money.js";
import {
  variantBrand
} from "@/filters/brand.js";
import {
  dateGenericFormat
} from "@/filters/dates.js";
import {
  supportOrOperation
} from "@/filters/supportOrOperation.js";
let VueCookie = require("vue-cookie");
import VueMask from 'v-mask';

Vue.use(VueMask);
Vue.use(Donut);
Vue.use(VueCookie);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.component("v-select", vSelect);
Vue.component("VueBootstrap4Table", VueBootstrap4Table);
Vue.use(PortalVue);
Vue.use(Vuex);
Vue.mixin(utilsMixin);

// name is optional
Vue.use(VueLodash, {
  name: "custom",
  lodash: lodash
});

// Load Locales ('en' comes loaded by default)
require("moment/locale/fr");

// Choose Locale
moment.locale("fr");
Vue.use(require("vue-moment"), {
  moment,
});

Vue.config.productionTip = false;

const router = new VueRouter({
  mode: "history",
  routes,
});

const brands = [{
  brand: "citroen",
},
{
  brand: "ds",
},
{
  brand: "peugeot",
},
{
  brand: "mercedes-cars",
},
{
  brand: "mercedes-vans",
},
{
  brand: "lmp",
}
];

/** Vue Filters Start */
Vue.filter("truncate", function (text, lengthText, suffix) {
  if (text && text.length > lengthText) {
    return text.substring(0, lengthText) + suffix;
  } else {
    return text;
  }
});

Vue.filter("convertInEuro", convertInEuro);
/** Vue Filters End */
Vue.filter("variantBrand", variantBrand);
Vue.filter("dateGenericFormat", dateGenericFormat);
Vue.filter("supportOrOperation", supportOrOperation);

const myVue = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.app = myVue;


router.beforeEach((to, from, next) => {
  let found = false;
  if (to.path == "/404") {
    next();
  } else {
    for (let i = 0; i < brands.length; i++) {

      if (to.params.brand == brands[i].brand) {
        found = true;
        next();
        return;
      }
    }

    if (found == false) {
      next("/404");
    }
  }
});
