import http from "../../../helpers/http";

export default {
  state: () => ({
    preenvent: [],
  }),
  actions: {
    async getPrevent({ commit, getters }, { $cookie }) {
      const preenvents = await http.get(
        process.env.VUE_APP_APIURL + "/global/preevent",
        $cookie.get("auth"),
        $cookie.get("authExpires")
      );
      const allMedias = getters["Medias/getAllMedia"];

      // must be in the getter ?

      for (let index = 0; index < preenvents[0].data.length; index++) {
        preenvents[0].data[index].supportList = preenvents[0].data[
          index
        ].supportList.map((element) => {
          return allMedias.find(
            (media) => media.media_support_code === element
          );
        });
      }

      commit("setPrevents", preenvents[0].data);
    },
  },
  mutations: {
    setPrevents(state, preenvents) {
      state.preenvent = preenvents;
    },
  },

  getters: {
    // getMediaById
    getListOfSupport: (state) => (code) => {
      const support = state.preenvent.find(
        (preenvent) => preenvent.code === code
      );
      if (support && support.supportList && support.supportList.length > 0) {
        return support.supportList.filter(Boolean).map((el) => {
          return {
            value: el.media_support_code,
            text: el.name,
          }
        });
      }
      return [];
    },
  },
};
