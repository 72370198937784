<template>
  <b-button pill @click="prev" :variant="'customPrimary' | variantBrand(this)">
    <i class="mr-2 fas fa-chevron-left"></i>
    {{$t('GoBack')}}
  </b-button>
</template>

<script>
export default {
  props: {
    prevRoute: {
      type: Function
    }
  },
  methods: {
    prev() {
      if (this.prevRoute) {
        this.$router.push(this.prevRoute);
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>
