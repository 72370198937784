const axios = require("axios");
const common = require("./common");
import Vue from 'vue';

function refreshToken() {
    const tokenExpires = Vue.cookie.get("authExpires");
    const tokenExpiresDate = Number(Vue.cookie.get("authExpiresDate"));
    const halfValidity = (tokenExpires / 2) * 1000;
    const now = Date.now();
    const halfTime = tokenExpiresDate - halfValidity;

    if (now >= halfTime) {
        return true;
    } else if (now >= tokenExpiresDate) {
        return "logout";
    } else {
        return false;
    }
}

function getAuthHeaders(token) {
    const authHeaders = {
        Authorization: "Bearer " + token,
        brand: window.location.pathname.split("/")[1]
    };

    return authHeaders;
}

export default {
    // Pass conf to get
    get: async function (url, token = null, tokenExpires = null) {
        let config;
        let authHeaders = getAuthHeaders(token);

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.get(url, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.get(url, config)];
            }

            return response;
        } catch (error) {
            return error;
        }
    },

    postExcel: async function (url, data, token = null, tokenExpires = null) {
        let config;
        const authHeaders = getAuthHeaders(token);
        authHeaders["Accept"] =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: "127.0.0.1",
                    port: process.env.VUE_APP_PROXY
                }
            };
        } else {
            config = {
                headers: authHeaders
            };
        }

        config["responseType"] = "arraybuffer";

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.get(url, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(
                    response[1].data.token,
                    response[1].data.validity
                );
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.post(url, data, config)];
            }

            return response;
        } catch (error) {
            return error;
        }
    },
    // Pass conf to post
    postPDF: async function (url, data, token = null, tokenExpires = null) {
        let config;
        let authHeaders = getAuthHeaders(token);
        authHeaders["Accept"] = 'application/pdf';

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        config["responseType"] = 'arraybuffer';

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.post(url, data, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.post(url, data, config)];
            }

            return response;
        } catch (error) {
            return error;
        }
    },
    // this is only to send file to the server !! dont use it for fetch a document
    postDocument: async function (url, data, token = null, tokenExpires = null) {
        let config;
        let authHeaders = getAuthHeaders(token);
        authHeaders["Content-Type"] = 'multipart/form-data';

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        config["responseType"] = 'arraybuffer';

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.post(url, data, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.post(url, data, config)];
            }

            return response;
        } catch (error) {
            return error;
        }
    },
    // Pass conf to get
    getPDF: async function (url, token = null, tokenExpires = null) {
        let config;
        let authHeaders = getAuthHeaders(token);
        authHeaders["Accept"] = 'application/pdf';

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        config["responseType"] = 'arraybuffer';

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.get(url, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.get(url, config)];
            }

            return response;
        } catch (error) {
            return error;
        }
    },
    getDocument: async function (url, token = null, tokenExpires = null) {
        let config;
        let authHeaders = getAuthHeaders(token);

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        config["responseType"] = 'blob';

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.get(url, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.get(url, config)];
            }

            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getExcel: async function (url, token = null, tokenExpires = null) {
        let config;
        let authHeaders = getAuthHeaders(token);
        authHeaders["Accept"] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        config["responseType"] = 'arraybuffer';

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.get(url, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.get(url, config)];
            }

            return response;
        } catch (error) {
            return error;
        }
    },
    postLogin: async function (url, data, token = null, tokenExpires = null) {
        let config;

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        }

        try {
            let response = await axios.post(url, data, config);

            return response;
        } catch (error) {
            return error;
        }
    },
    postFile: async function (url, data, token = null, tokenExpires = null) {

        let config;
        let authHeaders = getAuthHeaders(token);

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        config.headers["Content-Type"] = 'multipart/form-data';

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.post(url, data, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.post(url, data, config)];
            }

            return response;
        } catch (error) {
            return error;
        }
    },
    // Pass conf to post
    post: async function (url, data, token = null, tokenExpires = null) {
        let config;
        let authHeaders = getAuthHeaders(token);

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.post(url, data, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.post(url, data, config)];
            }
            return response;
        } catch (error) {
            return error;
        }
    },
    // Pass conf to patch
    patch: async function (url, data, token = null, tokenExpires = null) {
        let config;
        let authHeaders = getAuthHeaders(token);

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.patch(url, data, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.patch(url, data, config)];
            }

            return response;
        } catch (error) {
            return error;
        }
    },
    // Pass conf to patch
    delete: async function (url, token = null, tokenExpires = null) {
        let config;
        let authHeaders = getAuthHeaders(token);

        if (process.env.VUE_APP_NODE_ENV == "localDevelopment") {
            config = {
                headers: authHeaders,
                proxy: {
                    host: '127.0.0.1',
                    port: process.env.VUE_APP_PROXY
                }
            }
        } else {
            config = {
                headers: authHeaders
            }
        }

        try {
            let isRefresh = false;
            let response;

            if (tokenExpires) {
                isRefresh = refreshToken(tokenExpires);
            }

            if (isRefresh == true) {
                response = await Promise.all([
                    axios.delete(url, config),
                    axios.get(process.env.VUE_APP_AUTH_URL + "/auth/refreshToken", config)
                ]);
                common.default.refreshToken(response[1].data.token, response[1].data.validity);
            } else if (isRefresh === "logout") {
                common.default.logout();
            } else {
                response = [await axios.delete(url, config)];
            }

            return response;
        } catch (error) {
            return error;
        }
    }
}