<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    v-model="isDisplayed"
    :title="title"
  >
    {{ text }}
    <template v-slot:modal-footer>
      <div class="w-100 float-right">
        <b-button
          pill
          :variant="'customPrimary' | variantBrand(self)"
          class="float-right ml-1"
          @click="clickValidate()"
          >{{ accept }}</b-button
        >
        <b-button
          pill
          variant="secondary"
          class="float-right ml-1"
          @click="isDisplayed = false"
          >{{ $t("Cancel") }}</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    handleValidate: {
      type: Function,
    },
    title: {
      type: String,
      default: "Hello World",
    },
    text: {
      type: String,
      default: "Hello World",
    },
    accept: {
      type: String,
      default: "Hello World",
    },
    display: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clickValidate() {
      this.handleValidate();
      this.isDisplayed = false;
    },
  },
  computed: {
    self() {
      return this;
    },
    isDisplayed: {
      get: function () {
        return this.display;
      },
      set: function (display) {
        this.$emit("update:display", display); // for sync
      },
    },
  },
};
</script>
