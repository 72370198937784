<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    v-model="isDisplayed"
    :title="$t('warning')"
  >
    <div>
      <b>{{ $t("askDeleteOperation") }}</b>
    </div>
    <template v-slot:modal-footer>
      <div class="w-100 float-right d-flex justify-content-center">
        <b-button
          pill
          :variant="'customPrimary' | variantBrand(self)"
          @click="
            isDisplayed = false;
            handleValidate();
          "
          class="ml-1"
          >{{ $t("Delete") }}</b-button
        >
        <b-button
          variant="secondary"
          pill
          class="ml-1"
          @click="isDisplayed = false"
          >{{ $t("Cancel") }}</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: true,
    },
    handleValidate: {
      type: Function,
    },
  },

  computed: {
    self: {
      get: function () {
        return this;
      },
    },
    isDisplayed: {
      get: function () {
        return this.display;
      },
      set: function (display) {
        this.$emit("update:display", display); // for sync
      },
    },
  },
};
</script>


<style>
</style>