<template>
  <div class="mb30">
    <h5 class="mt30 mb15 text-left">
      <i class="fas fa-list mr5"></i>
      {{ scope.label }}
    </h5>

    <b-table responsive bordered striped class="m0" :fields="fields" :items="items">

      <template v-slot:cell(operation_name)="row">
        {{ row.item.operation_name }}
        <i v-if="(row.item.customCeil) || (row.item.turnoverContribution && row.item.customCeil)" class="fas fa-tag ml5 primaryColor cursor-pointer"
          v-b-popover.hover.right="row.item.contribLabel" :title="$t('customCeil')"></i>
      </template>

      <template v-slot:cell(actions)="row">
        <b-dropdown dropleft text="Drop-Left" variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <i class="fas fa-ellipsis-v primaryColor fs18"></i>
          </template>
          <b-dropdown-item @click="passModifyRow(row.item, row.index, scope.trade_code)" v-bind:disabled="disabled">
            <i class="fas fa-edit mr5"></i>
            {{ $t("Edit") }}
          </b-dropdown-item>

          <b-dropdown-item @click="passDeleteRow(row.item, row.index)" v-bind:disabled="disabled"
            v-b-modal.deleteOperation>
            <i class="fas fa-trash mr5"></i>
            {{ $t("Delete") }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-btn pill :variant="customPrimaryVariant" class="mt15" v-bind:disabled="disabled"
      @click="showModalOperation('addOperationModal', scope.trade_code)">
      <i class="fas fa-plus-circle mr5" />
      {{ $t("AddOperation") }}
    </b-btn>
  </div>
</template>

<script>
  export default {
    props: {
      showModalOperation: {
        type: Function,
      },
      customPrimaryVariant: {
        type: String,
      },
      disabled: {
        type: Boolean,
        default: true,
      },
      passModifyRow: {
        type: Function,
      },
      passDeleteRow: {
        type: Function,
      },

      scope: {
        type: Object,
        default: () => ({
          label: "unknown",
        }),
      },
      items: {
        type: Array,
        default: () => [],
      },
      fields: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>