import http from "../../../helpers/http";

export default {
  state: () => ({
    myPlan: [],
    plans: [],
    loading: false,
    selectedPlan: null,
    selectedPlanFull: null,
  }),
  actions: {
    addOperation({ state, commit }, { $cookie, myData }) {
      state.loading = true;
      let url = http
        .post(
          process.env.VUE_APP_APIURL + "/prerd/operation",
          myData,
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
        .then((res) => {
          commit("addOperation", res[0].data.presupport[0]);
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    },
    removeOperation({ state, commit }, { $cookie, codePreSupport }) {
      state.loading = true;
      let url = http
        .delete(
          process.env.VUE_APP_APIURL + "/prerd/operation/" + codePreSupport,
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
        .then((res) => {
          commit("removeOperation", codePreSupport);
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    },
    updateOperation({ state, commit }, { $cookie, myData }) {
      state.loading = true;

      let url = http
        .patch(
          process.env.VUE_APP_APIURL + "/prerd/operation",
          myData,
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
        .then((res) => {
          commit("updateOperation", res[0].data.presupport[0]);
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    },
    getMyPlan({ state, commit }, { preplanid, $cookie }) {
      if (preplanid && preplanid !== null) {
        state.loading = true;
        http
          .get(
            process.env.VUE_APP_APIURL + "/global/mypreplan/" + preplanid,
            $cookie.get("auth"),
            $cookie.get("authExpires")
          )
          .then((plan) => {
            state.selectedPlan = preplanid;
            commit("setMyPlan", plan);
          })
          .catch(() => {})
          .finally(() => {
            state.loading = false;
          });
      } else {
        commit("reset");
      }
    },
    getAllPlan({ state, commit, dispatch }, $cookie) {
      state.loading = true;
      http
        .get(
          process.env.VUE_APP_APIURL + "/prerd/preplan",
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
        .then((plans) => {
          dispatch("getMyPlan", {
            preplanid: plans[0].data.preplan[0].code,
            $cookie,
          });
          commit("setPlans", plans);
        })
        .catch(() => {
          state.loading = false;
        });
    },
  },
  mutations: {
    setPlans(state, plans) {
      state.plans = plans[0].data.preplan;
    },
    reset(state) {
      state.myPlan = [];
      state.selectedPlan = null;
    },
    setMyPlan(state, myPlan) {
      state.myPlan = myPlan[0].data;
    },
    updateOperation(state, presupport) {
      for (const key in state.myPlan) {
        if (state.myPlan.hasOwnProperty(key)) {
          const arrayOfPreSupport = state.myPlan[key];

          const presupportIndex = arrayOfPreSupport.data.findIndex((el) => {
            return el.code === presupport.code;
          });

          if (presupportIndex !== -1) {
            arrayOfPreSupport.data.splice(presupportIndex, 1, presupport);
          }
        }
      }
    },
    removeOperation(state, deleteCode) {
      for (const key in state.myPlan) {
        if (state.myPlan.hasOwnProperty(key)) {
          const arrayOfPreSupport = state.myPlan[key];

          const presupportIndex = arrayOfPreSupport.data.findIndex((el) => {
            return el.code === deleteCode;
          });

          if (presupportIndex !== -1) {
            arrayOfPreSupport.data.splice(presupportIndex, 1);
          }
        }
      }
    },
    addOperation(state, newPreSupport) {
      const focusPlan = state.myPlan.find((el) => {
        return el.code === newPreSupport.pre_event_code;
      });

      focusPlan.data = [...focusPlan.data, newPreSupport];
    },
  },

  getters: {
    selectedPlanFull(state) {
      return (
        state.plans.find((el) => el.code === state.selectedPlan) || {
          status: -1,
        }
      );
    },
    getLoadingMyMarketingPlan(state) {
      return state.loading;
    },
    getPreplanSelectedCode(state) {
      return state.selectedPlan;
    },
    getPrePlanAll(state) {
      return state.plans;
    },
    getPrePlan(state) {
      const plans = state.plans.map((el) => ({
        value: el.code,
        text: el.label,
        status: el.status,
      }));
      return plans;
    },
    getMyMarketingPlan(state) {
      return state.myPlan;
    },
  },
};
