
import http from "../../../helpers/http";

export default {
    updatePlandone (context, {$cookie, plandoneCode, data}) {
      console.log(plandoneCode);
      console.log(process.env.VUE_APP_APIURL +"/global/plansdone/" + plandoneCode);
        return http.patch(
          process.env.VUE_APP_APIURL +"/global/plansdone/" + plandoneCode + "/",
          data,
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
    },
};
