<template>
  <div class="container pad30 maxW700 row align-items-center m-auto">
    <div class="col-sm-12">
      <h1 class="mb30 text-center">{{ $t("404") }}</h1>
    </div>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // Import custom SASS variable overrides
  @import '../assets/custom-vars.scss';

  .maxW700 {
    height: calc(100vh - 53px);
  }

  .navbar {
    display: none!important;
  }
</style>