import RdCreate from "./components/RdCreate.vue";
import RdDashboard from "./components/RdDashboard.vue";
import RdDetails from "./components/RdDetails.vue";
import PosDashboard from "./components/PosDashboard.vue";
import PosDetails from "./components/PosDetails.vue";
import Login from "./components/Login.vue";
import Logout from "./components/Logout.vue";
import NotFound from "./components/NotFound.vue";
import Auth from "./components/Auth.vue";
import ReportsMenu from "./components/Report/Menu.vue";
import MenuRapportPrePlan from "./components/Report/PreplanCustomRepport/MenuRapportPrePlan";
import ReportsCustom from "./components/Report/CustomReport";
import ReportsCustomForPlan from "./components/Report/CustomReportForPlan";
import MarketingPlan from "./components/MarketingPlan.vue";
import CreationMarketingPlan from "./components/CreationMarketingPlan.vue";
import RdPlanMarketingDashboard from "./components/RdPlanMarketingDashboard.vue";
const routes = [
  {
    path: "/:brand/pos/preplandone/:preplanid",
    name: "scheduleForPos",
    component: MarketingPlan,
  },

  {
    path: "/:brand/pos/dashboard/marketingplan",
    name: "MarketingPlanDashboardTopos",
    component: RdPlanMarketingDashboard,
  },

  {
    path: "/:brand/rd/preplandone/:preplanid",
    name: "scheduleForRd",
    component: MarketingPlan,
  },

  {
    path: "/:brand/rd/dashboard/marketingplan",
    name: "MarketingPlanDashboardTord",
    component: RdPlanMarketingDashboard,
  },

  {
    path: "/:brand/rd/createmarketingplan",
    name: "createMarketingPlan",
    component: CreationMarketingPlan,
  },
  {
    path: "/:brand/:type/reports/menu/forplan",
    name: "reportsMenuPlan",
    component: MenuRapportPrePlan,
  },
  {
    path: "/:brand/:type/reports/menu",
    name: "reportsMenu",
    component: ReportsMenu,
  },
  {
    path: "/:brand/:type/reports/custom",
    name: "reportsCustom",
    component: ReportsCustom,
  },
  {
    path: "/:brand/:type/reports/custom/forplan",
    name: "reportsCustomForPlan",
    component: ReportsCustomForPlan,
  },
  {
    path: "/:brand/rd/create",
    name: "rdCreate",
    component: RdCreate,
  },
  {
    path: "/:brand/rd/dashboard",
    name: "rdDashboard",
    component: RdDashboard,
  },
  {
    path: "/:brand/rd/details/:id",
    name: "rdDetails",
    component: RdDetails,
  },
  {
    path: "/:brand/pos/dashboard",
    name: "posDashboard",
    component: PosDashboard,
  },
  {
    path: "/:brand/pos/details/:id",
    name: "posDetails",
    component: PosDetails,
  },
  {
    path: "/:brand/",
    name: "login",
    component: Login,
  },
  {
    path: "/:brand/auth/:token",
    name: "auth",
    component: Auth,
  },
  {
    path: "/:brand/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
  },
  {
    path: "*",
    redirect: "404",
  },
];

export default routes;
