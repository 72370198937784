<template>
  <div class="row">
    <AreYouSure
      :display.sync="areYouSureToDisplayTheDialog"
      :handleValidate="validatePlan"
      :title="marketingTitle"
      :text="marketingText"
      :accept="acceptText"
    />
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      id="deleteOperationModal"
      centered
      :title="$t('deleteOperationModalTitle')"
      ref="deleteOperationModal"
    >
      <p>{{ $t("DeleteOperation") }}</p>

      <template v-slot:modal-footer>
        <div class="w-100 float-right">
          <b-button
            pill
            :variant="'customPrimary' | variantBrand(self)"
            class="float-right ml-1"
            @click="removePreSupport()"
            >{{ $t("Delete") }}</b-button
          >
          <b-button
            pill
            variant="secondary"
            class="float-right ml-1"
            @click="hideModal('deleteOperationModal')"
            >{{ $t("Cancel") }}</b-button
          >
        </div>
      </template>
    </b-modal>

    <div class="col-sm-12">
      <div class="pad30">
        <div class="mb30">
          <addOperation
            v-if="addModal"
            :display.sync="addModal"
            :trades="trades"
            :medias="medias"
            :supportList="supportList"
            :supportOrOperation="supportOrOperation"
            :defaultTradeCode="trade_code"
            :defaultMediaCode="media_code"
            :defaultOperation="operation"
            :handleValidate="handleValidate"
          />

          <BackBtn />
          <b class="pad30 h3">
            <b-icon
              icon="house-fill"
              scale="1.25"
              shift-v="1.25"
              aria-hidden="true"
            ></b-icon>
            {{ $t("MyMarketingPlans") }}
            >
            {{ $t("CreateMarketingPlans") }}
          </b>
          <br />
          <br />
          <div v-if="loading" class="text-center">
            <loading :active.sync="loading" :is-full-page="true"></loading>
          </div>
          <div class="actionDiv float-right">
            <b-button
              pill
              :variant="'customPrimary' | variantBrand(this)"
              class="fw500 float-right"
              @click="areYouSureToDisplayTheDialog = true"
              :disabled="selectedPreplan === null"
            >
              <i class="fas fa-check mr5"></i>

              <span v-if="selctedPrePlanFull.status === 0">{{
                $t("Validate")
              }}</span>
              <span v-if="selctedPrePlanFull.status === 1">{{
                $t("Modification")
              }}</span>
            </b-button>
            <b-button
              pill
              :variant="'customPrimary' | variantBrand(this)"
              class="fw500 float-right mr15"
              @click="downloadExcel"
              :disabled="selectedPreplan === null"
            >
              <i class="far fa-file-excel mr5"></i>
              {{ $t("DownloadFile") }}
            </b-button>
          </div>
          <div class="selectPlan">
            <label class="fw500">{{ $t("selectPlan") }}</label>
            <v-select
              label="text"
              :reduce="(preplan) => preplan.value"
              :options="preplanListFull"
              v-model="selectedPreplan"
              :placeholder="$t('selectPlan')"
            ></v-select>
          </div>
          <div v-if="selectedPreplan !== null">
            <div v-for="(scope, index) in scopes" :key="index" class="mb-3">
              <i class="mr-1 fas fa-list"></i>
              <b class="h5">{{ scope.label }}</b>

              <Br />
              <b-btn
                pill
                class="my-3"
                :disabled="
                  !(medias && medias.length > 0 && trades && trades.length > 0)
                "
                :variant="'customPrimary' | variantBrand(self)"
                @click="
                  displayPopUp(
                    scope.code,
                    scope.supportOrOperation,
                    scope.supportList
                  )
                "
              >
                <i class="mr-2 fas fa-plus-circle"></i>
                {{ $t("AddOperation") }}
              </b-btn>
              <b-table
                responsive
                striped
                hover
                :items="scope.data"
                :fields="fields"
                head-variant="light"
              >
                <template v-slot:cell(action)="data">
                  <!-- `data.value` is the value after formatted by the Formatter -->
                  <b-dropdown
                    dropleft
                    text="Drop-Left"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <i class="fas fa-ellipsis-v primaryColor fs18"></i>
                    </template>
                    <b-dropdown-item
                      @click="
                        updatePreSupport(
                          data,
                          scope.supportOrOperation,
                          scope.supportList
                        )
                      "
                    >
                      <i class="fas fa-edit mr5"></i>
                      {{ $t("update") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteModal(data)">
                      <i class="fas fa-trash mr5"></i>
                      {{ $t("Delete") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackBtn from "@/components/Btn/Back.vue";
import AddOperation from "@/components/Dialog/AddOperation.vue";
import Trade from "@/store/modules/Trade/trade";
import MyMarketingPlan from "@/store/modules/MarketingPlan/myMarketingPlan";
import AreYouSure from "@/components/Dialog/AreYouSure.vue";
import Loading from "vue-loading-overlay";
import http from "@/helpers/http";
import common from "../helpers/common";
import FileSaver from "file-saver";
import Vue from "vue";

export default {
  data() {
    return {
      marketingTitle: this.$i18n.t("ValidateMarketingPlan"),
      marketingText: this.$i18n.t("AreYouSureToValidateYourPlan"),
      acceptText: this.$i18n.t("Validate"),
      brand: window.location.pathname.split("/")[1],
      dataDelete: null,
      mode: "add",
      areYouSureToDisplayTheDialog: false,
      supportOrOperation: null,
      supportList: [],
      trade_code: null,
      media_code: null,
      operation: "XXX",
      addModal: false,
      selectedEvent: null,
      fields: [
        {
          key: "operation",
          label: "Nom de l'opération",
          formatter: (value) => {
            return value || "-";
          },
        },
        {
          key: "mediasupport.name",
          label: "Support utilisé",
          formatter: (value) => {
            return value || "-";
          },
        },
        {
          key: "trade.label",
          label: "Métier",
        },
        {
          key: "action",
          label: "Actions",
        },
      ],
    };
  },
  components: {
    BackBtn,
    AddOperation,
    Loading,
    AreYouSure,
  },
  beforeMount() {
    common.isAuth(this, http);
  },
  created: function () {
    const store = this.$store;
    store.registerModule("trade", Trade);
    store.registerModule("marketingPlan", MyMarketingPlan);
  },
  destroyed: function () {
    const store = this.$store;
    store.unregisterModule("trade");
    store.unregisterModule("marketingPlan");
  },
  mounted() {
    this.$store.dispatch("getTrade", this.$cookie);
    this.$store.dispatch("getAllPlan", this.$cookie);
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters.getLoadingMyMarketingPlan;
      },
    },
    trades: {
      get() {
        return this.$store.getters.getTrades || [];
      },
    },
    medias: {
      get() {
        return this.$store.getters["Medias/getMedias"] || [];
      },
    },
    preplanListFull() {
      // list v-select
      return this.$store.getters.getPrePlan.map((el) => {
        if (el.status === 1) {
          el.text = `[${this.$t("Modification")}] ${el.text}`;
        } else {
          el.text = `[${this.$t("Creation")}] ${el.text}`;
        }
        return el;
      });
    },
    scopes: {
      get() {
        return this.$store.getters.getMyMarketingPlan || [];
      },
    },
    selctedPrePlanFull() {
      const x = this.$store.getters.getPrePlanAll.find(
        (el) => el.code === this.selectedPreplan
      ) || { status: -1 };
      this.swapText(x);
      return x;
    },
    selectedPreplan: {
      get() {
        return this.$store.getters.getPreplanSelectedCode || null;
      },
      set(preplancode) {
        this.$store.dispatch("getMyPlan", {
          preplanid: preplancode,
          $cookie: this.$cookie,
        });
      },
    },
    self: {
      get: function () {
        return this;
      },
    },
  },
  methods: {
    swapText(x) {
      let self = this;

      if (x.status === 1) {
        self.marketingTitle = self.$i18n.t("modifyMarketingPlan");
        self.marketingText = self.$i18n.t("AreYouSureToModifyYourPlan");
        self.acceptText = self.$i18n.t("modify");
      } else {
        self.marketingTitle = self.$i18n.t("ValidateMarketingPlan");
        self.marketingText = self.$i18n.t("AreYouSureToValidateYourPlan");
        self.acceptText = self.$i18n.t("Validate");
      }
    },
    validatePlan() {
      let self = this;

      http
        .post(
          this.$store.state.apiUrl +
            "/prerd/activatePlan/" +
            this.selectedPreplan,
          {},
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        )
        .then((res) => {
          common.makeToast(
            self,
            self.$t("Validate"),
            self.$t("SuccessAdding"),
            self.brand + "-customSuccess",
            "b-toaster-bottom-center",
            true
          );
          setTimeout(
            function () {
              self.$router.push({
                name: "rdDashboard",
                query: { tab: "mkg" },
              });
            }.bind(this),
            1500
          );
        })
        .catch((e) => {
          common.makeToast(
            self,
            self.$t("warning"),
            self.$t("FailedAdding"),
            self.brand + "-customWarning",
            "b-toaster-bottom-center",
            true
          );
        });
    },
    downloadExcel() {
      http
        .getExcel(
          this.$store.state.apiUrl +
            "/global/preplan/template/" +
            this.selectedPreplan,
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        )
        .then((res) => {
          if (res[1]) {
            common.refreshToken(this, res[1].data.token, res[1].data.validity);
          }

          const blob = new Blob([res[0].data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, Math.random() + ".xlsx");
          this.isLoading = false;
        });
    },
    displayPopUp(code_event, supportOrOperation, supportList = []) {
      this.mode = "add";
      this.supportOrOperation = supportOrOperation;
      this.supportList = supportList;
      this.trade_code = null;
      this.media_code = null;
      this.operation = "";
      this.selectedEvent = code_event;
      this.addModal = true;
    },
    updatePreSupport(data, supportOrOperation, supportList = []) {
      this.mode = "update";
      this.supportList = supportList;
      this.selectedSupport = data.item.code;
      this.supportOrOperation = supportOrOperation;
      this.operation = data.item.operation || "";
      this.media_code = data.item.media_support_code || null;
      this.trade_code = data.item.trade_code || null;
      this.selectedEvent = data.item.pre_event_code;
      this.addModal = true;
    },
    deleteModal(data) {
      this.dataDelete = data;
      this.$refs["deleteOperationModal"].show();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    removePreSupport() {
      this.$store.dispatch("removeOperation", {
        $cookie: this.$cookie,
        codePreSupport: this.dataDelete.item.code,
      });
      this.hideModal("deleteOperationModal");
    },
    handleValidate(data) {
      const myData = {
        ...data,
        pre_event_code: this.selectedEvent,
        pre_plan_code: this.selectedPreplan,
      };
      if (this.mode === "add") {
        this.$store.dispatch("addOperation", {
          $cookie: this.$cookie,
          myData,
        });
      } else {
        myData.code = this.selectedSupport;
        myData.pre_event_code;
        this.$store.dispatch("updateOperation", {
          $cookie: this.$cookie,
          myData,
        });
      }
    },
  },
};
</script>

<style scoped>
.selectPlan {
  max-width: 500px;
  margin-bottom: 30px;
}
</style>
