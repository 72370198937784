<template>
  <div class="pad30">
    <loading :active.sync="loading" :is-full-page="true"></loading>
    <div class="row">
      <div class="col-sm-12">
        <BackBtn />
        <h5 class="titleDetails">
          {{ $t("MyMarketingPlan") }}
          <i class="fas fa-angle-double-right ml5 mr5"></i>
          {{ prePlanName }}
        </h5>
      </div>
    </div>
    <br />
    <PointOfInterestBudget
      :marketingBudget="amount"
      :pointCode="id"
      :pointText="storeName"
      :downloadExcel="downloadExcel"
    />
    <Schedule
      :schedule="schedule"
      :scope="preplanid"
      :year="year"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import Schedule from "./Schedule/Schedule.vue";
import BackBtn from "@/components/Btn/Back.vue";
import PointOfInterestBudget from "@/components/Card/PointOfInterestBudget.vue";
import http from "@/helpers/http";
import common from "../helpers/common";
import prePlanDone from "@/store/modules/MarketingPlan/prePlanDone";
import PreEvent from "@/store/modules/PreEvent/PreEvent";
import Loading from "vue-loading-overlay";
import moment from "moment";
import FileSaver from "file-saver";
export default {
  components: {
    Schedule,
    BackBtn,
    Loading,
    PointOfInterestBudget,
  },
  methods: {
    downloadExcel() {
      http
        .getExcel(
          this.$store.state.apiUrl +
            "/global/preplandone/template/" +
            this.$route.params.preplanid,
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        )
        .then((res) => {
          if (res[1]) {
            common.refreshToken(this, res[1].data.token, res[1].data.validity);
          }

          const blob = new Blob([res[0].data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, Math.random() + ".xlsx");
          this.isLoading = false;
        });
    },
  },
  beforeMount() {
    common.isAuth(this, http);
  },
  created: function () {
    const store = this.$store;

    store.registerModule("prePlanDone", prePlanDone);
    store.registerModule("PreEvent", PreEvent);
  },
  destroyed: function () {
    const store = this.$store;

    store.unregisterModule("prePlanDone");
    store.unregisterModule("PreEvent");
  },
  mounted() {
    this.$store.dispatch("getPrevent", {
      $cookie: this.$cookie,
    });
    this.$store.dispatch("getPrePlanDone", {
      preplanid: this.$route.params.preplanid,
      $cookie: this.$cookie,
    });
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters.getLoadingPage;
      },
    },
    amount: {
      get() {
        return this.$store.getters.getAmount;
      },
    },
    disabled: {
      get() {
        if (this.$store.getters.getStatus === 2) {
          return true;
        }
        return false;
      },
    },
    schedule: {
      get() {
        return this.$store.getters.getterPrePlanDone;
      },
    },

    start_date: {
      get() {
        return (
          this.$store.getters.getMetaDataPrePlanDone?.scope?.start_date || ""
        );
      },
    },
    year: {
      get() {
        const firstDate =
          this.$store.getters.getMetaDataPrePlanDone?.scope?.start_date ||
          "01-01-2020";
        return moment(firstDate, "YYYY-MM-DD").year();
      },
    },
    end_date: {
      get() {
        return (
          this.$store.getters.getMetaDataPrePlanDone?.scope?.end_date || ""
        );
      },
    },
    storeName: {
      get() {
        return (
          this.$store.getters.getMetaDataPrePlanDone?.pointOfInterest?.name ||
          ""
        );
      },
    },
    id: {
      get() {
        return (
          this.$store.getters.getMetaDataPrePlanDone?.pointOfInterest?.code ||
          ""
        );
      },
    },
    prePlanName: {
      get() {
        return this.$store.getters.getMetaDataPrePlanDone?.scope?.label || "";
      },
    },
  },
  data() {
    return {
      preplanid: this.$route.params.preplanid,
    };
  },
};
</script>

<style scoped>
.titleDetails {
  display: inline-flex;
  align-items: center;
  margin: 0;
  margin-left: 15px;
}
</style>
