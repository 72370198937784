import http from "../../../helpers/http";

export default {
  state: () => ({
    trades: [],
  }),
  actions: {
    getTrade({ state, commit }, $cookie) {
      http
        .get(
          process.env.VUE_APP_APIURL + "/global/trade",
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
        .then((response) => {
          if (response && response.length > 0) {
            commit("setTrade", response[0].data);
          }
        });
    },
  },
  mutations: {
    setTrade(state, trades) {
      state.trades = trades;
    },
  },

  getters: {
    getTradesFull(state) {
      const sort = state.trades.map(el => el)
      sort.sort(
        (a, b) => a.display_order - b.display_order)
      return sort
    },
    getTrades(state) {
      return state.trades.map((el) => ({
        value: el.trade_code,
        text: el.label,
      }));
    },
  },
};
