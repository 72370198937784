export function supportOrOperation(line) {
  const { operation, media_support } = line;
  if (operation && media_support) {
    return `<b>${operation}</b><BR/>` + media_support;
  }
  if (media_support) {
    return `<b>${media_support}</b>`;
  }
  return `<b>${operation}</b>`;
}
