import Vue from 'vue';

export default {
  isAuth: function (thisParam, http) {
    let self = thisParam;

    if (self.$route.query.auth && self.$route.query.authExpires && self.$route.query.authExpiresDate) {
      self.$cookie.set("auth", self.$route.query.auth);
      self.$cookie.set("authExpires", self.$route.query.authExpires);
      self.$cookie.set("authExpiresDate", self.$route.query.authExpiresDate);
    }

    if (self.$cookie.get("auth")) {
      if (self.$route.params.brand !== self.$cookie.get('brand')) {
        self.$router.push({
          name: "login",
        });
        return;
      }
      if (self.$store.getters["Medias/getAllMedia"].length === 0) {
        self.$store.dispatch("Medias/fetchMedias", {
          self
        });
      }
      if (self.$route.name == "login" || self.$route.name == "auth") {
        http
          .get(
            process.env.VUE_APP_APIURL + "/global/rights",
            self.$cookie.get("auth"),
            self.$cookie.get("authExpires")
          )
          .then(function (result) {
            self.$cookie.set("isDR", result[0].data.isDR);
            self.$cookie.set("isPOS", result[0].data.isPOS);

            self.$cookie.set("brand", self.$route.params.brand);


            if (result[0].data.config && result[0].data.config.allowRate) {
              self.$cookie.set(
                "allowRate",
                result[0].data.config.allowRate
              );
            } else {
              self.$cookie.set("allowRate", true);
            }

            if (result[0].data.isDR == true) {
              if (self.$route.query.tab) {
                if (self.$route.query.tab == "mkg") {
                  self.$router.push({
                    name: "rdDashboard",
                    query: {
                      tab: 'mkg'
                    }
                  });
                } else if (self.$route.query.tab == "abd") {
                  self.$router.push({
                    name: "rdDashboard",
                    query: {
                      tab: 'abd'
                    }
                  });
                } else {
                  self.$router.push({
                    name: "rdDashboard"
                  });
                }
              } else {
                self.$router.push({
                  name: "rdDashboard"
                });
              }

            } else if (result[0].data.isPOS == true) {
              if (self.$route.query.tab) {
                if (self.$route.query.tab == "mkg") {
                  self.$router.push({
                    name: "posDashboard",
                    query: {
                      tab: 'mkg'
                    }
                  });
                } else if (self.$route.query.tab == "abd") {
                  self.$router.push({
                    name: "posDashboard",
                    query: {
                      tab: 'abd'
                    }
                  });
                } else {
                  self.$router.push({
                    name: "posDashboard"
                  });
                }
              } else {
                self.$router.push({
                  name: "posDashboard"
                });
              }
            }
          });
      }
    } else {
      if (self.$route.name != "login") {
        self.$router.push({
          name: "login",
        });
      }
    }
  },
  logout: function () {
    Vue.cookie.delete("auth");
    Vue.cookie.delete("authExpires");
    Vue.cookie.delete("authExpiresDate");
    window.location.href = '/' + window.location.pathname.split("/")[1];
  },
  refreshToken: function (token, validity) {
    const expiresDate = Date.now() + (validity * 1000);

    Vue.cookie.set("auth", token, {
      expires: parseInt(validity, 10) + "s"
    });
    Vue.cookie.set(
      "authExpires",
      validity, {
        expires: parseInt(validity, 10) + "s"
      }
    );
    Vue.cookie.set(
      "authExpiresDate",
      expiresDate, {
        expires: parseInt(validity, 10) + "s"
      }
    );
  },
  makeToast(
    thisParam,
    title,
    message,
    variant = null,
    toaster,
    append = false
  ) {
    thisParam.$bvToast.toast(message, {
      title: title,
      toaster: toaster,
      variant: variant,
      solid: true,
      appendToast: append,
    });
  },
};