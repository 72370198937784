/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import { FETCH_MEDIAS } from "./mutation-types";

export default {
  [FETCH_MEDIAS]: (state, medias) => {
    state.data = medias;
  }
};
