import {reportActions} from './actions';
import {reportMutations} from './mutations';
import {reportGetters} from './getters';

const state = {
    filters: {
        start_date: null,
        end_date: null,
    }
};
const actions = {...reportActions};
const mutations = {...reportMutations};
const getters = {...reportGetters};


// eslint-disable-next-line import/prefer-default-export
export const reportStore = {
    state, actions, mutations, getters,
};
