<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    v-model="isDisplayed"
    :title="$t('ActionDescription')"
    hide-footer
  >
    <div v-for="(item, index) in scope" :key="index">
      <b>Description de l'action</b>
      :
      {{ item.comment }}
      <span v-if="isNotEmpty(item.media_support_codes)">
        <br />
        <b>{{ $t("Medias") }}</b>
        :
        <span v-html="displayIcons(item.media_support_codes)"></span>
      </span>
      <br />
      <b>{{ $t("Budget") }}</b>
      :
      {{ item.amount | convertInEuro }}
      <hr v-if="index !== scope.length - 1" />
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: true,
    },
    scope: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isNotEmpty: function (defaultMediaSupportCodes) {
      return (defaultMediaSupportCodes =
        JSON.parse(defaultMediaSupportCodes).length > 0);
    },
    yourMediaDataFull: function (defaultMediaSupportCodes) {
      console.log(typeof defaultMediaSupportCodes);
      return this.listOfMedia.filter(
        (el) =>
          !!defaultMediaSupportCodes.find((s) => s === el.media_support_code)
      );
    },
    displayIcons(defaultMediaSupportCodes) {
      defaultMediaSupportCodes = JSON.parse(defaultMediaSupportCodes);
      const x = this.yourMediaDataFull(defaultMediaSupportCodes)
        .map((el) => "<i class=" + el.picto + "></i> " + el.name)
        .join(", ");
      return x;
    },
  },
  computed: {
    listOfMedia() {
      return this.$store.getters["Medias/getAllMedia"];
    },
    self: {
      get: function () {
        return this;
      },
    },

    isDisplayed: {
      get: function () {
        return this.display;
      },
      set: function (display) {
        this.$emit("update:display", display); // for sync
      },
    },
  },
};
</script>

<style></style>
