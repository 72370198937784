function formatDate() {
  let d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const utilsMixin = {
  methods: {
    forceFileDownload(stream, type = "pdf") {
      //Create a Blob from the PDF Stream
      let contentType;
      if (type === "pdf") {
        contentType = "application/pdf";
      } else {
        contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }

      const file = new Blob([stream], {
        type: contentType,
      });
      //Build a URL from the file
      const blob = URL.createObjectURL(file);

      const link = document.createElement("a");
      // create a blobURI pointing to our Blob
      link.href = blob;
      link.download = formatDate() + "_report." + type;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      window.addEventListener("focus", (e) => URL.revokeObjectURL(link.href), {
        once: true,
      });
    },
  },
};

export default utilsMixin;
