var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","no-close-on-esc":"","title":_vm.title},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 float-right"},[_c('b-button',{staticClass:"float-right ml-1",attrs:{"pill":"","variant":_vm._f("variantBrand")('customPrimary',_vm.self)},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" "+_vm._s(_vm.$t("Validate")))]),_c('b-button',{staticClass:"float-right ml-1",attrs:{"pill":"","variant":"secondary"},on:{"click":function($event){_vm.isDisplayed = false}}},[_vm._v(_vm._s(_vm.$t("Cancel"))+" ")])],1)]},proxy:true}]),model:{value:(_vm.isDisplayed),callback:function ($$v) {_vm.isDisplayed=$$v},expression:"isDisplayed"}},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("DescriptionAction")))]),_c('b-form-textarea',{class:{
        pleaseFill:
          (_vm.description === null || _vm.description === '') &&
          _vm.isValidation == false,
      },attrs:{"rows":"10","rows-max":"15","id":"input-1","required":"","placeholder":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('hr'),(_vm.listOfSupport.length > 0)?_c('v-select',{class:{
        pleaseFill: _vm.mediaSupportCodes.length == 0 && _vm.isValidation == false,
      },attrs:{"label":"text","reduce":function (support) { return support.value; },"multiple":"","options":_vm.listOfSupport,"placeholder":_vm.$t('SupportToSelect')},model:{value:(_vm.mediaSupportCodes),callback:function ($$v) {_vm.mediaSupportCodes=$$v},expression:"mediaSupportCodes"}}):_vm._e(),_c('hr'),_vm._v(" "+_vm._s(_vm.$t("Budget"))+" "),_c('i',[_vm._v(_vm._s(_vm.$t("BudgetInfo")))]),_c('b-form-input',{class:{
        pleaseFill: (_vm.price === null || _vm.price === '') && _vm.isValidation == false,
      },attrs:{"type":"number","min":"0"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }