<template>
  <b-tabs class="mb-3">
    <!-- Add your b-tab components here -->
    <template v-slot:tabs-end>
      <b-nav-item
        href="#"
        :active="active == 2"
        role="presentation"
        @click="goToMarketingPlan"
        >{{ $t("MarketingPlan") }}</b-nav-item
      >
      <b-nav-item
        href="#"
        :active="active == 1"
        role="presentation"
        @click="goToDashboardPlan"
        >{{ $t("FileOfAbundance") }}</b-nav-item
      >
    </template>
  </b-tabs>
</template>

<script>
export default {
  methods: {
    goToDashboardPlan() {
      this.$router.push({
        name: this.getSuffix + "Dashboard",
      });
    },
    goToMarketingPlan() {
      this.$router.push({
        name: "MarketingPlanDashboardTo" + this.getSuffix,
      });
    },
  },
  computed: {
    getSuffix: {
      get() {
        if (this.isRd == true) {
          return "rd";
        }
        if (this.isPos == true) {
          return "pos";
        }

        return "error";
      },
    },
    isRd: {
      get() {
        return this.$cookie.get("isDR") == "true";
      },
    },
    isPos: {
      get() {
        return this.$cookie.get("isPOS") == "true";
      },
    },
  },
  props: {
    active: {
      type: Number,
      default: 2,
    },
  },
  created() {
    if (this.$route.query.tab) {
      if (this.$route.query.tab === "mkg") {
        this.goToMarketingPlan();
      }
    }
  },
};
</script>

<style></style>
