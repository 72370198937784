<template>
  <div
    class="backgroundImage"
    :style="{ 'background-image': 'url(' + backgroundImage + ')' }"
  >
    <div class="whiteNav">
      <b-navbar-brand>
        <img :src="mainLogo" class="loginLogo" />
      </b-navbar-brand>
    </div>
    <div class="container pad30 maxW700 row align-items-center m-auto">
      <div class="col-sm-12">
        <b-card>
          <h3 class="mb30 text-center cardTitle">{{ $t("Login") }}</h3>
          <p>
            Entrez votre
            <b>email</b> et votre <b>mot de passe</b> pour vous connecter à
            votre espace.
          </p>
          <p class="italic fs10">
            Pour une navigation optimale veuillez utiliser la dernière version
            de
            <b>Google Chrome</b> ou <b>Mozilla Firefox</b>.
          </p>
          <b-form @submit="onSubmit">
            <b-input-group class="mb10">
              <b-input-group-prepend>
                <span class="input-group-text no-border">
                  <i class="fa fa-user-circle"></i>
                </span>
              </b-input-group-prepend>
              <b-form-input
                id="login"
                v-model="form.login"
                type="email"
                required
                :placeholder="$t('Email')"
                class="loginInput"
              ></b-form-input>
            </b-input-group>

            <b-input-group class="mb10">
              <b-input-group-prepend>
                <span class="input-group-text no-border">
                  <i class="fa fa-unlock-alt"></i>
                </span>
              </b-input-group-prepend>
              <b-form-input
                id="password"
                type="password"
                v-model="form.password"
                required
                :placeholder="$t('Password')"
                class="loginInput"
              ></b-form-input>
            </b-input-group>

            <b-button
              type="submit"
              :variant="customPrimaryVariant"
              class="w-100 loginButton"
            >
              <i class="fas fa-key mr10"></i>
              {{ $t("Connexion") }}
            </b-button>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../helpers/http";
import common from "../helpers/common";

export default {
  beforeMount() {
    common.isAuth(this, http);
  },
  data() {
    return {
      backgroundImage:
        "/img/" +
        window.location.pathname.split("/")[1] +
        "/backgroundLogin.jpg",
      form: {
        login: "",
        password: "",
      },
      mainLogo: "/img/" + window.location.pathname.split("/")[1] + "/login-logo.png",
      brand: window.location.pathname.split("/")[1],
      customPrimaryVariant:
        window.location.pathname.split("/")[1] + "-customPrimary",
    };
  },
  methods: {
    onSubmit(evt) {
      let self = this;
      evt.preventDefault();

      http
        .postLogin(process.env.VUE_APP_AUTH_URL + "/auth/", this.form)
        .then(function (result) {
          if (result.status == 200) {
            let expires = result.data.validity;
            let expiresDate = Date.now() + result.data.validity * 1000;

            self.$cookie.set("auth", result.data.token, {
              expires: expires + "s",
            });
            self.$cookie.set("authExpires", expires, {
              expires: expires + "s",
            });
            self.$cookie.set("authExpiresDate", expiresDate, {
              expires: expires + "s",
            });
            common.makeToast(
              self,
              self.$i18n.t("SuccessfulConnexion"),
              self.$i18n.t("ConnexionWasSuccessful"),
              self.brand + "-customSuccess",
              "b-toaster-bottom-center",
              true
            );
            http
              .get(
                process.env.VUE_APP_APIURL + "/global/rights",
                self.$cookie.get("auth"),
                self.$cookie.get("authExpires")
              )
              .then(function (result) {
                self.$cookie.set("isDR", result[0].data.isDR);
                self.$cookie.set("isPOS", result[0].data.isPOS);
                self.$cookie.set("brand", self.$route.params.brand);
                if (result[0].data.config && result[0].data.config.allowRate) {
                  self.$cookie.set(
                    "allowRate",
                    result[0].data.config.allowRate
                  );
                } else {
                  self.$cookie.set("allowRate", true);
                }

                if (result[0].data.isDR == true) {
                  self.$router.push({
                    name: "rdDashboard",
                  });
                } else if (result[0].data.isPOS == true) {
                  self.$router.push({
                    name: "posDashboard",
                  });
                } else {
                  self.$router.push({
                    name: "posDashboard",
                  });
                }
              });
          } else {
            common.makeToast(
              self,
              self.$i18n.t("ConnexionRefused"),
              self.$i18n.t("IncorrectEmailOrPassword"),
              self.brand + "-customWarning",
              "b-toaster-bottom-center",
              true
            );
          }
        })
        .catch(function () {
          common.makeToast(
            self,
            self.$i18n.t("Error"),
            self.$i18n.t("ConnexionFailed"),
            self.brand + "-customDanger",
            "b-toaster-bottom-center",
            true
          );
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// Import custom SASS variable overrides
@import "../assets/custom-vars.scss";

.maxW700 {
  height: calc(100vh - 60px);
}

.navbar {
  display: none !important;
}

.mb10 {
  margin-bottom: 10px;
}

.backgroundImage {
  background-size: cover;
  height: calc(100vh - 60px);
}

.whiteNav {
  background: white !important;
  width: 100%;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginLogo {
  max-height: 48px;
  padding: 5px;
}

.cardTitle {
  font-size: 18px;
  font-weight: bold;
}

.loginButton {
  height: 48px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.loginInput {
  border: none;
  height: 48px;
  background: #ececec;
}

.fs10 {
  font-size: 10px;
}

.italic {
  font-style: italic;
}

.no-border {
  border: none;
}
</style>
