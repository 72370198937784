<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    v-model="isDisplayed"
    :title="$t('ActionDescription')"
  >
    <div>
      <b>Description de l'action</b> :
      <br />
      {{ defaultDescrition }}
      <hr />
      <b>{{ $t("Medias") }}</b>
      :

      <span v-html="displayIcons"></span>

      <hr v-if="defaultMediaSupportCodes.length > 0" />
      <b>{{ $t("Budget") }}</b>
      :
      {{ defaultPrice | convertInEuro }}
    </div>
    <template v-slot:modal-footer>
      <div class="w-100 float-right">
        <b-button
          pill
          v-if="!disabled"
          :variant="'customPrimary' | variantBrand(self)"
          class="float-right ml-1"
          @click="
            isDisplayed = false;
            remove();
          "
        >
          <i class="fas fa-trash mr5"></i>
          {{ $t("Delete") }}
        </b-button>
        <b-button
          pill
          v-if="!disabled"
          :variant="'customPrimary' | variantBrand(self)"
          class="float-right ml-1"
          @click="
            isDisplayed = false;
            edit();
          "
        >
          <i class="fas fa-edit mr5"></i>
          {{ $t("Edit") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    display: {
      type: Boolean,
      default: true,
    },
    defaultDescrition: {
      type: String,
      default: "",
    },
    defaultMediaSupportCodes: {
      type: Array,
      default: () => [],
    },
    defaultPrice: {
      type: Number,
      default: null,
    },
  },

  methods: {
    edit: function () {
      this.$emit("edit");
    },
    remove: function () {
      this.$emit("remove");
    },
  },
  computed: {
    yourMediaDataFull: function () {
      return this.listOfMedia.filter(
        (el) =>
          !!this.defaultMediaSupportCodes.find(
            (s) => s === el.media_support_code
          )
      );
    },
    displayIcons() {
      const x = this.yourMediaDataFull
        .map((el) => "<i class=" + el.picto + "></i> " + el.name)
        .join(", ");

      return x;
    },
    listOfMedia() {
      return this.$store.getters["Medias/getAllMedia"];
    },
    self: {
      get: function () {
        return this;
      },
    },

    isDisplayed: {
      get: function () {
        return this.display;
      },
      set: function (display) {
        this.$emit("update:display", display); // for sync
      },
    },
  },
};
</script>

<style></style>
