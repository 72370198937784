<template>
  <div class="pad30">
    <Tab :active="isMarketing" />
    <div class="row">
      <div class="col-sm-12">
        <h3 class="mb30 text-left">{{ $t("TitleDashboard") }}</h3>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 mb30">
        <div class="cardInfo">
          <div>
            <div class="roundTotal inlineFlex">
              <h3 class="m0" style="font-size: 36px">{{ totalPlans }}</h3>
            </div>
            <p
              class="inlineFlex m0 text-left"
              style="
                float: right;
                height: 150px;
                align-items: center;
                margin-left: 15px;
              "
            >
              <span class="totalColor bold" style="display: contents"
                >Nombre total</span
              >
              <br />de fichier(s) <br />d'abondement(s)<br />en cours
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 mb30">
        <div class="cardInfo">
          <div>
            <vc-donut
              class="inlineFlex"
              style="margin-top: 15px"
              background="white"
              foreground="#e2e2e2"
              :size="120"
              unit="px"
              :thickness="15"
              :sections="toTreatSections"
              :total="totalGraph"
              :start-angle="0"
            >
              <h3 class="m0">
                <span class="warningColor" style="font-size: 36px">
                  {{ toTreatCount }}
                </span>
                /{{ totalPlans }}
              </h3>
            </vc-donut>
            <p
              class="inlineFlex m0 text-left"
              style="
                float: right;
                height: 150px;
                align-items: center;
                margin-left: 15px;
              "
            >
              Fichier(s)
              <br />d'abondement(s)
              <br />
              <span class="warningColor bold" style="display: contents"
                >à traiter</span
              >
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 mb30">
        <div class="cardInfo">
          <div>
            <vc-donut
              class="inlineFlex"
              style="margin-top: 15px"
              background="white"
              foreground="#e2e2e2"
              :size="120"
              unit="px"
              :thickness="15"
              :sections="waitingSections"
              :total="totalGraph"
              :start-angle="0"
            >
              <h3 class="m0">
                <span class="infoColor" style="font-size: 36px">
                  {{ waitingCount }}
                </span>
                /{{ totalPlans }}
              </h3>
            </vc-donut>
            <p
              class="inlineFlex m0 text-left"
              style="
                float: right;
                height: 150px;
                align-items: center;
                margin-left: 15px;
              "
            >
              Fichier(s)
              <br />d'abondement(s)
              <br />
              <span class="infoColor bold" style="display: contents">
                en attente de
                <br />retour DR
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-12 mt15">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

        <div class="row">
          <div class="col-sm-12">
            <b-button
              pill
              :variant="customPrimaryVariant"
              class="fw500 float-right"
              :to="{ name: 'reportsMenu', params: { type: 'pos' } }"
            >
              <i class="fas fa-file-alt mr5"></i>
              {{ $t("generateReport") }}
            </b-button>
          </div>
        </div>

        <h5 class="m0 text-left">
          <i class="fas fa-list mr5"></i>
          {{ $t("Abondement") }}
        </h5>

        <vue-bootstrap4-table
          id="inProgress"
          :classes="classes"
          :rows="rows"
          :columns="columns"
          :config="config"
          @on-select-row="clickRow"
        >
          <template slot="name" slot-scope="props">
            <div>
              <i v-if="props.row.status === 0 || props.row.status === 3" class="fas fa-circle warningColor mr10 fs10"></i>
              <i v-else-if="props.row.status === 1 || props.row.status === 4" class="fas fa-circle infoColor mr10 fs10"></i>
              <i v-else-if="props.row.status === 7" class="fas fa-circle successColor mr10 fs10"></i>
              {{ props.row.name }}
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <b-dropdown
              dropleft
              text="Drop-Left"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <i class="fas fa-ellipsis-v primaryColor fs18"></i>
              </template>
              <b-dropdown-item
                v-if="props.row.status === 0"
                :to="{
                  name: 'posDetails',
                  params: { id: props.row.fillPlanCode },
                }"
                :data-id="'dropdown' + props.row.vbt_id"
              >
                <i class="fas fa-edit mr5"></i>
                {{ $t("EditFile") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-else-if="
                  props.row.status === 4 ||
                  props.row.status === 3 ||
                  props.row.status === 7 ||
                  props.row.status === 1
                "
                :to="{
                  name: 'posDetails',
                  params: { id: props.row.fillPlanCode },
                }"
                :data-id="'dropdown' + props.row.vbt_id"
              >
                <i class="fas fa-eye mr5"></i>
                {{ $t("ViewFile") }}
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template slot="statusName" slot-scope="props"
            >{{ props.row.statusName }}
            <span
              class="roundDiv ml5"
              v-if="props.row.commentCount > 0 && props.row.status !== 7"
              >{{ props.row.commentCount }}</span
            >
          </template>

          <template slot="creationDate" slot-scope="props">{{
            props.row.creationDate | dateGenericFormat
          }}</template>

          <template slot="modificationDate" slot-scope="props">{{
            props.row.modificationDate | dateGenericFormat
          }}</template>

          <template slot="global-search-clear-icon">
            <i class="fas fa-times-circle waitingColor"></i>
          </template>

          <template slot="simple-filter-clear-icon">
            <i class="fas fa-times-circle waitingColor"></i>
          </template>

          <template slot="sort-asc-icon">
            <i class="fas fa-sort-up"></i>
          </template>

          <template slot="sort-desc-icon">
            <i class="fas fa-sort-down"></i>
          </template>

          <template slot="no-sort-icon">
            <i class="fas fa-sort"></i>
          </template>

          <template slot="empty-results">{{ $t("EmptyResults") }}</template>

          <template slot="paginataion-previous-button">{{
            $t("Previous")
          }}</template>

          <template slot="paginataion-next-button">{{ $t("Next") }}</template>

          <template slot="pagination-info" slot-scope="props">
            {{ props.filteredRowsLength }} {{ $t("filteredResults") }}
            {{ props.originalRowsLength }}
          </template>
        </vue-bootstrap4-table>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../helpers/http";
import common from "../helpers/common";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Tab from "@/components/Tab/Tab.vue";
import _ from "lodash";

import JQuery from "jquery";
let $ = JQuery;

export default {
  beforeMount() {
    common.isAuth(this, http);
  },
  data() {
    return {
      isMarketing: 1,
      toTreatSections: [
        {
          value: 0,
          color: "#ff9f42",
        },
      ],
      waitingSections: [
        {
          value: 0,
          color: "#0387C5",
        },
      ],
      customPrimaryVariant:
        window.location.pathname.split("/")[1] + "-customPrimary",
      isLoading: false,
      fullPage: true,
      totalPlans: 0,
      totalGraph: 1,
      toTreatCount: 0,
      campaignCount: 0,
      posCount: 0,
      waitingCount: 0,
      rows: [],
      columns: [
        {
          label: this.$i18n.t("PeriodOfTime"),
          name: "name",
          filter: {
            type: "simple",
            placeholder: this.$i18n.t("PeriodOfTime"),
          },
          sort: true,
        },
        {
          label: this.$i18n.t("Pdv"),
          name: "pos",
          filter: {
            type: "simple",
            placeholder: this.$i18n.t("Pdv"),
          },
          sort: true,
        },
        {
          label: this.$i18n.t("CreationDate"),
          name: "creationDate",
          filter: {
            type: "simple",
            placeholder: this.$i18n.t("CreationDate"),
          },
          sort: true,
        },
        {
          label: this.$i18n.t("LastModification"),
          name: "modificationDate",
          filter: {
            type: "simple",
            placeholder: this.$i18n.t("LastModification"),
          },
          sort: true,
        },
        {
          label: this.$i18n.t("Status"),
          name: "statusName",
          sort: true,
          initial_sort: true, // "false" by default
          initial_sort_order: "asc", // "asc" by default
          sortCaseSensitive: false, // "true" by default
          filter: {
            type: "select",
            mode: "multi",

            placeholder: this.$i18n.t("Status"),
            options: [
              {
                name: this.$i18n.t("ToTreat"),
                value: this.$i18n.t("ToTreat"),
              },
              {
                name: this.$i18n.t("WaitingReturnDR"),
                value: this.$i18n.t("WaitingReturnDR"),
              },
              {
                name: this.$i18n.t("Finished"),
                value: this.$i18n.t("Finished"),
              },
            ],
            select_all_checkbox: {
              visibility: true,
              text: "Tout",
            },
          },
        },
        {
          label: this.$i18n.t("Actions"),
          name: "actions",
          sort: false,
        },
      ],
      classes: {
        cell: {
          "customWaitingBackground text-center": function (
            row,
            column,
          ) {
            if (
              column.name == "statusName" &&
              [1,4].includes(row.status)
            ) {
              return true;
            }
          },
          "customWarningBackground text-center": function (
            row,
            column,
          ) {
            if (column.name == "statusName" && [0, 3].includes(row.status)) { 
              return true;
            }
          },
          "customSuccessBackground text-center": function (
            row,
            column,
          ) {
            if (column.name == "statusName" && [7,8,9].includes(row.status)) {
              return true;
            }
          },
        },
      },
      config: {
        rows_selectable: true,
        global_search: {
          visibility: false,
        },
        show_refresh_button: false,
        show_reset_button: false,
        card_mode: false,
        highlight_row_hover: false,
        per_page_options: [],
      },
    };
  },
  components: {
    Loading,
    Tab,
  },
  mounted() {
    let self = this;

    this.isLoading = true;
    // Get dashboard rows
    http
      .get(
        this.$store.state.apiUrl + "/pos/plan/",
        this.$cookie.get("auth"),
        this.$cookie.get("authExpires")
      )
      .then((res) => {
        window.addEventListener("click", function () {
          document
            .getElementsByClassName("dropdown-menu")[0]
            .removeAttribute("style");
        });
        document
          .getElementById("multifilter_statusName")
          .addEventListener("click", function (event) {
            event.stopPropagation();
            if (
              document
                .getElementsByClassName("dropdown-menu")[0]
                .getAttribute("style") == null
            ) {
              document
                .getElementsByClassName("dropdown-menu")[0]
                .setAttribute("style", "display:block");
            } else {
              document
                .getElementsByClassName("dropdown-menu")[0]
                .removeAttribute("style");
            }
          });
        let response = res[0];
        let array = [];

        if (res[1]) {
          common.refreshToken(this, res[1].data.token, res[1].data.validity);
        }

        this.totalPlans = response.data.metadata.total;
        this.totalGraph = response.data.metadata.total;
        this.toTreatCount = response.data.metadata.toTreat;
        this.toTreatSections = [
          {
            value: response.data.metadata.toTreat,
            color: "#ff9f42",
          },
        ];
        this.waitingCount = response.data.metadata.waiting;
        this.waitingSections = [
          {
            value: response.data.metadata.waiting,
            color: "#0387C5",
          },
        ];
        this.campaignCount = response.data.metadata.campaignCount;
        this.posCount = response.data.metadata.posCount;

        for (let i = 0; i < response.data.data.length; i++) {
          let statusName = "";

          switch (response.data.data[i].status) {
            case 0:
              statusName = this.$i18n.t("ToTreat");
              break;
            case 3:
              statusName = this.$i18n.t("ToTreat");
              break;
            case 4:
              statusName = this.$i18n.t("WaitingReturnDR");
              break;
            case 1:
              statusName = this.$i18n.t("WaitingReturnDR");
              break;
            case 7:
            case 8:
            case 9:
              statusName = this.$i18n.t("Finished");
              break;
          }

          let commentCount = response.data.data[i].commentCount;

          if (response.data.data[i].status === 7) {
            commentCount = 0;
          }

          array.push({
            name: response.data.data[i].name,
            pos: response.data.data[i].pos,
            creationDate: response.data.data[i].creationDate,
            modificationDate: response.data.data[i].modificationDate,
            planCode: response.data.data[i].plan_code,
            commentCount: commentCount,
            fillPlanCode: response.data.data[i].plan_done_code,
            status: response.data.data[i].status,
            statusName: statusName,
            actions: "",
          });
        }
        this.rows = _.orderBy(
          array,
          ["commentCount", "statusName"],
          ["desc", "asc"]
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    clickRow(row) {
      console.log(row);
      document
        .querySelectorAll(
          "a[data-id=dropdown" + row.selected_item.vbt_id + "]"
        )[0]
        .click();
    },
    showModal() {
      this.$refs["addOperationModal"].show();
    },
    hideModal() {
      this.$refs["addOperationModal"].hide();
    },
  },
  created() {
    if (this.$route.query.tab) {
      if (this.$route.query.tab === "mkg") {
        this.isMarketing = 2;
      } else if (this.$route.query.tab === "abd") {
        this.isMarketing = 1;
      }
    }
    if (this.isMarketing === 2) {
      document.title = "Plans marketing";
    } else {
      document.title = "Abondements";
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// Import custom SASS variable overrides
@import "../assets/custom-vars.scss";

.cardInfo {
  width: 100%;
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: $minBorder;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.waitingColor {
  color: #b2bad0;
}

.infoColor {
  color: #0387c5;
}

.infoBackground {
  background: #0387c5;
}

.inProgress .card-header {
  display: none;
}

.table-active {
  background-color: #fcfcfc !important;
}

.stateDiv {
  background: #f1f3f8;
  width: fit-content;
  padding: 5px;
  margin: auto;
  border-radius: $minBorder;
}

.roundTotal {
  background: #e6e6e6;
  height: 120px;
  width: 120px;
  border-radius: 500px;
  align-items: center;
  justify-content: center;
  color: #333;
  margin-top: 15px;
}

.totalColor {
  color: #aaaab7;
}
</style>
