<template>
  <div class="i-flex i-center">
    <div class="text-left pt-3">
      {{ $t("DisplayBy") }}
      <BR />
      <b-button
        class="period"
        :class="range === 'M' ? 'selected' : null"
        @click="updateRange('M')"
        >{{ $t("Month") }}</b-button
      >

      <b-button
        class="period"
        @click="updateRange('B')"
        :class="range === 'B' ? 'selected' : null"
        >{{ $t("Bimestre") }}</b-button
      >
      <b-button
        class="period"
        @click="updateRange('T')"
        :class="range === 'T' ? 'selected' : null"
        >{{ $t("Trimester") }}</b-button
      >
      <b-button
        class="period"
        @click="updateRange('Y')"
        :class="range === 'Y' ? 'selected' : null"
        >{{ $t("Year") }}</b-button
      >
    </div>
    <div class="text-left pt-3 ml15 min-w-300" v-if="selectedRange !== 'Y'">
      {{ $t("ChooseRange") }}
      <v-select
        id="periodSelected"
        label="text"
        :reduce="(period) => period.value"
        v-model="period"
        :options="periods"
      ></v-select>
    </div>
    <div class="text-left pt-3 ml15" v-if="selectedRange !== 'Y'">
      <br />
      <b-button
        @click="decreasePeriod"
        :variant="'customPrimary' | variantBrand(this)"
        class="btn-circle"
      >
        <i class="fa fa-chevron-left" />
      </b-button>
      <b-button
        @click="increasePeriod"
        :variant="'customPrimary' | variantBrand(this)"
        class="btn-circle ml-1"
      >
        <i class="fa fa-chevron-right" />
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { variantBrand } from "@/filters/brand.js";
export default {
  props: {
    selectedPeriod: { type: Number, default: 1 },
    selectedRange: { type: String, default: "B" },
    startWeek: { type: Number, default: 1 },
    selectedStartWeekRange: { type: Number, default: 1 },
    year: { type: Number, default: 2021 },
  },

  methods: {
    variantBrandComp(variant) {
      return variantBrand(variant, this);
    },
    updateRange(range) {
      this.range = range;
    },
    increasePeriod() {
      this.period++;
    },
    decreasePeriod() {
      this.period--;
    },
  },
  watch: {
    year() {
      this.period = 1;
    },
    periods: function () {
      this.period = 1;
    },
  },
  computed: {
    periods: {
      get: function () {
        const months = [
          this.$t("January"),
          this.$t("February"),
          this.$t("March"),
          this.$t("April"),
          this.$t("May"),
          this.$t("June"),
          this.$t("July"),
          this.$t("August"),
          this.$t("September"),
          this.$t("October"),
          this.$t("November"),
          this.$t("December"),
        ];

        if (this.selectedRange === "M") {
          return months.map((text, value) => {
            return { value: value + 1, text };
          });
        }
        if (this.selectedRange === "B") {
          let myPeriods = [];
          let loop = 0;
          for (let value = 0; value < months.length; value = value + 2) {
            loop++;
            myPeriods.push({
              value: loop,
              text: `${months[value]} - ${months[value + 1]}`,
            });
          }
          return myPeriods;
        }
        if (this.selectedRange === "T") {
          let myPeriods = [];
          let loop = 0;
          for (let value = 0; value < months.length; value = value + 3) {
            loop++;
            myPeriods.push({
              value: loop,
              text: `${months[value]} - ${months[value + 1]} - ${
                months[value + 2]
              }`,
            });
          }
          return myPeriods;
        }

        return months;
      },
    },
    period: {
      get: function () {
        return this.selectedPeriod;
      },
      set: function (period) {
        let coef;
        switch (this.selectedRange) {
          case "M":
            coef = 1;
            break;
          case "B":
            coef = 2;
            break;
          case "T":
            coef = 3;
            break;
        }

        const month = (period - 1) * coef + 1;

        this.$emit("scope", this.periods[period - 1].text.split("-"));
        // avoid week zero
        let myFisrtweek = moment(
          `${month}-01-${this.year}`,
          "MM-DD-YYYY"
        ).week();
        if (myFisrtweek > 52 || (month == 1 && myFisrtweek >= 52)) {
          myFisrtweek = 0;
        }

        const weekRangMonth = [
          {
            start: myFisrtweek,
            end: moment(`${month}-01-${this.year}`, "MM-DD-YYYY")
              .endOf("month")
              .week(),
          },
          {
            start: moment(`${month + 1}-01-${this.year}`, "MM-DD-YYYY").week(),
            end: moment(`${month + 1}-01-${this.year}`, "MM-DD-YYYY")
              .endOf("month")
              .week(),
          },
          {
            start: moment(`${month + 2}-01-${this.year}`, "MM-DD-YYYY").week(),
            end: moment(`${month + 2}-01-${this.year}`, "MM-DD-YYYY")
              .endOf("month")
              .week(),
          },
        ];

        this.$emit("scopeRangMonth", weekRangMonth);

        this.$emit("update:selectedPeriod", period); // for sync

        let firstweek = moment(`${month}-01-${this.year}`, "MM-DD-YYYY").week();
        if (firstweek > 52 || (month === 1 && firstweek >= 52)) {
          firstweek = 0;
        }
        this.$emit("update:startWeek", firstweek);

        const weekRang =
          moment(`${month + coef - 1}-01-${this.year}`, "MM-DD-YYYY")
            .endOf("month")
            .week() -
          firstweek +
          1;

        this.$emit("update:selectedStartWeekRange", weekRang);
      },
    },
    range: {
      get: function () {
        return this.selectedRange;
      },
      set: function (range) {
        this.$emit("update:selectedRange", range); // for sync
      },
    },
  },
};
</script>

<style scoped>
.period {
  margin-left: 0px;
  margin-right: 4px;
  color: black;
  border: none;
  background: #e5e2e0;
  border-radius: 6px;
}

.selected {
  box-shadow: none;
  background: #cfeae3 !important;
  border-color: #627b77 !important;
  border-width: 1px;
  color: #627b77 !important;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.i-flex {
  display: inline-flex;
}

.i-center {
  align-items: center;
}

.min-w-300 {
  min-width: 300px;
}
</style>
