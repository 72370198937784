/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import http from "@/helpers/http";
import {
  FETCH_MEDIAS
} from "./mutation-types";
export default {
  fetchMedias({
    commit
  }, component) {
    const url = process.env.VUE_APP_APIURL + "/global/media_support";
    const cookie = component.self.$cookie.get("auth");
    const expiresCookie = component.self.$cookie.get("authExpires");

    http.get(url, cookie, expiresCookie).then(res => {
      if (res) {
        if (res[0]) {
          if (res[0].data) {
            commit(FETCH_MEDIAS, res[0].data);
          } else {
            commit(FETCH_MEDIAS, null);
          }
        } else {
          commit(FETCH_MEDIAS, null);
        }
      } else {
        commit(FETCH_MEDIAS, null);
      }
    });
  }
};