<template>
  <div></div>
</template>

<script>
  import common from '../helpers/common'

  export default {
    beforeMount() {
      common.logout(this)
    },
    data() {
      return {}
    }
  }
</script>