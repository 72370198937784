<template>
  <div class="row">
    <div class="col-sm-12 mb30">
      <div class="cardInfo">
        <div class="text-left">
          <p>
            <i class="fas fa-map-marker-alt mr5"></i>
            {{ $t("CodedPOS") }}
          </p>
          <p class="m0">
            <b>{{pointCode}}</b>
          </p>
        </div>
      </div>

      <div class="cardInfo">
        <div class="text-left">
          <p>
            <i class="fas fa-map-marker-alt mr5"></i>
            {{ $t("NamePOS") }}
          </p>
          <p class="m0">
            <b>{{pointText}}</b>
          </p>
        </div>
      </div>

      <div class="cardInfo">
        <div class="text-left">
          <p>
            <i class="far fa-calendar-alt mr5"></i>
            {{ $t("MarketingBudget") }}
          </p>
          <p class="m0">
            <b>{{marketingBudget | convertInEuro }}</b>
          </p>
        </div>
      </div>

      <div class="actionDiv float-right">
        <b-button
          pill
          :variant="'customPrimary' | variantBrand(this)"
          class="fw500 float-right"
          @click="downloadExcel"
        >
          <i class="far fa-file-excel mr5"></i>
          {{ $t("DownloadFile") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    downloadExcel: {
      type: Function
    },
    marketingBudget: {
      type: Number,
      default: 0
    },
    pointCode: {
      type: String,
      default: ""
    },
    pointText: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
// Import custom SASS variable overrides
@import "@/assets/custom-vars.scss";

.cardInfo {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 15px;
  width: auto;
  height: 100px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: $minBorder;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
</style>