<template>
  <div id="custom-report-view">
    <b-button
      class="mt-3 ml-3"
      :to="`/${brand}/${$route.params.type}/reports/menu`"
      pill
      :variant="customPrimaryVariant"
    >
      <i class="mr-2 fas fa-chevron-left"></i>
      {{ $t("GoBack") }}
    </b-button>
    <div id="custom-report">
      <h1 class="my-4 text-center" v-html="$t('CustomReport')"></h1>
      <p class="text-center" v-html="$t('CustomReportText')"></p>
      <b-form ref="form" @submit.prevent="handleCustomReportForm()">
        <b-container fluid>
          <b-row align-v="center" class="mb-5 my-1">
            <b-col cols="auto">
              <label class="custom-label my-0 mx-0">
                <i class="far fa-calendar-alt"></i>
                {{ $t("PeriodOfTime") }} :
              </label>
            </b-col>
            <b-col cols="auto">
              <ul class="dates-row">
                <li>
                  <span>{{ $t("FromDate") }}</span>
                  <datepicker
                    required
                    placeholder="DD/MM/YYYY"
                    format="dd/MM/yyyy"
                    :language="fr"
                    v-model="filters.start_date"
                    name="start_date"
                  ></datepicker>
                </li>
                <li>
                  <span>{{ $t("ToDate") }}</span>
                  <datepicker
                    :required="true"
                    placeholder="DD/MM/YYYY"
                    format="dd/MM/yyyy"
                    :language="fr"
                    v-model="filters.end_date"
                    name="end_date"
                  ></datepicker>
                </li>
              </ul>
            </b-col>
          </b-row>
          <b-row align-v="center" class="mb-5 my-1">
            <b-col cols="auto">
              <label class="custom-label my-0 mx-0">
                <i class="fas fa-sort"></i>
                {{ $t("SortingChoice") }} :
              </label>
            </b-col>
            <b-col cols="auto">
              <b-form-radio-group
                :checked="filters.sorting_choice"
                label="Individual radios"
              >
                <b-form-radio
                  v-model="filters.sorting_choice"
                  name="sorting-choice"
                  value="point_code"
                  >Tri par point de vente</b-form-radio
                >
                <b-form-radio
                  v-model="filters.sorting_choice"
                  name="sorting-choice"
                  value="media_category"
                  >Tri par médias</b-form-radio
                >
              </b-form-radio-group>
            </b-col>
          </b-row>
          <b-row align-v="center" class="mb-3 my-1">
            <b-col cols="auto">
              <label class="custom-label my-0 mx-0">
                <i class="fas fa-ad"></i>
                {{ $t("Medias") }} :
              </label>
            </b-col>
            <b-col cols="auto">
              <b-form-checkbox
                v-model="allMedias"
                @change="toggleAllMedias"
                id="select-all-medias"
                >{{ $t("SelectAllMediasLabel") }}</b-form-checkbox
              >
            </b-col>
          </b-row>

          <ul
            class="medias-list"
            v-for="(cat, keyIndex) in filters.cat"
            :key="keyIndex"
          >
            <p>
              <b-form-checkbox @change="toggleAllMediasWithCat($event, cat)"
                ><b>{{ cat }}</b></b-form-checkbox
              >
            </p>
            <div v-for="(media, key) in filters.medias" :key="key">
              <li v-if="cat === media.type" class="mb-5">
                <b-form-checkbox
                  @change="resetAllMedias"
                  v-model="media.active"
                  :id="key"
                >
                  <span class="mx-1 icon" :class="media.icon"></span>
                  {{ media.name }}
                </b-form-checkbox>
              </li>
            </div>
          </ul>

          <b-row align-v="center" class="mb-4 my-1">
            <b-col cols="auto">
              <label class="custom-label my-0 mx-0">
                <i class="fas fa-map-marker-alt"></i>
                {{ $t("NamePOS") }} :
              </label>
            </b-col>
            <b-col cols="auto">
              <b-form-checkbox
                v-model="allPos"
                @change="toggleAllPos"
                id="select-all-pos"
                >{{ $t("SelectAllPosLabel") }}</b-form-checkbox
              >
            </b-col>
          </b-row>
          <v-select
            :closeOnSelect="false"
            :placeholder="$t('SearchByPos')"
            v-if="!allPos"
            :reduce="(item) => item.point_code"
            label="name"
            multiple
            v-model="filters.pos"
            :options="pos"
          />
          <p
            v-if="filters.pos.length > 0"
            v-html="$t('SelectedPos', { count: filters.pos.length })"
          ></p>
          <b-col class="text-center mt-2 mb-2" cols="12">
            <b-form-radio-group :checked="filters.type_file">
              <b-form-radio
                v-model="filters.type_file"
                name="sorting-choice"
                value="xlsx"
                ><i large class="fas fa-file-excel fa-lg mr-1 green"> </i
                >{{ $t("FormatExcel") }}
              </b-form-radio>
              <b-form-radio
                v-model="filters.type_file"
                name="sorting-choice"
                value="pdf"
                ><i class="fas fa-file-pdf fa-lg mr-1 red"></i
                >{{ $t("FormatPDF") }}</b-form-radio
              >
            </b-form-radio-group>
          </b-col>
        </b-container>
        <div class="text-center my-3">
          <b-button pill :variant="customPrimaryVariant" type="submit">
            <i v-if="!isLoading" class="mr-2 fas fa-chart-bar"></i>
            <i v-else class="mr-2 fas fa-spinner fa-spin"></i>
            {{ $t("CreateReportButton") }}
          </b-button>
          <p class="mt-2" v-if="error">{{ $t("CustomReportFormNotValid") }}</p>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import http from "@/helpers/http";
import Vue from "vue";
import { mapGetters } from "vuex";
import common from "../../helpers/common";

export default {
  name: "CustomReport",
  components: {
    Datepicker,
  },
  beforeMount() {
    common.isAuth(this, http);
  },
  data() {
    return {
      error: false,
      fr: fr,
      selected: null,
      medias: [],
      pos: [],
      allMedias: false,
      allPos: false,
      categories: {},
      filters: {
        sorting_choice: "point_code",
        medias: {},
        pos: [],
        cat: [],
        type_file: "pdf",
        title: "Rapport d'activité personnalisé",
        abundance: this.$cookie.get("allowRate") == "true",
      },
    };
  },
  mounted() {
    this.getMediaSupport();
    this.getPosList();
  },
  computed: {
    ...mapGetters(["isLoading"]),
    brand() {
      return this.$route.params.brand;
    },
    customPrimaryVariant() {
      return this.brand + "-customPrimary";
    },
  },
  methods: {
    resetAllMedias() {
      this.allMedias = false;
    },
    toggleAllMedias(checked) {
      Object.keys(this.filters.medias).forEach((key) => {
        Vue.set(this.filters.medias[key], "active", checked);
      });
    },
    toggleAllMediasWithCat(event, cat) {
      Object.keys(this.filters.medias).forEach((key) => {
        if (this.filters.medias[key].type === cat) {
          Vue.set(this.filters.medias[key], "active", event);
        }
      });
    },
    toggleAllPos(checked) {
      if (checked) this.filters.pos = [];
    },
    setupMediaFilters(medias) {
      let type = "";

      medias.forEach((media) => {
        switch (media.type) {
          case "offline":
            type = this.$i18n.t("offline");
            break;
          case "online":
            type = this.$i18n.t("online");
            break;
          case "marketing":
            type = this.$i18n.t("marketing2");
            break;
          case "other":
            type = this.$i18n.t("other");
            break;
        }

        if (!this.filters.cat.includes(type)) {
          this.filters.cat.push(type);
        }

        Vue.set(this.filters.medias, media.media_support_code, {
          type: type,
          name: media.name,
          icon: media.icon,
          active: false,
        });
      });
    },
    setupPos() {
      this.pos.forEach((item) => {
        item.name = item.pointofinterest.details.name;
      });
    },
    async getPosList() {
      try {
        let res = await http.get(
          this.$store.state.apiUrl + "/global/posDetails",
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        );
        if (res[0]) {
          this.pos = res[0].data;
        }
        this.setupPos();
      } catch (err) {
        console.log(err);
      }
    },
    async getMediaSupport() {
      try {
        let res = await http.get(
          this.$store.state.apiUrl + "/global/media_support",
          this.$cookie.get("auth"),
          this.$cookie.get("authExpires")
        );

        if (res[0]) {
          this.medias = res[0].data;
          this.setupMediaFilters(this.medias);
        }
      } catch (err) {
        console.log(err);
      }
    },
    validateMedias() {
      let firstMediaChecked = Object.values(this.filters.medias).find(
        (media) => {
          return media.active;
        }
      );
      return firstMediaChecked !== undefined || this.allMedias;
    },
    validatePos() {
      return this.filters.pos.length > 0 || this.allPos;
    },
    validateForm() {
      return (
        this.filters.start_date != null &&
        this.filters.end_date != null &&
        this.filters.sorting_choice &&
        this.validateMedias() &&
        this.validatePos()
      );
    },
    async handleCustomReportForm() {
      let self = this;
      this.$store.commit("SET_LOADING", true);
      this.error = false;
      if (this.validateForm()) {
        try {
          let res = await http.postPDF(
            this.$store.state.apiUrl + "/global/generateCustomReport",
            this.filters,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          );
          if (res[0] == undefined) {
            common.makeToast(
              self,
              self.$i18n.t("Error"),
              self.$i18n.t("noDataReport"),
              self.$route.params.brand + "-customDanger",
              "b-toaster-bottom-center",
              true
            );
          }
          if (res[0])
            this.forceFileDownload(res[0].data, this.filters.type_file);
          this.$store.commit("SET_LOADING", false);
        } catch (err) {
          console.log(err);
          this.$store.commit("SET_LOADING", false);
        }
      } else {
        this.$store.commit("SET_LOADING", false);
        this.error = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/custom-vars.scss";

#custom-report {
  margin: auto;
  width: 900px;
  max-width: 95%;

  .custom-label {
    font-weight: bold;
    font-size: 18px;

    i {
      margin-right: 6px;
    }
  }
}

.dates-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    margin: 0 10px;
    display: flex;
    align-items: center;

    > span {
      margin-right: 10px;
    }
  }
}

.medias-list {
  display: inline-table !important;
  // grid-template-columns: repeat(4, 1fr);
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 30px;
  margin-bottom: 2rem;

  > li {
    margin-bottom: 15px;
    font-weight: bold;

    .icon {
    }
  }
}

.vs__search::placeholder {
  opacity: 0.6;
}

@media screen and (max-width: 700px) {
  .medias-list {
    // grid-template-columns: repeat(2, 1fr);
  }

  .dates-row {
    flex-direction: column;

    > li {
      margin-bottom: 10px;
    }
  }
}

.mb-5 {
  margin-bottom: 5px !important;
}
</style>