import http from "../../../helpers/http";

export default {
  state: () => ({
    prePlanDone: {},
    metaData: {},
    globalLoading: false,
  }),
  actions: {
    deletePreSupportDone({
      state,
      commit
    }, {
      $cookie,
      myData
    }) {
      state.globalLoading = true;
      let url = http
        .delete(
          process.env.VUE_APP_APIURL +
          "/prepos/presupportdone" +
          `/?week=${myData.week}&pre_plan_done_code=${myData.pre_plan_done_code}&pre_support_code=${myData.pre_support_code}`,
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
        .then((res) => {
          commit("deletePreSupportDone", myData);
          state.globalLoading = false;
        })
        .catch(() => {
          state.globalLoading = false;
        });
    },
    addPreSupportDone({
      state,
      commit
    }, {
      $cookie,
      myData
    }) {
      state.globalLoading = true;
      let url = http
        .post(
          process.env.VUE_APP_APIURL + "/prepos/presupportdone",
          myData,
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
        .then((res) => {
          commit("addPreSupportDone", res[0].data.presupprtdone);
          state.globalLoading = false;
        })
        .catch(() => {
          state.globalLoading = false;
        });
    },
    updatePreSupportDone({
      state,
      commit
    }, {
      $cookie,
      myData
    }) {
      state.globalLoading = true;
      let url = http
        .patch(
          process.env.VUE_APP_APIURL + "/prepos/presupportdone",
          myData,
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
        .then((res) => {
          commit("updatePreSupportDone", res[0].data.presupprtdone);
          state.globalLoading = false;
        })
        .catch(() => {
          state.globalLoading = false;
        });
    },
    getPrePlanDone({
      state,
      commit
    }, {
      $cookie,
      preplanid
    }) {
      state.globalLoading = true;

      http
        .get(
          process.env.VUE_APP_APIURL + "/global/preplandone/" + preplanid,
          $cookie.get("auth"),
          $cookie.get("authExpires")
        )
        .then((res) => {
          commit("setMetaData", res[0].data.metaData);
          commit("setPrePlanDone", res[0].data.data);
          state.globalLoading = false;
        })
        .catch(() => {
          state.globalLoading = false;
        });
    },
  },
  mutations: {
    deletePreSupportDone(state, newPreSupport) {
      for (const preSupport in state.prePlanDone) {
        if (state.prePlanDone.hasOwnProperty(preSupport)) {
          if (
            state.prePlanDone[preSupport].metaData.pre_support_code ===
            newPreSupport.pre_support_code
          ) {
            const index = state.prePlanDone[preSupport].data.findIndex(
              (el) => el.week === newPreSupport.week
            );
            state.prePlanDone[preSupport].data.splice(index, 1);
          }
        }
      }
    },
    addPreSupportDone(state, newPreSupport) {
      for (const preSupport in state.prePlanDone) {
        if (state.prePlanDone.hasOwnProperty(preSupport)) {
          if (
            state.prePlanDone[preSupport].metaData.pre_support_code ===
            newPreSupport.pre_support_code
          ) {
            state.prePlanDone[preSupport].data.push(newPreSupport);
          }
        }
      }
    },
    updatePreSupportDone(state, newPreSupport) {
      for (const preSupport in state.prePlanDone) {
        if (state.prePlanDone.hasOwnProperty(preSupport)) {
          if (
            state.prePlanDone[preSupport].metaData.pre_support_code ===
            newPreSupport.pre_support_code
          ) {
            const index = state.prePlanDone[preSupport].data.findIndex(
              (el) => el.week === newPreSupport.week
            );
            state.prePlanDone[preSupport].data.splice(index, 1, newPreSupport);
          }
        }
      }
    },
    setPrePlanDone(state, prePlanDone) {
      state.prePlanDone = prePlanDone;
    },
    setMetaData(state, metaData) {
      state.metaData = metaData;
    },
  },

  getters: {
    getStatus(state) {
      if (
        state.metaData &&
        state.metaData.prePlanDone &&
        state.metaData.status
      ) {
        return state.metaData.preplandone.status;
      }
      return 0;
    },
    getAmount(state) {
      let amount = 0;
      for (const key in state.prePlanDone) {
        if (state.prePlanDone.hasOwnProperty(key)) {
          const week = state.prePlanDone[key].data;
          amount += week.reduce((acc, el) => {
            return acc + el.amount;
          }, 0);
        }
      }

      return amount;
    },
    getLoadingPage(state) {
      return state.globalLoading;
    },
    getterPrePlanDone(state) {
      return state.prePlanDone;
    },
    getMetaDataPrePlanDone(state) {
      return state.metaData;
    },
  },
};