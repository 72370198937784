<template>
  <div class="pad30">
    <Tab :active="2" />

    <div class="row">
      <div class="col-sm-12">
        <h3 class="mb30 text-left">{{ $t("TitleDashboardMarketingPlan") }}</h3>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 mb30">
        <div class="cardInfo">
          <div>
            <div class="roundTotal inlineFlex">
              <h3 class="m0" style="font-size: 36px">{{ totalPlans }}</h3>
            </div>
            <p
              class="inlineFlex m0 text-left"
              style="
                float: right;
                height: 150px;
                align-items: center;
                margin-left: 15px;
              "
            >
              <span class="totalColor bold" style="display: contents">{{
                $t("NumberOf")
              }}</span>
              <br />
              {{ $t("ofMarketingPlans") }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 mb30">
        <div class="cardInfo">
          <div>
            <vc-donut
              class="inlineFlex"
              style="margin-top: 15px"
              background="white"
              foreground="#e2e2e2"
              :size="120"
              unit="px"
              :thickness="15"
              :sections="toTreatSections"
              :total="totalGraph"
              :start-angle="0"
            >
              <h3 class="m0">
                <span class="warningColor" style="font-size: 36px">
                  {{ toTreatCount }}
                </span>
                /{{ totalPlans }}
              </h3>
            </vc-donut>
            <p
              class="inlineFlex m0 text-left"
              style="
                float: right;
                height: 150px;
                align-items: center;
                margin-left: 15px;
              "
            >
              {{ $t("plans") }}
              <br />
              {{ $t("marketing") }}
              <br />
              <span class="warningColor bold" style="display: contents">{{
                $t("Inprogress") | downcase
              }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 mb30">
        <div class="cardInfo">
          <div>
            <vc-donut
              class="inlineFlex"
              style="margin-top: 15px"
              background="white"
              foreground="#e2e2e2"
              :size="120"
              unit="px"
              :thickness="15"
              :sections="waitingSections"
              :total="totalGraph"
              :start-angle="0"
            >
              <h3 class="m0">
                <span class="infoColor" style="font-size: 36px">
                  {{ waitingCount }}
                </span>
                /{{ totalPlans }}
              </h3>
            </vc-donut>
            <p
              class="inlineFlex m0 text-left"
              style="
                float: right;
                height: 150px;
                align-items: center;
                margin-left: 15px;
              "
            >
              {{ $t("marketingPlans") }}
              <br />
              <span
                class="infoColor bold"
                style="display: contents"
                v-if="isRd === true"
                >{{ $t("WaitingReturnPO2") }}</span
              >
              <span class="infoColor bold" style="display: contents" v-else>{{
                $t("ToTreat2")
              }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-12 mt15">
        <div class="row">
          <div class="col-sm-12">
            <b-button
              v-if="isRd === true"
              pill
              :variant="customPrimaryVariant"
              class="fw500 float-right"
              :to="{ name: 'createMarketingPlan' }"
            >
              <i class="fas fa-plus-circle mr5"></i>
              {{ $t("CreateAnnualMarketingPlan") }}
            </b-button>

            <b-button
              pill
              :variant="customPrimaryVariant"
              class="fw500 float-right mr5"
              :to="{ name: 'reportsMenuPlan', params: { type: 'rd' } }"
            >
              <i class="fas fa-file-alt mr5"></i>
              {{ $t("generateReport") }}
            </b-button>
          </div>
        </div>

        <h5 class="m0 text-left">
          <i class="fas fa-list mr5"></i>
          {{ $t("MyMarketingPlan") }}
        </h5>

        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

        <vue-bootstrap4-table
          id="inProgress"
          :classes="classes"
          :rows="rows"
          :columns="columns"
          :config="config"
          @on-select-row="clickRow"
        >
          <template slot="name" slot-scope="props">
            <div v-if="props.row.status === 0">
              <i class="fas fa-circle infoColor mr10 fs10"></i>
              {{ props.row.name }}
            </div>
            <div v-else-if="props.row.status === 1">
              <i class="fas fa-circle warningColor mr10 fs10"></i>
              {{ props.row.name }}
            </div>
            <div v-else-if="props.row.status === 2">
              <i class="fas fa-circle successColor mr10 fs10"></i>
              {{ props.row.name }}
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <b-dropdown
              dropleft
              text="Drop-Left"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <i class="fas fa-ellipsis-v primaryColor fs18"></i>
              </template>
              {{ "scheduleFor" + getSuffix }}
              <b-dropdown-item
                v-if="props.row.status === 1"
                :to="{
                  name: 'scheduleFor' + getSuffix,
                  params: { preplanid: props.row.planCode },
                }"
                :data-id="'dropdown' + props.row.vbt_id"
              >
                <i class="fas fa-edit mr5"></i>
                {{ $t("EditFile") }}
              </b-dropdown-item>

              <b-dropdown-item
                v-if="props.row.status === 2 || props.row.status === 0"
                :to="{
                  name: 'scheduleFor' + getSuffix,
                  params: { preplanid: props.row.planCode },
                }"
                :data-id="'dropdown' + props.row.vbt_id"
              >
                <i class="fas fa-eye mr5"></i>
                {{ $t("ViewFile") }}
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template slot="statusName" slot-scope="props">
            {{ props.row.statusName }}
          </template>

          <template slot="creationDate" slot-scope="props">{{
            props.row.creationDate | dateGenericFormat
          }}</template>

          <template slot="modificationDate" slot-scope="props">{{
            props.row.modificationDate | dateGenericFormat
          }}</template>

          <template slot="simple-filter-clear-icon">
            <i class="fas fa-times-circle waitingColor"></i>
          </template>

          <template slot="sort-asc-icon">
            <i class="fas fa-sort-up"></i>
          </template>

          <template slot="sort-desc-icon">
            <i class="fas fa-sort-down"></i>
          </template>

          <template slot="no-sort-icon">
            <i class="fas fa-sort"></i>
          </template>

          <template slot="empty-results">{{ $t("EmptyResults") }}</template>

          <template slot="paginataion-previous-button">
            {{ $t("Previous") }}
          </template>

          <template slot="paginataion-next-button">{{ $t("Next") }}</template>

          <template slot="pagination-info" slot-scope="props">
            {{ props.filteredRowsLength }} {{ $t("filteredResults") }}
            {{ props.originalRowsLength }}
          </template>
        </vue-bootstrap4-table>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../helpers/http";
import common from "../helpers/common";
import Loading from "vue-loading-overlay";
import Tab from "@/components/Tab/Tab.vue";
import "vue-loading-overlay/dist/vue-loading.css";

//  :to="{path: 'schedule' , params: { preplanid: props.row. }
export default {
  beforeMount() {
    common.isAuth(this, http); // TODO why
  },
  filters: {
    downcase: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toLowerCase() + value.slice(1);
    },
  },
  computed: {
    columns() {
      return [
        {
          label: this.$i18n.t("PeriodOfTime"),
          name: "name",
          filter: {
            type: "simple",
            placeholder: this.$i18n.t("PeriodOfTime"),
          },
          sort: true,
        },
        {
          label: this.$i18n.t("Pdv"),
          name: "pos",
          filter: {
            type: "simple",
            placeholder: this.$i18n.t("Pdv"),
          },
          sort: true,
        },
        {
          label: this.$i18n.t("CodedPOS"),
          name: "posCode",
          filter: {
            type: "simple",
            placeholder: this.$i18n.t("CodedPOS"),
          },
          sort: true,
        },
        {
          label: this.$i18n.t("CreationDate"),
          name: "creationDate",
          filter: {
            type: "simple",
            placeholder: this.$i18n.t("CreationDate"),
          },
          sort: true,
        },
        {
          label: this.$i18n.t("LastModification"),
          name: "modificationDate",
          filter: {
            type: "simple",
            placeholder: this.$i18n.t("LastModification"),
          },
          sort: true,
        },
        {
          label: this.$i18n.t("Status"),
          name: "statusName",
          sort: true,
          initial_sort: true, // "false" by default
          initial_sort_order: "asc", // "asc" by default
          sortCaseSensitive: false, // "true" by default
          filter: {
            type: "select",
            mode: "multi",

            placeholder: this.$i18n.t("Status"),
            options: this.filterOptions,
            select_all_checkbox: {
              visibility: true,
              text: "Tout",
            },
          },
        },
      ];
    },

    getSuffix: {
      get() {
        if (this.isRd == true) {
          return "Rd";
        }
        if (this.isPos == true) {
          return "Pos";
        }

        return "error";
      },
    },
    isRd: {
      get() {
        //self.$cookie.set("isPOS", result[0].data.isPOS);
        return this.$cookie.get("isDR") == "true";
      },
    },
    isPos: {
      get() {
        //self.$cookie.set("isPOS", result[0].data.isPOS);
        return this.$cookie.get("isPOS") == "true";
      },
    },
  },
  data() {
    return {
      filterOptions: [],
      totalGraph: 1,
      toTreatSections: [
        {
          value: 0,
          color: "#ff9f42",
        },
      ],
      waitingSections: [
        {
          value: 0,
          color: "#0387C5",
        },
      ],
      customPrimaryVariant:
        window.location.pathname.split("/")[1] + "-customPrimary",
      isLoading: true,
      fullPage: true,
      totalPlans: 0,
      toTreatCount: 0,
      campaignCount: 0,
      posCount: 0,
      waitingCount: 0,
      rows: [],

      classes: {
        // SOS
        cell: {
          "customWaitingBackground text-center": function (
            row,
            column,
            cellValue
          ) {
            if (
              column.name == "statusName" &&
              (cellValue == "En attente de retour PDV" ||
                cellValue == "A traiter")
            ) {
              return true;
            }
          },
          "customWarningBackground text-center": function (
            row,
            column,
            cellValue
          ) {
            if (column.name == "statusName" && cellValue == "En cours") {
              return true;
            }
          },
          "customSuccessBackground text-center": function (
            row,
            column,
            cellValue
          ) {
            if (column.name == "statusName" && cellValue == "Terminé") {
              return true;
            }
          },
        },
      },
      config: {
        rows_selectable: true,
        global_search: {
          visibility: false,
        },
        show_refresh_button: false,
        show_reset_button: false,
        card_mode: false,
        highlight_row_hover: false,
        per_page_options: [],
      },
    };
  },
  components: {
    Loading,
    Tab,
  },
  mounted() {
    http
      .get(
        this.$store.state.apiUrl + "/global/preplandone/",
        this.$cookie.get("auth"),
        this.$cookie.get("authExpires")
      )
      .then((res) => {
        /* fix bug, it very ugly*/
        window.addEventListener("click", function () {
          if (
            document.getElementsByClassName("dropdown-menu") &&
            document.getElementsByClassName("dropdown-menu")[0]
          ) {
            document
              .getElementsByClassName("dropdown-menu")[0]
              .removeAttribute("style");
          }
        });
        document
          .getElementById("multifilter_statusName")
          .addEventListener("click", function (event) {
            event.stopPropagation();
            if (
              document
                .getElementsByClassName("dropdown-menu")[0]
                .getAttribute("style") == null
            ) {
              document
                .getElementsByClassName("dropdown-menu")[0]
                .setAttribute("style", "display:block");
            } else {
              document
                .getElementsByClassName("dropdown-menu")[0]
                .removeAttribute("style");
            }
          });
        let response = res[0];
        let array = [];

        if (res[1]) {
          common.refreshToken(this, res[1].data.token, res[1].data.validity);
        }

        this.totalPlans = response.data.metadata.total;
        this.totalGraph = response.data.metadata.total;
        this.toTreatCount = response.data.metadata.toTreat;
        this.toTreatSections = [
          {
            value: response.data.metadata.toTreat,
            color: "#ff9f42",
          },
        ];
        this.waitingCount = response.data.metadata.waiting;
        this.waitingSections = [
          {
            value: response.data.metadata.waiting,
            color: "#0387C5",
          },
        ];
        this.campaignCount = response.data.metadata.campaignCount;
        this.posCount = response.data.metadata.posCount;

        for (let i = 0; i < response.data.data.length; i++) {
          let statusName = "";
          //natega
          if (this.isRd === true) {
            this.filterOptions = [
              {
                name: this.$i18n.t("Inprogress"),
                value: this.$i18n.t("Inprogress"),
              },
              {
                name: this.$i18n.t("WaitingReturnPOS"),
                value: this.$i18n.t("WaitingReturnPOS"),
              },
              {
                name: this.$i18n.t("Finished"),
                value: this.$i18n.t("Finished"),
              },
            ];
            switch (response.data.data[i].status) {
              case 1:
                statusName = this.$i18n.t("Inprogress");
                break;
              case 0:
                statusName = this.$i18n.t("WaitingReturnPOS");
                break;
              case 2:
                statusName = this.$i18n.t("Finished");
                break;
              default:
                break;
            }
          } else {
            this.filterOptions = [
              {
                name: this.$i18n.t("Inprogress"),
                value: this.$i18n.t("Inprogress"),
              },
              {
                name: this.$i18n.t("ToTreat"),
                value: this.$i18n.t("ToTreat"),
              },
              {
                name: this.$i18n.t("Finished"),
                value: this.$i18n.t("Finished"),
              },
            ];
            switch (response.data.data[i].status) {
              case 1:
                statusName = this.$i18n.t("Inprogress");
                break;
              case 0:
                statusName = this.$i18n.t("ToTreat");
                break;
              case 2:
                statusName = this.$i18n.t("Finished");
                break;
              default:
                break;
            }
          }

          array.push({
            name: response.data.data[i].name,
            pos: response.data.data[i].pos,
            posCode: response.data.data[i].point_code,
            creationDate: response.data.data[i].creationDate,
            modificationDate: response.data.data[i].modificationDate,
            planCode: response.data.data[i].plan_code, // wrong
            planDoneCode: response.data.data[i].plan_code,
            status: response.data.data[i].status,
            statusName: statusName,
            actions: "",
          });
        }
        this.rows = array;
        this.totalPlans = array.filter((el) => el.status !== 2).length;
        this.totalGraph = array.filter((el) => el.status !== 2).length;
        this.toTreatCount = array.filter((el) => el.status === 1).length;
        this.posCount = array.filter((el) => el.status === 0).length;

        0, (this.isLoading = false);
      });
  },
  methods: {
    clickRow(row) {
      this.$router.push({
        name: "scheduleFor" + this.getSuffix,
        params: {
          preplanid: row.selected_item.planCode,
        },
      });
    },
    showModal() {
      this.$refs["addOperationModal"].show();
    },
    hideModal() {
      this.$refs["addOperationModal"].hide();
    },
  },
  created() {
    document.title = "Plans marketing";
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
</style>
<style lang="scss" scoped>
// Import custom SASS variable overrides
@import "../assets/custom-vars.scss";
.test {
  width: 200px !important;
  background: white !important;
  color: #f2f2f4 !important;
  border: 1px solid #ced4da !important;
}
.cardInfo {
  width: 100%;
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: $minBorder;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.waitingColor {
  color: #b2bad0;
}

.infoColor {
  color: #0387c5;
}

.infoBackground {
  background: #0387c5;
}

.inProgress .card-header {
  display: none;
}

.table-active {
  background-color: #fcfcfc !important;
}

.stateDiv {
  background: #f1f3f8;
  width: fit-content;
  padding: 5px;
  margin: auto;
  border-radius: $minBorder;
}

.roundTotal {
  background: #fdf6f4;
  height: 120px;
  width: 120px;
  border-radius: 500px;
  align-items: center;
  justify-content: center;
  color: #d04e39;
  margin-top: 15px;
}

.totalColor {
  color: #d04e39;
}

#app tr {
  cursor: pointer;
}
</style>