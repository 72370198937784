<template>
  <div>
    <AddAction
      :display.sync="addModal"
      v-if="addModal"
      :mode="mode"
      :defaultPrice="selectedPrice"
      :defaultDescrition="selectedDescription"
      :handleValidate="handleAddorUpdate"
      :defaultMediaSupportCodes="this.selectedCell.media_support_codes"
      :preEventCode="this.selectedCell.pre_event_code"
    />
    <ReadActionAll
      :display.sync="readModal"
      v-if="selectedRange === 'Y'"
      :scope="scopeCellForYear"
    />

    <ReadAction
      v-else
      :display.sync="readModal"
      :defaultPrice="selectedPrice"
      :defaultDescrition="selectedDescription"
      @edit="displayActionModal('update')"
      @remove="displayRemoveModel()"
      :defaultMediaSupportCodes="this.selectedCell.media_support_codes"
      :disabled="!isPos"
    />

    <RemoveAction :display.sync="removeModal" :handleValidate="handleRemove" />

    <div class="pb-3">
      <FilterBar
        :selectedPeriod.sync="selectedPeriod"
        :selectedRange.sync="selectedRange"
        :startWeek.sync="startWeek"
        :selectedStartWeekRange.sync="selectedStartWeekRange"
        :year="year"
        @scope="setLabel"
        @scopeRangMonth="setMonths"
      />
    </div>
    <div
      header-tag="header"
      footer-tag="footer"
      v-for="(items, key) in dataServer"
      :key="key"
    >
      <h5 class="mt30 mb15 text-left">
        <i class="fas fa-list mr5"></i>
        {{ key }}
      </h5>

      <b-table
        :items="items"
        :fields="fields"
        responsive="sm"
        class="test"
        v-if="selectedRange !== 'Y'"
      >
        <template v-slot:cell()="data">
          <b-btn
            block
            v-if="data.value && data.value !== false"
            class="btn-action"
            @click="
              getCell(data);
              displayReadModal(data.value);
            "
          >
            {{ data.value.comment }}
            <br />
            {{ data.value.amount | convertInEuro }}
            <br />
            <span v-html="displayIcons(data.value.media_support_codes)"></span>
          </b-btn>
          <b-btn
            block
            v-if="
              !(data.value && data.value !== false) &&
              isPos === true &&
              disabled === false
            "
            class="btn-action btn-hidden"
            @click="
              getCell(data);
              displayActionModal('add');
            "
            >+</b-btn
          >
        </template>
        <template v-slot:cell(name)="data">
          <span :inner-html.prop="data.item | supportOrOperation"></span>
        </template>
        <template v-slot:cell(job)="data">
          <span>{{ data.value }}</span>
        </template>

        <template v-slot:thead-top="data">
          <b-tr>
            <b-th colspan="2.5">
              {{ $t("Year") }} {{ year }}
              <span class="sr-only"></span>
            </b-th>
            <b-th class="month-1" :colspan="secondCols.col1">
              {{ selectedLabel[0] }}
            </b-th>
            <b-th class="month-1 splitCol" colspan="1" v-if="secondCols.half1">
              <div class="half">...</div>
              <div class="half month-2">...</div>
            </b-th>

            <b-th
              class="month-2"
              :colspan="secondCols.col2"
              v-if="selectedRange === 'B' || selectedRange === 'T'"
            >
              {{ selectedLabel[1] }}</b-th
            >
            <b-th
              colspan="1"
              class="splitCol month-1"
              v-if="
                secondCols.half2 &&
                (selectedRange === 'B' || selectedRange === 'T')
              "
            >
              <div class="half month-2">...</div>
              <div class="half">...</div>
            </b-th>
            <b-th
              class="month-1"
              :colspan="secondCols.col3"
              v-if="selectedRange === 'T'"
              >{{ selectedLabel[2] }}</b-th
            >
          </b-tr>
        </template>
      </b-table>
      <b-table
        :items="items"
        :fields="fieldsMonth"
        responsive="sm"
        class="test"
        v-else
      >
        <template v-slot:cell(name)="data">
          <span :inner-html.prop="data.item | supportOrOperation"></span>
        </template>
        <template v-slot:cell(job)="data">{{ data.value }}</template>
        <template v-slot:cell()="data">
          <b-badge
            @click="scopeData(data.value.data)"
            pill
            class="ring btn"
            :variant="'customPrimary' | variantBrand(self)"
            v-if="data.value.nbrPreplan"
            >{{ data.value.nbrPreplan }}</b-badge
          >
          <br />
          {{ (data.value.amount || 0) | convertInEuro }}
          <br v-if="!isNotEmpty" />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddAction from "@/components/Dialog/AddAction.vue";
import ReadAction from "@/components/Dialog/ReadAction.vue";
import ReadActionAll from "@/components/Dialog/ReadActionAll.vue";
import RemoveAction from "@/components/Dialog/RemoveAction.vue";
import FilterBar from "./filter/Filter.vue";
import http from "@/helpers/http";
import moment from "moment";

export default {
  components: {
    AddAction,
    ReadAction,
    RemoveAction,
    FilterBar,
    ReadActionAll,
  },
  props: {
    schedule: { type: Object },
    scope: { type: String },
    year: { type: Number },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    isNotEmpty: function (defaultMediaSupportCodes) {
      return (defaultMediaSupportCodes =
        JSON.parse(defaultMediaSupportCodes).length > 0);
    },
    yourMediaDataFull: function (defaultMediaSupportCodes) {
      return this.listOfMedia.filter(
        (el) =>
          !!defaultMediaSupportCodes.find((s) => s === el.media_support_code)
      );
    },
    displayIcons(defaultMediaSupportCodes) {
      defaultMediaSupportCodes = JSON.parse(defaultMediaSupportCodes);
      return this.yourMediaDataFull(defaultMediaSupportCodes)
        .map((el) => "<i class=" + el.picto + "></i>")
        .join("");
    },
    scopeData(scope) {
      this.scopeCellForYear = scope;
      this.readModal = true;
    },
    setLabel(labels) {
      this.selectedLabel = labels;
    },
    setMonths(data) {
      this.monthRows = data;
    },
    getCell(data) {
      let myDataValue = data.value;

      if (myDataValue === "") {
        myDataValue = {
          media_support_codes: "[]",
          amount: 0,
          comment: "",
        };
      }

      this.selectedCell = {
        media_support_codes: JSON.parse(myDataValue.media_support_codes),
        amount: myDataValue.amount,
        comment: myDataValue.comment,
        week: data.field.week,
        name: data.item.name,
        trade: data.item.job,
        row: data.item.pre_support_code,
        pre_event_code: data.item.pre_event_code,
      };
    },
    handleAddorUpdate(data) {
      if (this.scope.length !== 5 && this.selectedRow.length !== 5) {
        throw "The code is not loaded or wrong";
      }

      const myData = {
        ...data,
        week: this.selectedStartWeek,
        pre_plan_done_code: this.scope,
        pre_support_code: this.selectedRow,
      };

      if (this.mode === "update") {
        this.$store.dispatch("updatePreSupportDone", {
          myData,
          $cookie: this.$cookie,
        });
      } else {
        this.$store.dispatch("addPreSupportDone", {
          myData,
          $cookie: this.$cookie,
        });
      }
    },

    handleRemove(data) {
      if (this.scope.length !== 5 && this.selectedRow.length !== 5) {
        throw "The code is not loaded or wrong";
      }
      const myData = {
        ...data,
        week: this.selectedStartWeek,
        pre_plan_done_code: this.scope,
        pre_support_code: this.selectedRow,
      };
      this.$store.dispatch("deletePreSupportDone", {
        myData,
        $cookie: this.$cookie,
      });
    },
    displayRemoveModel() {
      this.removeModal = true;

      this.selectedStartWeek = this.selectedCell.week;
      this.selectedRow = this.selectedCell.row;
    },
    displayReadModal(data) {
      this.readModal = true;

      this.selectedPrice = data.amount;
      this.selectedDescription = data.comment;

      this.selectedStartWeek = this.selectedCell.week;
      this.selectedRow = this.selectedCell.row;
    },
    displayActionModal(mode) {
      this.mode = mode;
      this.addModal = true;
      this.selectedStartWeek = this.selectedCell.week;
      this.selectedRow = this.selectedCell.row;
      if (mode === "update") {
        this.selectedPrice = this.selectedCell.amount;
        this.selectedDescription = this.selectedCell.comment;
      } else {
        this.selectedPrice = null;
        this.selectedDescription = "";
      }
    },
  },
  computed: {
    self: {
      get() {
        return this;
      },
    },
    listOfMedia() {
      return this.$store.getters["Medias/getAllMedia"];
    },
    items: {
      get() {
        return this.dataServer.map((el) => ({
          ...el,
        }));
      },
    },
    isPos: {
      get() {
        return this.$cookie.get("isPOS") == "true";
      },
    },
    secondCols: {
      get() {
        const half1 = this.monthRows[0].end === this.monthRows[1].start;
        const m1 = half1 ? -1 : 0;
        const half2 = this.monthRows[1].end === this.monthRows[2].start;
        const m2 = half2 ? -1 : 0;
        switch (this.selectedRange) {
          case "M":
            return {
              col1: this.monthRows[0].end - this.monthRows[0].start + m1 + 1,
              half1,
              col2: this.monthRows[1].end - this.monthRows[1].start,
              half2,
              col3: this.monthRows[2].end - this.monthRows[2].start,
            };
          case "B":
            return {
              col1: this.monthRows[0].end - this.monthRows[0].start + m1 + 1,
              half1,
              col2:
                this.monthRows[1].end - this.monthRows[1].start + m1 + m2 + 1,
              half2,
              col3: this.monthRows[2].end - this.monthRows[2].start,
            };
          case "T":
            return {
              col1: this.monthRows[0].end - this.monthRows[0].start + m1 + 1,
              half1,
              col2: this.monthRows[1].end - this.monthRows[1].start + m2 + 1,
              half2,
              col3:
                this.monthRows[2].end - this.monthRows[2].start + m1 + m2 + 1,
            };

          default:
            return false;
        }
      },
    },

    dataServer: {
      get() {
        const event = {};
        const multischedule = {};
        for (const presupport in this.schedule) {
          if (this.schedule.hasOwnProperty(presupport)) {
            const element = this.schedule[presupport];
            event[element.metaData.pre_event] = {};
          }
        }
        for (const presupport in this.schedule) {
          if (this.schedule.hasOwnProperty(presupport)) {
            const element = this.schedule[presupport];
            event[element.metaData.pre_event][presupport] = element;
          }
        }

        for (const preevent in event) {
          const dataDisplay = [];
          for (const key in event[preevent]) {
            const year = event[preevent][key].data.reduce((acc, el) => {
              acc["w" + el.week] = { ...el };
              return acc;
            }, {});
            const month = event[preevent][key].data.reduce((acc, el) => {
              let nbrOfMonth = moment().week(el.week).month() + 1;
              if (el.week == 0 || el.week === 1) {
                nbrOfMonth = 1; // fix bug with first week
              }
              if (acc["m" + nbrOfMonth]) {
                acc["m" + nbrOfMonth].amount += el.amount;
                acc["m" + nbrOfMonth].nbrPreplan += 1;
                acc["m" + nbrOfMonth].data.push(el);
              } else {
                acc["m" + nbrOfMonth] = { ...el };
                acc["m" + nbrOfMonth].data = [el];
                acc["m" + nbrOfMonth].nbrPreplan = 1;
              }

              return acc;
            }, {});
            //media_support_codes
            dataDisplay.push({
              pre_event_code: event[preevent][key].metaData.pre_event_code,
              name: event[preevent][key].metaData.name,
              job: event[preevent][key].metaData.trade,
              operation: event[preevent][key].metaData.operation,
              media_support: event[preevent][key].metaData.media_support,
              pre_support_code: event[preevent][key].metaData.pre_support_code,
              ...year,
              ...month,
            });
          }
          multischedule[preevent] = dataDisplay;
        }
        return multischedule;
      },
    },
    fields: {
      get() {
        const fieldWeek = [];
        for (
          let week = this.startWeek;
          week < this.startWeek + this.selectedStartWeekRange;
          week++
        ) {
          fieldWeek.push({
            week,
            key: "w" + week,
            label: "Semaine " + week,
          });
        }

        const fields = [
          { key: "name", label: this.$t("OperationName") },
          { key: "job", label: this.$t("Job") },
          ...fieldWeek,
        ];
        return fields;
      },
    },
  },
  watch: {
    year(year) {
      this.startWeek =
        moment(`01-01-${year}`, "MM-DD-YYYY").week() > 52 ? 0 : 1;
    },
  },
  data() {
    let firstweek = moment(`01-01-${this.year}`, "MM-DD-YYYY").week();

    return {
      monthRows: [
        { start: 1, end: 5 },
        { start: 5, end: 15 },
        { start: 15, end: 20 },
      ],
      fieldsMonth: [
        "name",
        { key: "job", label: this.$t("Job") },
        { key: "m1", label: this.$t("January") },
        { key: "m2", label: this.$t("February") },
        { key: "m3", label: this.$t("March") },
        { key: "m4", label: this.$t("April") },
        { key: "m5", label: this.$t("May") },
        { key: "m6", label: this.$t("June") },
        { key: "m7", label: this.$t("July") },
        { key: "m8", label: this.$t("August") },
        { key: "m9", label: this.$t("September") },
        { key: "m10", label: this.$t("October") },
        { key: "m11", label: this.$t("November") },
        { key: "m12", label: this.$t("December") },
      ],
      selectedPeriod: firstweek > 52 ? 0 : 1, // TODO REMOVE???
      selectedRange: "M", // Month Bimenster Trimester Year
      selectedStartWeek: 1,
      startWeek: 1,
      scopeCellForYear: [],
      selectedCell: {},
      selectedStartWeekRange: 5,
      selectedRow: null,
      selectedLabel: [this.$t("January"), this.$t("February")],
      addModal: false,
      readModal: false,
      removeModal: false,
      mode: "add",
      selectedPrice: null,
      selectedDescription: "",
    };
  },
}; // TODO  class name
</script>
<style scoped>
th {
  border: none;
}

.card {
  margin-bottom: 0px;
  border-radius: 0px;
}

.test {
  margin-bottom: -2px;
}

.test >>> th[scope="col"] {
  text-align: center;
  background: #f2f2f4;
  border: 1px solid #e1e1e1;
}

.test >>> tr[role="row"] {
  height: 40px;
}

.test >>> tbody tr[role="row"] {
  height: 75px;
}

.test >>> table {
  table-layout: fixed;
}

.month-1 {
  background: #dbdbe3;
}

.month-2 {
  background: #cbcbd5;
}

.test >>> .splitCol {
  margin: 0px;
  height: 10px;
  padding: 0px;
}

.one-third {
  width: 33%;
  height: 100%;
  display: inline-block;
}

.two-third {
  width: 67%;
  height: 100%;
  display: inline-block;
}

.half {
  width: 50%;
  height: 100%;
  display: inline-block;

  color: rgba(0, 0, 0, 0);
}

.test >>> td,
.test >>> th {
  padding: 5px;
  vertical-align: middle !important;
  border: 1px solid #f1f1f1;
}

.card-body {
  margin: 0px;
  padding: 0px;
}

.bg-third {
  background: #f6f8fb;
}

.btn-action {
  background: #f5f5f5;

  color: black;
  border: none;
  height: 75px;

  white-space: nowrap;
  text-overflow: inherit;
  width: 100%;
  overflow: hidden;
}

.ring {
  border-radius: 100%;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.btn-hidden {
  opacity: 0;
  transition: all 0.3s ease-in;
}

.btn-hidden:hover {
  opacity: 100;
  background: #eee;
}
</style>