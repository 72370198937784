<template>
  <div id="rd-reports-menu">
    <b-button
      class="mt-3 ml-3"
      :to="`/${brand}/${$route.params.type}/dashboard`"
      pill
      :variant="customPrimaryVariant"
    >
      <i class="mr-2 fas fa-chevron-left"></i>
      {{ $t("GoBack") }}
    </b-button>
    <h1 class="my-4 text-center" v-html="$t('Report')"></h1>
    <p class="text-center" v-html="$t('SelectReportText')"></p>
    <ul class="report-choices">
      <li>
        <ReportChoice
          :isLoading="loadings.pos"
          @btnClicked="generatePremadeReport(filtersPos, 'pos', $event)"
          :text="$t('ReportByPOS')"
          icon="fas fa-map-marker-alt"
        />
      </li>
      <li>
        <ReportChoice
          :isLoading="loadings.month"
          @btnClicked="generatePremadeReport(filtersMonth, 'month', $event)"
          :text="$t('ReportByMonth')"
          icon="far fa-calendar-alt"
        />
      </li>
      <li>
        <ReportChoice
          :isLoading="loadings.medias"
          @btnClicked="generatePremadeReport(filtersMedias, 'medias', $event)"
          :text="$t('ReportByMedias')"
          icon="fas fas fa-desktop"
        />
      </li>
    </ul>
    <ul class="report-choices">
      <li></li>
      <li>
        <ReportChoice
          @btnClicked="
            $router.push(`/${brand}/${$route.params.type}/reports/custom`)
          "
          :text="$t('CustomReport')"
          icon="far fa-file-alt"
          :multi="false"
        />
      </li>
      <li></li>
    </ul>
  </div>
</template>

<script>
import ReportChoice from "@/components/Report/ReportChoice";
import http from "@/helpers/http";
import moment from "moment";
import common from "../../helpers/common";

export default {
  beforeMount() {
    common.isAuth(this, http);
  },
  name: "rdReportsMenu",
  components: {
    ReportChoice,
  },
  computed: {
    brand() {
      return this.$route.params.brand;
    },
    filtersPos() {
      return {
        sorting_choice: "point_code",
        allMedias: true,
        pos: [],
        start_date: this.firstDayInPreviousMonth(Date.now()).toString(),
        end_date: this.lastDayInPreviousMonth(Date.now()).toString(),
        title: "Rapport d'activité",
      };
    },
    filtersMedias() {
      return {
        sorting_choice: "media_category",
        allMedias: true,
        pos: [],
        start_date: this.firstDayInPreviousMonth(Date.now()).toString(),
        end_date: this.lastDayInPreviousMonth(Date.now()).toString(),
        title: "Rapport d'activité",
      };
    },
    filtersMonth() {
      return {
        sorting_choice: "point_code",
        allMedias: true,
        pos: [],
        start_date: this.firstDayInMonth(Date.now()).toString(),
        end_date: this.lastDayInMonth(Date.now()).toString(),
        month: moment().subtract(1, "months").format("MMMM YYYY"),
        title: "Rapport d'activité",
      };
    },
  },
  data() {
    return {
      type_file: "pdf",
      brandParam: window.location.pathname.split("/")[1],
      customPrimaryVariant:
        window.location.pathname.split("/")[1] + "-customPrimary",
      loadings: {
        pos: false,
        month: false,
        medias: false,
      },
    };
  },
  methods: {
    firstDayInMonth(date) {
      let now = new Date();
      let lastday = new Date(now.getFullYear(), now.getMonth(), 0);
      let firstday = new Date(lastday.getFullYear(), lastday.getMonth(), 1);

      return firstday;
    },
    lastDayInMonth(date) {
      let now = new Date();
      let lastday = new Date(now.getFullYear(), now.getMonth(), 0);

      return lastday;
    },
    firstDayInPreviousMonth(date) {
      let d = new Date();

      return new Date(d.setDate(d.getDate() - 30));
    },
    lastDayInPreviousMonth(date) {
      let d = new Date();

      return new Date(d.setDate(d.getDate()));
    },
    async generatePremadeReport(filters, loadingToFire, format) {
      this.type_file = format;
      const myFormat = format;
      let self = this;

      this.loadings[loadingToFire] = true;

      try {
        let res;
        filters.type_file = this.type_file;
        if (myFormat === "pdf") {
          res = await http.postPDF(
            this.$store.state.apiUrl + "/global/generateCustomReport",
            filters,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          );
        } else {
          res = await http.postExcel(
            this.$store.state.apiUrl + "/global/generateCustomReport",
            filters,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          );
        }

        if (res[0]) {
          this.forceFileDownload(res[0].data, myFormat);
        } else {
          common.makeToast(
            self,
            "Erreur",
            "Aucune donnée trouvée.",
            self.brandParam + "-customDanger",
            "b-toaster-bottom-center",
            true
          );
        }
        this.loadings[loadingToFire] = false;
      } catch (err) {
        this.loadings[loadingToFire] = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/custom-vars.scss";

#rd-reports-menu {
  .report-choices {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    padding: 0;
    width: 1000px;
    max-width: 95%;
    margin: 90px auto;

    > li {
      flex: 1;
      margin: 0 20px;
    }
  }
}
</style>