<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    v-model="isDisplayed"
    :title="title"
  >
    <div>
      <b>{{ $t("DescriptionAction") }}</b>
      <b-form-textarea
        rows="10"
        rows-max="15"
        id="input-1"
        v-model="description"
        required
        placeholder
        :class="{
          pleaseFill:
            (description === null || description === '') &&
            isValidation == false,
        }"
      ></b-form-textarea>
      <hr />
      <v-select
        label="text"
        :reduce="(support) => support.value"
        v-model="mediaSupportCodes"
        multiple
        :options="listOfSupport"
        :placeholder="$t('SupportToSelect')"
        v-if="listOfSupport.length > 0"
        :class="{
          pleaseFill: mediaSupportCodes.length == 0 && isValidation == false,
        }"
      ></v-select>
      <hr />
      {{ $t("Budget") }} <i>{{ $t("BudgetInfo") }}</i>
      <b-form-input
        v-model="price"
        type="number"
        min="0"
        :class="{
          pleaseFill: (price === null || price === '') && isValidation == false,
        }"
      >
      </b-form-input>
    </div>
    <template v-slot:modal-footer>
      <div class="w-100 float-right">
        <b-button
          pill
          :variant="'customPrimary' | variantBrand(self)"
          class="float-right ml-1"
          @click="validate()"
        >
          {{ $t("Validate") }}</b-button
        >
        <b-button
          pill
          variant="secondary"
          class="float-right ml-1"
          @click="isDisplayed = false"
          >{{ $t("Cancel") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import common from "../../helpers/common";

export default {
  props: {
    display: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "add",
    },
    defaultDescrition: {
      type: String,
      default: "",
    },
    defaultPrice: {
      type: Number,
      default: null,
    },
    handleValidate: {
      type: Function,
    },
    defaultMediaSupportCodes: {
      type: Array,
      default: () => [],
    },
    preEventCode: {
      type: String,
    },
  },
  methods: {
    validate() {
      this.errors = [];
      if (this.description.trim() === "") {
        this.errors.push(this.$t("ErrorHaveToPickDescription"));
        this.isValidation = false;
        common.makeToast(
          this,
          "Attention",
          this.$t("missingAddAction"),
          this.brand + "-customWarning",
          "b-toaster-bottom-center",
          true
        );
        return false;
      }

      if (this.price === null || this.price === "") {
        this.errors.push(this.$t("ErrorHaveToPickPrice"));
        this.isValidation = false;
        common.makeToast(
          this,
          "Attention",
          this.$t("missingAddAction"),
          this.brand + "-customWarning",
          "b-toaster-bottom-center",
          true
        );
        return false;
      }
      this.isValidation = true;
      this.isDisplayed = false;
      this.handleValidate({
        media_support_codes: JSON.stringify(this.mediaSupportCodes),
        comment: this.description,
        amount: parseFloat(this.price),
      });
    },
  },
  watch: {
    price(value) {
      if (parseInt(value) != value || parseInt(value) < 0) {
        if (!isNaN(parseInt(value))) {
          this.price = Math.abs(parseInt(value));
        } else {
          this.price = "";
        }
      }
    },
    defaultDescrition() {
      this.description = this.defaultDescrition;
    },
    defaultPrice() {
      this.price = this.defaultPrice;
    },
    defaultMediaSupportCodes() {
      this.mediaSupportCodes = this.defaultMediaSupportCodes;
    },
  },
  data() {
    return {
      brand: window.location.pathname.split("/")[1],
      errors: [],
      description: this.defaultDescrition,
      price: this.defaultPrice,
      mediaSupportCodes: this.defaultMediaSupportCodes,
      isValidation: true,
    };
  },
  computed: {
    self: {
      get: function () {
        return this;
      },
    },
    title: {
      get: function () {
        let title = "Ajouter une action";
        if (this.mode === "update") {
          title = "Modifier une action";
        }
        return title;
      },
    },
    listOfSupport() {
      return this.$store.getters.getListOfSupport(this.preEventCode);
    },
    isDisplayed: {
      get: function () {
        return this.display;
      },
      set: function (display) {
        this.$emit("update:display", display); // for sync
      },
    },
  },
};
</script>

<style>
</style>