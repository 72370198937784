import appActions from './actions';
import appMutations from './mutations';
import appGetters from './getters';

const state = {
    loading: false,
};
const actions = appActions;
const mutations = appMutations;
const getters = appGetters;


export const appStore = {
    state, actions, mutations, getters,
};
