import Vue from 'vue';
import Vuex from 'vuex';
import { appStore } from './modules/App/app';
import { reportStore } from './modules/Report/report'
import Medias from './modules/Medias/'
import Trade from './modules/Trade/trade'
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        apiUrl: process.env.VUE_APP_APIURL,
        // Check spinner state
        isSpinnerInactive: true,
        // Disallow text input
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        // Space thousand
        numberThousand(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
        // Set number + currency
        setCurrency(number) {
            if (number != undefined && number != "") {
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"
            } else {
                return number
            }
        },
        // Set number + percentage
        setPercentage(number) {
            if (number) {
                return number + " %"
            } else {
                return number
            }
        }
    },
    modules: {
        appStore,
        reportStore,
        Medias,
        Trade
    },
    strict: false
});

export default store;
