<template>
  <div></div>
</template>

<script>
  import http from '../helpers/http'
  import common from '../helpers/common'

  export default {
    beforeMount() {
      common.refreshToken(this, this.$route.params.token, process.env.VUE_APP_JWT_VALIDITY)
      common.isAuth(this, http)
    },
    data() {
      return {}
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>