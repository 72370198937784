<template>
    <b-card  :bg-variant="customPrimaryVariant" class='sign'>
        <center class='mt-4'>
        <i v-if="!isLoading" :class="icon" class="icon main-icon"></i>
        <i v-else class="icon mr-2 fas fa-spinner fa-spin main-icon"></i>
        <BR/>
        <span v-html="text"></span>
        </center>
         <template #footer>
             <b-row class="btn-row" v-if="multi === true">
                <b-col sm="6" class='btn-col'>
                     <b-btn class='btn-scoped first' variant="tryBtn" :class='customPrimaryVariantText' @click="$emit('btnClicked','pdf')"><i class="fas fa-file-pdf fa-lg mr-2  "/>
                     <span class="d-none d-lg-inline-block">
                     {{$t("FormatPDFshort")}}
                     </span>
                     </b-btn>
                </b-col>
                <b-col sm="6" class='btn-col'>
                     <b-btn class='btn-scoped'  variant="tryBtn" :class='customPrimaryVariantText' @click="$emit('btnClicked','xlsx')"> <i 
                     
                     class="fas fa-file-excel fa-lg mr-2"/><span class="d-none d-lg-inline-block">{{$t("FormatExcelshort")}}</span></b-btn>
                </b-col>
            </b-row>
            <b-row class="btn-row" v-else>
                <b-col sm="12" class='btn-col'>
                     <b-btn class='btn-scoped' variant="tryBtn" :class='customPrimaryVariantText' @click="$emit('btnClicked')">{{$t("Settings")}}</b-btn>
                </b-col>
            </b-row>
      </template>
    </b-card>
</template>

<script>
    export default {
        name: "ReportChoice",
        props: {
            multi:{
                default: true,
                type: Boolean,
            },
            isLoading: {
                default: false,
                type: Boolean,
            },
            text: {
                default: "",
                type: String
            },
            icon: {
                default: "",
                type: String
            }
        },
        data() {
            return {
                customPrimaryVariant: window.location.pathname.split("/")[1] + "-customPrimary",
                 customPrimaryVariantText:
        "text-" + window.location.pathname.split("/")[1] + "-customPrimary",
            }
        }
    }
</script>

<style lang="scss" scoped>
    .btn-tryBtn{
        background: white;
    }

    .card-footer{
        padding: 0px;
    }
    .btn-scoped{
        width: 100%;
        border-radius: 0px;
       
        &.first{
            border-right: black 1px solid!important;   
            box-sizing: border-box;
        }
    }

    .btn-row{
        margin-right: 0px; 
        margin-left:  0px;
    }
    .btn-col{
        padding-right: 0px;
        padding-left: 0px;
    }
    .sign {
        display: flex;
      
       
        height: 200px;
        width: 100%;
        color: #fff;
        font-size: 18px;
        .main-icon {
            font-size: 25px;
            margin-bottom: 15px;
        }
    }
</style>