<template>
  <div>
    <b-table
      responsive
      bordered
      striped
      class="m0"
      id="posDetailsTable"
      hover
      :items="scopeImprovement"
      :fields="fields"
    >
    
    <template v-slot:cell(name)="row">
        {{ row.item.name }}
        <i v-if="(row.item.customCeil) || (row.item.turnoverContribution && row.item.customCeil)" class="fas fa-tag ml5 cursor-pointer"
          v-b-popover.hover.right="row.item.contribLabel" :title="$t('customCeil')"></i>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array,
      default: () => {},
    },
  },
  computed: {
    scopeImprovement() {
      const temp = this.scope.map((el) => el);
      const media = temp.map((el) => el.medias.map((x) => x.name));
      const model = temp.map((el) => el.models.map((x) => x.name));

      for (let index = 0; index < media.length; index++) {
        temp[index].mediasString = media[index].join(", ");
        temp[index].modelString = model[index].join(", ");
      }

      return temp;
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          sortable: true,
          label: this.$t("Operations"),
        },
        {
          key: "modelString",
          sortable: true,
          label: this.$t("tableModel"),
        },
        {
          key: "mediasString",
          label: this.$t("Supports"),
          sortable: false,
        },
        {
          key: "contribLabel" ,
          label: this.$t("AbundanceRate"),
          sortable: false,
        },
        {
          key: "comment",
          label: this.$t("comment"),
          sortable: false,
        },
      ],
    };
  },
};
</script>

<style>
</style>